import React from 'react'
import Header from "../Header";
import {MDBBtn} from "mdbreact";
import {useDispatch} from "react-redux";
import {resetAccountCreationSession} from "../../Redux/Actions/CreateAccount";
import {Button} from "@mui/material";

export default function CreateAccountForm(props) {
    const dispatch = useDispatch();

    const resetCreationSession = () => {
        dispatch(resetAccountCreationSession())
    }

    return (
        <>
            <Header title='Account Creation Error'/>
            <p>There was an error creating your account. Please contact our support team at support@bloyal.com or 877-388-7638</p>
            <Button className='next-btn' onClick={resetCreationSession}>Retry</Button>
        </>
    )
}
