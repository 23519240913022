import initialState from "../InitialState";
import actionTypes from "../Actions/ActionTypes";


export const createAccountReducer = (state = initialState.createAccount, action) => {
    switch (action.type) {
        case actionTypes.createAccount.validateLoginDomain:
            return {
                ...state,
                validatingDomain: true,
            }
        case actionTypes.createAccount.validateLoginSuccess:
            return {
                ...state,
                isDomainValid: !action.response.data.IsFound,
                validatingDomain: false,
            }
        case actionTypes.createAccount.validateLoginFailure:
            return {
                ...state,
                error: action.error,
                status: 'failure',
            }
        case actionTypes.createAccount.createAccountRequestSuccess:
            return {
                ...state,
                status: action.data.status,
                key: action.data.key,
            };
        case actionTypes.createAccount.createAccountRequestFailure:
            return {
                ...state,
                error: action.error,
                status: 'failure',
            };
        case actionTypes.createAccount.resetError:
            return {
                ...state,
                error: '',
                status: '',
            };
        case actionTypes.session.setNoExistingCreationSession:
            return {
                ...state,
                error: '',
                status: '',
            }
        case actionTypes.createAccount.updateValue:
            return {
                ...state,
                [action.key]: action.value,
            };
        case actionTypes.createAccount.setAccountCreationStatus:
            return {
                ...state,
                percentCompleted: action.data.PercentCompleted,
            }
        case actionTypes.createAccount.accountCreationCompleted:
            return {
                ...state,
                percentCompleted: 100,
            }
        default:
            return state;
    }
}