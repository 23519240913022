import {client} from "./ApiClient";
import {isNullOrUndefined, mapServiceUrls} from "../Helpers/Globals";
import {useQuery} from "react-query";
import {Settings} from "../Settings";

const getAlert = (key, serviceUrls, accessKey, alertUid) => {
    return client(mapServiceUrls(serviceUrls).LoyaltyEngineApiUrl, `api/v4/${accessKey}/alerts?uid=${alertUid}`)
}

export default function useAlert(serviceUrls, sessionKey, alertUid) {
    const {status, data, error } = useQuery(['Alert', alertUid], (key) => getAlert(key, serviceUrls, sessionKey, alertUid), {enabled: !!sessionKey && !!serviceUrls, staleTime: 1000*10})
    const alert = data ? data.data : undefined
    return {status, alert, error}
}