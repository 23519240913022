import React, {useEffect, useState} from 'react';
import {TextField, useTheme} from "@material-ui/core";

export default function PhoneInput(props) {
    const theme = useTheme()
    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    const handleChange = (event) => {
        event.preventDefault();
        setValue(event.target.value);
        props.handleChange(props.id, event.target.value);
    };

    const mapValue = (value) => {
        const numbers = value.replace(/\D[^\.]/g, "");
        if (value.length < 4)
            return value
        if (value.length > 3 && value.length < 7)
            return numbers.slice(0, 3) + "-" + numbers.slice(3, 6)
            
        return numbers.slice(0, 3) + "-" + numbers.slice(3, 6) + "-" + numbers.slice(6)
    }

    return (
        <TextField
            color={'secondary'}
            className={'center'}
            style={{width: props.width !== undefined ? props.width : '80%'}}
            id={props.id}
            label={props.label}
            value={mapValue(value)}
            required={props.required ? props.required : false}
            onChange={handleChange}
            disabled={props.disabled === true}
            variant={props.variant !== undefined ? props.variant : 'standard'}
            inputMode={"none"}
            inputProps={{ style: { fontSize: "1.5rem" } }}
        />
    );
}