import React from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';

import { setDomain, setMerchantData, setSessionKey, setConnectorKey, setProvisionKey } from '../Redux/Actions/Session';
import { setLoginKey } from '../Redux/Actions/Login';
import { restoreProvisionStoreForm, setStoreData } from '../Redux/Actions/ProvisionStore';
import { restoreBackOfficeConnectorForm } from '../Redux/Actions/BackOfficeConnector';
import { setDeviceData } from '../Redux/Actions/ProvisionDevice';

const ReturnLanding = (props) => {
    const dispatch = useDispatch();

    const parsed = queryString.parse(props.location.search);
    let name = parsed.devicename;
    let code = parsed.devicecode;
    let storeCode = parsed.storecode;
    let storeName = parsed.storename;
    let domain = parsed.domain;
    let accessKey = parsed.accessKey;
    let connectorKey = parsed.connectorKey;
    let provisionKey = parsed.provisionKey;
    dispatch(setLoginKey(accessKey));
    dispatch(setDomain(domain));
    dispatch(setDeviceData(name, code));
    dispatch(setStoreData(storeName, storeCode));
    dispatch(setMerchantData(storeCode, storeName));
    dispatch(setSessionKey(accessKey));
    dispatch(setConnectorKey(connectorKey));
    dispatch(setProvisionKey(provisionKey));
    dispatch(restoreProvisionStoreForm());
    dispatch(restoreBackOfficeConnectorForm());

    return (
        <Redirect to={'/devicesetup' + window.location.search} />
    )
}

export default ReturnLanding;