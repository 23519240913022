import initialState from "../InitialState";
import actionTypes from "../Actions/ActionTypes";
import {statuses} from "../../Helpers/Status";
import {safeSetSessionStorage, setSessionLoginDomain} from "../../Helpers/Globals";


export const sessionReducer = (state = initialState.session, action) => {
    switch (action.type) {
        case actionTypes.session.setSessionKey:
            safeSetSessionStorage('blSessionKey', action.sessionKey)
            return {
                ...state,
                sessionKey: action.sessionKey
            };
        case actionTypes.session.setUsageId:
            return {
                ...state,
                usageId: action.usageId,
            }
        case actionTypes.session.setConnectorKey:
            return {
                ...state,
                connectorKey: action.connectorKey
            };
        case actionTypes.session.setDomain:
            setSessionLoginDomain(action.domain)
            return {
                ...state,
                domain: action.domain,
            }
        case actionTypes.session.setMerchantData:
            return {
                ...state,
                merchantId: action.merchantId,
                merchantName: action.merchantName,
            };
        case actionTypes.session.setProvisionKey:
            return {
                ...state,
                provisionKey: action.provisionKey,
                keySet: true,
            }
        case actionTypes.session.getProvisionSessionSuccess:
            if (state.provisionKey !== action.response.data.ProvisionSessionKey) {
                window.location.href = (window.location.origin + window.location.pathname + window.location.search).replace(state.provisionKey, action.response.data.ProvisionSessionKey)
            }
            return {
                ...state,
                connectorKey: action.response.data.ConnectorUid,
                ProvisionSession: action.response.data,
                provisionUid: action.response.data.Uid,
                provisionKey: action.response.data.ProvisionSessionKey
            }
        case actionTypes.session.getProvisionSessionFailure:
            return {
                ...state,
                status: statuses.error,
                error: action.error,
            }
        case actionTypes.session.sessionRestored:
            return {
                ...state,
                status: statuses.finished,
            }
        case actionTypes.session.resetError:
            return {
                ...state,
                error: '',
                status: '',
            }
        case actionTypes.session.setExistingCreationSession:
            return {
                ...state,
                existingSession: true,
            }
        case actionTypes.session.setRedirectUrl:
            return {
                ...state,
                RedirectUrl: action.url
            }
        default:
            return state;
    }
}