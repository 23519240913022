import initialState from "../InitialState";
import actionTypes from "../Actions/ActionTypes";
import { statuses } from "../../Helpers/Status";


export const provisionDeviceReducer = (state = initialState.provisionDevice, action) => {
    switch (action.type) {
        case actionTypes.provisionDevice.setDeviceData:
            return {
                ...state,
                device: {
                    Code: action.code,
                    Name: action.name,
                }
            }
        case actionTypes.provisionDevice.checkProvisionStatus:
            return {
                ...state,
                status: statuses.loading,
            };
        case actionTypes.provisionDevice.checkProvisionStatusSuccess:
            let data = action.data;
            if (data.data.Device === null) {
                return {
                    ...state,
                    status: statuses.notFound,
                };
            } else {
                if (!data.data.IsProvisioned)
                    return {
                        ...state,
                        device: data.data.Device,
                        status: statuses.found,
                    };
                else {
                    return {
                        ...state,
                        device: data.data.Device,
                        status: statuses.provisioned,
                    }
                }
            }
        case actionTypes.provisionDevice.checkProvisionStatusFailure:
            return {
                ...state,
                error: action.error,
                status: 'error',
            };
        case actionTypes.provisionDevice.provisionDeviceRequest:
            return {
                ...state,
                status: statuses.loading,
            };
        case actionTypes.provisionDevice.provisionDeviceRequestFailure:
            return {
                ...state,
                error: action.error,
                status: statuses.error,
            };
        case actionTypes.provisionDevice.provisionDeviceRequestSuccess:
            let result = action.data;
            return {
                ...state,
                device: result.data.Device,
                deviceKey: result.data.DeviceKey,
                status: statuses.provisioned,
            };
        default:
            return state;
    }
}