import React, {useEffect, useState} from 'react';
import {FormControl, Grid, Input, InputAdornment, InputLabel, Tooltip} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {TextField} from "@material-ui/core";


export default function TextInputWithHelpText(props) {
    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    const handleChange = (event) => {
        event.preventDefault();
        setValue(event.target.value);
        props.handleChange(props.id, event.target.value);
    };

    // return (
    //     <FormControl color={'secondary'} variant="standard" className='input'>
    //         <InputLabel color={'secondary'} style={{}} htmlFor={props.id}>{`${props.label}${props.required ? '*' : ''}`}</InputLabel>
    //         <Input
    //             style={{marginTop: '5px', marginBottom: '5px', width: '260px', height: '38px'}}
    //             id={props.id}
    //             label={props.label}
    //             value={value}
    //             required={props.required ? props.required : false}
    //             onChange={handleChange}
    //             disabled={props.disabled === true}
    //             color={"secondary"}
    //             endAdornment={
    //                 <InputAdornment style={{cursor: 'pointer'}} position="end">
    //                     <Tooltip title={props.tooltip}>
    //                         <InfoOutlinedIcon />
    //                     </Tooltip>
    //                 </InputAdornment>
    //             }
    //         />
    //     </FormControl>
    // );
    return (
        <Grid container className={'center'} style={{maxWidth: '260px'}}>
            <Grid item xs={10}>
                <TextField
                    color={'secondary'}
                    style={{marginTop: '5px', marginBottom: '5px', width: '100%'}}
                    id={props.id}
                    label={props.label}
                    value={value}
                    required={props.required ? props.required : false}
                    onChange={handleChange}
                    disabled={props.disabled === true}
                    variant={props.variant !== undefined ? props.variant : 'standard'}
                />
            </Grid>
            <Grid item xs={2} style={{paddingTop: '20px'}}>
                <Tooltip title={props.tooltip} style={{cursor: 'pointer'}}>
                    <InfoOutlinedIcon />
                </Tooltip>
            </Grid>
        </Grid>
    );
}