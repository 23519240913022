import React, {useEffect, useState} from 'react';
import {useEditionContext} from "../../Utils/Contexts/EditionContext";
import useEditionDefinition from "../../Utils/UseEditionDefinition";
import {useSelector} from "react-redux";
import ServiceProviderRow from "./ServiceProviderRow";
import Header from "../Header";
import useClientServiceProviders from "../../Utils/UseClientServiceProviders";
import LoadingSpinner from "../LoadingSpinner";
import {MDBBtn} from "mdbreact";
import {Redirect} from "react-router-dom";
import useActivateService from "../../Utils/UseActivateService";
import queryString from "query-string";
import useClientApplications from "../../Utils/UseClientApplications";

export default function AutoProvisionServicePage(props) {
    const sessionKey = useSelector(state => state.session.sessionKey);
    const serviceUid = useSelector(state => state.session.ProvisionSession.ServiceUid)
    const {serviceProviders, status: serviceProvidersStatus, error: serviceProviderErrors} = useClientServiceProviders(sessionKey)
    const {applications, status: appsStatus, error: appsErrors} = useClientApplications(sessionKey)
    const [clientServiceUid, setClientServiceUid] = useState('')
    const [next, setNext] = useState(false)

    const activateService = useActivateService()
    
    useEffect(() => {
        const lower = window.location.search.toLowerCase();
        const parsed = queryString.parse(lower);
        if (parsed.tonext === 'true')
            nextStep()
    })
    
    useEffect(() => {
        if (serviceProviders)
            setClientServiceUid(serviceProviders.find(sp => sp.ServiceUid === serviceUid)?.ClientServiceUid ?? 'NotFound')
    }, [serviceProviders])
    
    useEffect(() => {
        if (clientServiceUid === 'NotFound') {
            activateServiceProvider()
        }
    }, [clientServiceUid])

    useEffect(() => {
        if (activateService.status === 'success') {
            setClientServiceUid(activateService.data.data.EntityUid)
        }
    }, [activateService.status])

    const nextStep = () => {
        props.nextStep();
    }

    const activateServiceProvider = () => {
        activateService.mutate({accessKey: sessionKey, service: {
                ServiceType: "",
                ClientUid: '',
                ServiceUid: serviceUid
            }})
    }
    
    if (clientServiceUid !== 'NotFound' && clientServiceUid !== '')
        return <Redirect to={`/provisionservice/${serviceUid}/${clientServiceUid}${window.location.search}`} />
    
    return (
        <>
            <Header title={'Activating your service provider'} />
            <LoadingSpinner/>
        </>
    )
}