import {useQuery} from 'react-query';
import {Settings} from '../Settings';
import {client} from "./ApiClient";

const getServiceUrls = (key, domain) => client(Settings.DomainServerUrl, 'api/v4/serviceurls/' + domain);

export default function useServiceUrls(domain) {
    const { status, data, error, isFetching } = useQuery(['ServiceUrls', domain], (key) => getServiceUrls(key, domain), {
        enabled: !!domain,
        staleTime: 1000*60*30
    });
    const serviceUrlsStatus = status;
    const serviceUrls = data ? data.data : undefined;
    const serviceUrlsError = error;
    const isServiceUrlsFetching = isFetching;
    return {serviceUrlsStatus, serviceUrls, serviceUrlsError, isServiceUrlsFetching}
}