import actionTypes from './ActionTypes';

export const setDeviceData = (name, code) => {
    return {
        type: actionTypes.provisionDevice.setDeviceData,
        name,
        code,
    };
}

export const checkProvisionStatus = (storeCode, deviceCode) => {
    return {
        type: actionTypes.provisionDevice.checkProvisionStatus,
        storeCode,
        deviceCode,
    };
}

export const checkProvisionStatusSuccess = (data) => {
    return {
        type: actionTypes.provisionDevice.checkProvisionStatusSuccess,
        data,
    };
}

export const checkProvisionStatusFailure = (error) => {
    return {
        type: actionTypes.provisionDevice.checkProvisionStatusFailure,
        error
    };
}

export const provisionDeviceRequest = (deviceName, deviceCode, storeCode) => {
    return {
        type: actionTypes.provisionDevice.provisionDeviceRequest,
        deviceName,
        deviceCode,
        storeCode,
    };
}

export const provisionDeviceRequestSuccess = (data) => {
    return {
        type: actionTypes.provisionDevice.provisionDeviceRequestSuccess,
        data,
    }
}

export const provisionDeviceRequestFailure = (error) => {
    return {
        type: actionTypes.provisionDevice.provisionDeviceRequestFailure,
        error
    }
}

export const setDeviceKeySuccess = () => {
    return {
        type: actionTypes.provisionDevice.setDeviceKeySuccess,
    }
}

export const setDeviceKeyFailure = (error) => {
    return {
        type: actionTypes.provisionDevice.setDeviceKeyFailure,
        error,
    }
}

export const error = (error) => {
    return {
        type: actionTypes.provisionDevice.error,
        error,
    }
}

export const resetProvisionDeviceError = () => {
    return {
        type: actionTypes.provisionDevice.resetError,
    }
}