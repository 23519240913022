import {client} from "./ApiClient";
import {mapServiceUrls} from "../Helpers/Globals";
import {useQuery} from "react-query";

const getCustomer = (key, urls, accessKey, uid) => {
    return client(urls.LoyaltyEngineApiUrl, `api/v4/${accessKey}/Customers/${uid}`)
}

export default function useCustomer(urls, accessKey, uid) {
    const {status, data, error } = useQuery(['customer', uid], (key) => getCustomer(key, mapServiceUrls(urls), accessKey, uid), {enabled: !!accessKey && !!urls && !!uid, staleTime: 1000*5*60})
    const customer = data ? data.data : undefined
    return {status, customer, error}
}