import {client} from "./ApiClient";
import {mapServiceUrls} from "../Helpers/Globals";
import {useQuery} from "react-query";
import {Settings} from "../Settings";

const getClientServiceProviders = (key, accessKey, editionCode) => {
    return client(Settings.DomainServerUrl, `api/v4/${accessKey}/ServiceActivations/ClientServiceProviders`)
}

export default function useClientServiceProviders(accessKey) {
    const {status, data, error, refetch } = useQuery(['ServiceProviders', accessKey], (key) => getClientServiceProviders(key, accessKey), {enabled: !!accessKey, staleTime: 1000*10})
    const serviceProviders = data ? data.data : undefined
    return {status, serviceProviders, error, refetch}
}