import React, {useEffect, useMemo, useState} from 'react';
import QuickSignup, {mapSignupField} from "../QuickSignUp/QuickSignup";
import Header from "../Header";
import {Button} from "@material-ui/core";
import useSetCartCustomer from "../../Utils/UseSetCartCustomer";
import useServiceUrls from "../../Utils/UseServiceUrls";
import queryString from "query-string";
import useGetCart from "../../Utils/UseGetCart";
import LoadingSpinner from "../LoadingSpinner";
import {closeWindow, getQueryString, onSnippetComplete} from "../../Helpers/Globals";
import useAvailableCoupons from "../../Utils/UseAvailableCoupons";
import CustomerFacingCouponSection from "./CustomerFacingCouponSection";
import useApplyCoupon from "../../Utils/UseApplyCoupon";
import CartCouponSummary from "../PreviewDiscount/CartCouponSummary";
import CustomPhoneInput from "./CustomPhoneInput";
import useResolveCustomer from "../../Utils/UseResolveCustomer";
import useSignupCustomer from "../../Utils/UseSignupCustomer";
import SimpleCartLoyaltySummary from "./SimpleCartLoyaltySummary";
import {AppBar, Grid, Toolbar, Typography} from "@mui/material";
import useCalculateCartForCustomerFacingSnippet from "../../Utils/UseCalculateCartForCustomerFacingSnippet";

const title = 'Rewards (Customizable)'
const fields = [
    {
        FieldName: 'EmailAddress',
        IsRequired: true
    }
]
// const fields = [
//     {
//         FieldName: 'MobilePhone',
//         IsRequired: true
//     }
// ]
const getResolveCustomerObject = (mobilePhone) => {
    return {
        MobilePhone: mobilePhone,
        FirstName: '',
        LastName: '',
        ExternalId: '',
        Code: '',
        Uid: '',
        LoyaltyCardNumber: '',
        EmailAddress: '',
        Phone1: '',
        CompanyName: ''
    }
}

export default function CustomerFacingSnippet(props) {
    const [sessionKey, setSessionKey] = useState('');
    const [loginDomain, setLoginDomain] = useState('');
    const [cartUid, setCartUid] = useState('');
    const [cartExternalId, setCartExternalId] = useState('');
    const [cartSourceExternalId, setCartSourceExternalId] = useState('');
    const [returnUrl, setReturnUrl] = useState('')
    const [deviceCode, setDeviceCode] = useState('')
    const [storeCode, setStoreCode] = useState('')
    const { serviceUrlsStatus, serviceUrls, serviceUrlsError } = useServiceUrls(loginDomain);
    // const { status: cartStatus, cartResponse, error: cartError, refetch } = useGetCart(serviceUrls, sessionKey, cartUid, cartExternalId, cartSourceExternalId, deviceCode, storeCode);
    const {status: couponsStatus, coupons, error: couponsError, refetch: refetchCoupons} = useAvailableCoupons(serviceUrls, sessionKey, cartUid, cartExternalId, cartSourceExternalId, deviceCode, storeCode)
    
    const [couponAdded, setCouponAdded] = useState('')
    const [isCustomerSet, setIsCustomerSet] = useState(false)
    const [phone, setPhone] = useState('')
    const [cartResponse, setCartResponse] = useState(undefined)

    const {status: resolveStatus, resolvedCustomerResponse, error: resolveError, refetch: refetchResolve} = useResolveCustomer(serviceUrls, sessionKey, getResolveCustomerObject(phone), deviceCode, storeCode)
    const setCartCustomer = useSetCartCustomer()
    const apply = useApplyCoupon()
    const signup = useSignupCustomer()
    const calcCart = useCalculateCartForCustomerFacingSnippet()
    
    const loyaltySummary = useMemo(() => cartResponse?.LoyaltySummary, [cartResponse])
    const availableCoupons = useMemo(() => coupons?.filter(c => c.Type === 'Awarded' || c.EnableChoiceRewards) ?? [], [coupons])
    
    const calcCartCall = () => {
        calcCart.mutate({urls: serviceUrls, cartUid, cartExternalId, cartSourceExternalId, accessKey: sessionKey, deviceCode, storeCode})
    }
    
    const updateCart = () => {
        calcCartCall()
        refetchCoupons()
    }

    useEffect(() => {
        setSessionKey(getQueryString(window.location.href, 'bl_sk') ?? getQueryString(window.location.href,'SessionKey') ?? '');
        setLoginDomain(getQueryString(window.location.href, 'bl_LoginDomain') ?? getQueryString(window.location.href,   'LoginDomain') ?? '');
        setReturnUrl(getQueryString(window.location.href, 'bl_rurl') ?? getQueryString(window.location.href,'ReturnUrl') ?? '')
        setCartUid(getQueryString(window.location.href, 'bL_CartUid') ?? getQueryString(window.location.href,'CartUid') ?? '');
        setCartExternalId(getQueryString(window.location.href, 'bl_CartExternalId') ?? getQueryString(window.location.href,'cartExternalId') ?? '')
        setCartSourceExternalId(getQueryString(window.location.href, 'bl_CartSourceExternalId') ?? getQueryString(window.location.href,'CartSourceExternalId') ?? '')
        setDeviceCode(getQueryString(window.location.href, 'bl_DeviceCode'))
        setStoreCode(getQueryString(window.location.href, 'bl_StoreCode'))
    }, [window.location.href])
    
    useEffect(() => {
        if (serviceUrls)
            calcCartCall()
    }, [serviceUrls])

    useEffect(() => {
        if (apply.status === 'success') {
            updateCart()
            setTimeout(() => setCouponAdded(''), 2000)
        }
    }, [apply.status])

    useEffect(() => {
        if (setCartCustomer.status === 'success') {
            setIsCustomerSet(true)
            updateCart()
        }
    }, [setCartCustomer.status])

    useEffect(() => {
        if (signup.status === 'success') {
            const customer = signup.data.data.Entity
            setCartCustomer.mutate({urls: serviceUrls, cartUid, cartExternalId, cartSourceExternalId, customer, accessKey: sessionKey, deviceCode, storeCode})
        }
    }, [signup.status])
    
    useEffect(() => {
        
        if (cartResponse && cartResponse?.Cart.Customer !== null)
            setIsCustomerSet(true)
    }, [cartResponse])
    
    useEffect(() => {
        if (calcCart.status === 'success')
            setCartResponse(calcCart.data.data)
    }, [calcCart.status])
    
    useEffect(() => {
        if (resolveStatus === 'success') {
            if (resolvedCustomerResponse === undefined)
                signup.mutate({
                    urls: serviceUrls,
                    accessKey: sessionKey,
                    customer: {MobilePhone: phone},
                    groupsToJoin: [],
                    deviceCode,
                    storeCode
                })
            else
                setCartCustomer.mutate({urls: serviceUrls, cartUid, cartExternalId, cartSourceExternalId, customer: resolvedCustomerResponse, accessKey: sessionKey, deviceCode, storeCode})
        }
    }, [resolveStatus])

    const handleChange = (value) => {
        setPhone(value)
    }
    
    const applyCoupon = (coupon) => {
        apply.mutate({urls: serviceUrls, cartUid, cartExternalId, cartSourceExternalId, uid: coupon.Uid, code: coupon.Code, quantity: 1, accessKey: sessionKey, deviceCode, storeCode})
        setCouponAdded(coupon.Name)
    }
    
    const login = () => {
        refetchResolve()
    }
    
    const buildNameString = () => {
        const firstName = cartResponse.Cart.Customer?.FirstName
        const hasFirstName = firstName !== null && firstName !== undefined && firstName !== ''
        if (hasFirstName)
            return `Hi ${firstName}`
        
        return 'Hi'
    }
    
    const close = () => {
        onSnippetComplete(returnUrl)
    }
    
    return (
        <>
            {calcCart.error === 'Invalid credential' && <p>Session Key is expired.</p>}
            {calcCart.status === 'success' && cartResponse === undefined && <p>Cart not found.</p>}
            {cartResponse && calcCart.status === 'success' &&
                <>
                    {isCustomerSet && loyaltySummary && 
                        <>
                            <AppBar style={{ background: '#ececec', color: 'black', height: '64px' }} position={'sticky'}>
                                <Toolbar style={{width: '100%'}} sx={{ justifyContent: "space-between" }}>
                                    <Typography style={{marginTop: '5px'}} variant="h5" color="inherit">
                                        {`${buildNameString()}! You have ${cartResponse.Cart.Customer?.CurrentLoyaltyPoints} points`}
                                    </Typography>
                                    <Button onClick={close} style={{marginRight: '5px', marginTop: '5px'}} variant='contained' color='primary'>Done</Button>
                                </Toolbar>
                            </AppBar>
                            {couponsStatus === 'success' && apply.status !== 'loading' && <CustomerFacingCouponSection coupons={availableCoupons} applyCoupon={applyCoupon} appliedCoupons={loyaltySummary?.AppliedCoupons}/>}
                            {(couponsStatus === 'loading' || apply.status === 'loading') && <LoadingSpinner />}
                        </>
                    }
                    {(!isCustomerSet || calcCart.status !== 'success') &&
                        <>
                            <CustomPhoneInput value={phone} onChange={handleChange} login={login}/>
                        </>
                    }
                    {setCartCustomer.status === 'loading' && <LoadingSpinner />}
                </>
            }
            {calcCart.status === 'loading' && <LoadingSpinner />}
            
        </>
    )
}