import React, { useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { statuses } from '../../../Helpers/Status';
import { checkProvisionStatus, provisionDeviceRequest } from '../../../Redux/Actions/ProvisionDevice';
import Header from '../../Header';
import ProvisionNewDevice from './ProvisionNewDevice';
import ProvisionExistingDevice from './ProvisionExistingDevice';
import LoadingSpinner from '../../LoadingSpinner';
import {Next} from "react-bootstrap/PageItem";


const FormProvisionDevice = (props) => {
    const device = useSelector(state => state.provisionDevice.device);
    const store = useSelector(state => state.provisionStore.store);
    const status = useSelector(state => state.provisionDevice.status);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(provisionDeviceRequest(device.Name, device.Code, store.Code));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(() => {
        if (status === statuses.provisioned)
            props.nextStep()
    }, [status])

    return (
        <Fragment>
            <Header title='Provision Device' />
            <p>Setting up your device</p>
            <LoadingSpinner />
        </Fragment>
    )
}

export default FormProvisionDevice;