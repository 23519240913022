import actionTypes from './ActionTypes';

export const restoreBackOfficeConnectorForm = () => {
    return {
        type: actionTypes.backOfficeConnector.restoreBackOfficeConnectorForm,
    };
};

export const setBackOfficeReady = () => {
    return {
        type: actionTypes.backOfficeConnector.setBackOfficeReady,
    };
};