import React, { Fragment } from 'react';
import { MDBBtn } from 'mdbreact';

import Header from '../../Header';
import {useSelector} from "react-redux";
import {Button} from "@mui/material";

export default function ProvisionExistingStore(props) {
    const percent = useSelector(state => state.createAccount.percentCompleted);

    return (
        <Fragment>
                <Header title="Store Details"  step={percent === 100 ? 4 : 1} totalSteps={percent === 100 ? 5 : 2}/>
                <h4>Your {props.name} store is not provisioned, would you like to provision it?</h4>
                <br />
                <Button className="btn-pair next-btn" id="confirm" type="button" onClick={props.provisionStore}>Provision</Button>
            </Fragment>
    )
}
