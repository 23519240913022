

const actionTypes = {
    login: {
        submitLoginRequest: "login/submitLoginRequest",
        loginRequestSuccess: "login/loginRequestSuccess",
        loginRequestFailure: "login/loginRequestFailure",
        setLoggedIn: "login/setLoggedIn",
        setLoggedOut: "login/setLoggedOut",
        resetError: "login/resetError",
        loginFailed: "login/loginFailed",
        setLoginKey: "login/setLoginKey",
    },
    resetPassword: {
        resetPasswordRequest: "resetPassword/resetPasswordRequest",
        resetPasswordRequestSuccess: "resetPassword/resetPasswordRequestSuccess",
        resetPasswordRequestFailure: "resetPassword/resetPasswordRequestFailure",
        resetError: "resetPassword/resetError",
    },
    createAccount: {
        validateLoginDomain: "createAccount/validateLoginDomain",
        validateLoginSuccess: "createAccount/validateLoginDomainSuccess",
        validateLoginFailure: "createAccount/validateLoginDomainFailure",
        createAccountRequest: "createAccount/createAccountRequest",
        createAccountRequestSuccess: "createAccount/createAccountRequestSuccess",
        createAccountRequestFailure: "createAccount/createAccountRequestFailure",
        checkAccountCreationStatus: "createAccount/checkAccountCreationStatus",
        setAccountCreationStatus: "createAccount/setAccountCreationStatus",
        accountCreationCompleted: "createAccount/accountCreationCompleted",
        resetAccountCreationSession: "createAccount/resetAccountCreationSession",
        error: "createAccount/error",
        resetError: "createAccount/resetError",
        updateValue: "createAccount/updateValue",
    },
    provisionStore: {
        setStoreData: "provisionStore/setStoreData",
        checkProvisionStatus: "provisionStore/checkProvisionStatus",
        checkProvisionStatusSuccess: "provisionStore/checkProvisionStatusSuccess",
        checkProvisionStatusFailure: "provisionStore/checkProvisionStatusFailure",
        provisionStoreRequest: "provisionStore/provisionStoreRequest",
        provisionStoreRequestSuccess: "provisionStore/provisionStoreRequestSuccess",
        provisionStoreRequestFailure: "provisionStore/provisionStoreRequestFailure",
        restoreProvisionStoreForm: "provisionStore/restoreProvisionStoreForm",
        error: "provisionStore/error",
        resetError: "provisionStore/resetError",
    },
    backOfficeConnector: {
        restoreBackOfficeConnectorForm: "backOfficeConnector/restoreBackOfficeConnectorForm",
        setBackOfficeReady: "backOfficeConnector/setBackOfficeReady",
    },
    provisionDevice: {
        setDeviceData: "provisionDevice/setDeviceData",
        checkProvisionStatus: "provisionDevice/checkProvisionStatus",
        checkProvisionStatusSuccess: "provisionDevice/checkProvisionStatusSuccess",
        checkProvisionStatusFailure: "provisionDevice/checkProvisionStatusFailure",
        provisionDeviceRequest: "provisionDevice/provisionDeviceRequest",
        provisionDeviceRequestSuccess: "provisionDevice/provisionDeviceRequestSuccess",
        provisionDeviceRequestFailure: "provisionDevice/provisionDeviceRequestFailure",
        setDeviceKeySuccess: "provisionDevice/setDeviceKeySuccess",
        setDeviceKeyFailure: "provisionDevice/setDeviceKeyFailure",
        error: "provisionDevice/error",
        resetError: "provisionDevice/resetError",
    },
    createStore: {
        setStoreData: "createStore/setStoreData",
        createStoreRequest: "createStore/createStoreRequest",
        createStoreRequestSuccess: "createStore/createStoreRequestSuccess",
        createStoreRequestFailure: "createStore/createStoreRequestFailure",
        error: "createStore/error",
        resetError: "createStore/resetError",
    },
    session: {
        setSessionKey: "session/setSessionKey",
        setUsageId: "session/setUsageId",
        setConnectorKey: "session/setConnectorKey",
        setProvisionKey: "session/setProvisionKey",
        getProvisionSession: "session/getProvisionSession",
        getProvisionSessionSuccess: "session/getProvisionSessionSuccess",
        getProvisionSessionFailure: "session/getProvisionSessionFailure",
        postProvisionSession: "session/postProvisionSession",
        postProvisionSessionSuccess: "session/postProvisionSessionSuccess",
        postProvisionSessionFailure: "session/postProvisionSessionFailure",
        checkExistingCreationSession: "session/checkExistingCreationSession",
        setExistingCreationSession: "session/setExistingCreationSession",
        setNoExistingCreationSession: "session/setNoExistingCreationSession",
        setDomain: "session/setDomain",
        setRedirectUrl: "session/setRedirectUrl",
        setMerchantData: "session/setMerchantData",
        sessionRestored: "session/sessionRestored",
        error: "session/error",
        resetError: "session/resetError",
    },
    PreviewDiscount: {
        setSessionData: "previewDiscount/setSessionData",
        getCart: "previewDiscount/getCart",
        setCart: "previewDiscount/setCart",
        getCartError: "previewDiscount/getCartError",
    }
}

export default actionTypes;