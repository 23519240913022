import {client} from "./ApiClient";
import {mapServiceUrls} from "../Helpers/Globals";
import {useMutation, useQuery, useQueryClient} from "react-query";

const setCartCustomer = ({urls, accessKey, cartUid, cartExternalId, cartSourceExternalId, customer, deviceCode, storeCode}) => {
    return client(mapServiceUrls(urls).LoyaltyEngineApiUrl, `api/v4/${accessKey}/carts/commands/setCustomer?cartUid=${cartUid === undefined ? null : cartUid}&externalId=${cartExternalId === undefined ? null : cartExternalId}&sourceExternalId=${cartSourceExternalId === undefined ? null : cartSourceExternalId}${!!storeCode ? `&storeCode=${storeCode}` : ''}${!!deviceCode ? `&deviceCode=${deviceCode}` : ''}`, {
        body: {
                ...customer,
        }
    })
}

export default function useSetCartCustomer() {
    return useMutation(setCartCustomer);
}