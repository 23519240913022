import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import LandingPage from "./LandingPage";
import Login from "./Login";
import CreateAccountForm from "./AccountCreation/CreateAccountForm";
import ResetPassword from "./ResetPassword";
import StoreAndDeviceSetupForm from "./StoreAndDeviceSetup/StoreAndDeviceSetupForm";
import ReturnLanding from "./ReturnLanding";
import PreviewDiscount from "./PreviewDiscount/PreviewDiscount";
import PreviewCart from "./PreviewCart/PreviewCart";
import Layout from "./Layout";
import {useDispatch, useSelector} from "react-redux";
import queryString from "query-string";
import {getProvisionSession, setDomain, setProvisionKey, setRedirectUrl} from "../Redux/Actions/Session";
import {QueryClient, QueryClientProvider} from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools'
import {EditionProvider} from "../Utils/Contexts/EditionContext";
import ProvisioningPage from "./ServiceProviders/ProvisioningPage";
import {getSessionLoginDomain} from "../Helpers/Globals";
import QuickSignup from "./QuickSignUp/QuickSignup";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import ApplyCoupon from "./ApplyCoupon/ApplyCoupon";
import KoronaCustomerFacingWrapper from "./CustomerFacing/KoronaCustomerFacingWrapper";
import QuickEdit from "./QuickEdit/QuickEdit";
import AlertSnippet from "./Alerts/AlertSnippet";
import CustomerFacingSnippet from "./CustomerFacing/CustomerFacingSnippet";
import CouponAlertSnippet from "./Alerts/CouponAlertSnippet";
import {LocalizationProvider} from "@mui/x-date-pickers";
import KoronaCustomerFacingLandingPage from "./CustomerFacing/KoronaCustomerFacingLandingPage";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import RecordEngagement from "./RecordEngagement/RecordEngagement";

const queryClient = new QueryClient();

let muiTheme = createTheme({
    palette: {
        primary: {
            main: '#393939'
        },
        secondary: {
            main: '#619700'
        }
    },
    button: {
        '&:active': {
            backgroundColor: '#393939'
        }
    },
    dateField: {
        '&:focused':
            {
                color: '#619700'
            }
    }
});

export default function Wrapper(props) {
    const dispatch = useDispatch();
    const keySet = useSelector(state => state.session.keySet)
    const domain = useSelector(state => state.session.domain)
    const [editionCode, setEditionCode] = useState('')
    const [editionUid, setEditionUid] = useState('')
    
    useEffect(() => {
        if (!domain) {
            const storedDomain = getSessionLoginDomain(window)
            if (!storedDomain)
                return
            
            dispatch(setDomain(storedDomain))
        }
            
    }, [domain])

    useEffect(() => {
        const path = window.location.pathname;
        if (!keySet && !path.toLowerCase().includes("pos")) {
            const lower = window.location.search.toLowerCase();
            const parsed = queryString.parse(lower);
            let provisionKey = parsed.bl_pk ?? parsed.provisionsessionkey;
            let redirectUrl = parsed.bl_rurl ?? parsed.returnurl;
            const edition = parsed.bl_edition ?? ''
            if (provisionKey !== undefined) {
                dispatch(setProvisionKey(provisionKey));
                dispatch(getProvisionSession())
            }
            dispatch(setRedirectUrl(redirectUrl))
            setEditionCode(edition)
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <BrowserRouter>
            <ThemeProvider theme={muiTheme}>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <QueryClientProvider client={queryClient} >
                        <Switch>
                            <Route exact path='/provisionservice/:providerUid' component={ProvisioningPage} />
                            <Route path='/POS/Korona/CustomerFacing' component={KoronaCustomerFacingWrapper} />
                            <Route path='/POS/Korona/ThankYou' component={KoronaCustomerFacingLandingPage} />
                            <Route path='/POS/CustomerFacing' component={CustomerFacingSnippet} />
                            <Route path='/POS/CouponAlert' component={CouponAlertSnippet} />
                            <Route path='/POS/QuickSignup' component={QuickSignup} />
                            <Route path='/POS/QuickEdit' component={QuickEdit} />
                            <Route path='/POS/ApplyCoupon' component={ApplyCoupon} />
                            <Route path='/POS/RecordEngagement' component={RecordEngagement} />
                            <Route path='/POS/previewdiscount' component={PreviewDiscount} />
                            <Route path='/POS/previewcart' component={PreviewCart} />
                        </Switch>
                        <Layout>
                            <EditionProvider editionCode={editionCode} editionUid={editionUid} setEditionUid={setEditionUid}>
                                    <Switch>
                                        <Route exact path='/' component={LandingPage} />
                                        <Route path='/login' component={Login} />
                                        <Route path='/createaccount' component={CreateAccountForm} />
                                        <Route path='/resetpassword' component={ResetPassword} />
                                        <Route path='/devicesetup' component={StoreAndDeviceSetupForm} />
                                        <Route path='/restorestate' component={ReturnLanding} />
                                        <Route path='/provisionservice/:providerUid/:serviceIntegrationUid' component={ProvisioningPage} />
                                        
                                    </Switch>
                                <ReactQueryDevtools initialIsOpen={false} />
                            </EditionProvider>
                        </Layout>
                    </QueryClientProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </BrowserRouter>
    )
}