import actionTypes from "./ActionTypes"

export const getCart = (sessionKey, loginDomain, cartUid, externalId, sourceExternalId) => {
    return {
        type: actionTypes.PreviewDiscount.getCart,
        sessionKey,
        loginDomain,
        cartUid,
        externalId,
        sourceExternalId,
    }
}

export const setCart = (data) => {
    return {
        type: actionTypes.PreviewDiscount.setCart,
        data,
    }
}

export const getCartError = (error) => {
    return {
        type: actionTypes.PreviewDiscount.getCartError,
        error,
    }
}