import React, {Fragment, useEffect} from 'react'
import { MDBCard, MDBCardHeader, MDBCardBody, MDBCardText } from 'mdbreact'

export default function CartPricingSummary(props) {

    useEffect(() => {
        let strings = props.prices.map(mapPrice)
        const result = strings.join("<br >")
        const element = document.getElementById('pricing-card')
        if (element)
            element.innerHTML = result
    }, [])

    const mapPrice = (price, index) => {
        return "Applied Price: " + price.Name + " (" + price.Code + ")"
    }

    return (
        <Fragment>
            <MDBCard className="cart-summary-card">
                <MDBCardHeader className="cart-summary-card-header">Pricing</MDBCardHeader>
                <MDBCardBody style={{ textAlign: "left" }}>
                    <MDBCardText style={{ color: "#393939" }}>
                        {(props.prices !== undefined && props.prices.length > 0) &&
                            <span id={'pricing-card'} />
                        }
                        {(props.prices === undefined || props.prices.length === 0) &&
                            <Fragment>
                                No sale prices are available.
                            </Fragment>
                        }
                    </MDBCardText>
                </MDBCardBody>
            </MDBCard>
        </Fragment>
    )
}
