import React, {useEffect, useState} from 'react';
import {TextField} from "@material-ui/core";

export default function EmailInput(props) {
    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    const handleChange = (event) => {
        event.preventDefault();
        setValue(event.target.value);
        props.handleChange(props.id, event.target.value);
    };

    return (
        <TextField
            color={'secondary'}
            type='email'
            style={{marginTop: '5px', marginBottom: '5px', width: '260px'}}
            id={props.id}
            label={props.label}
            value={value}
            required={props.required ? props.required : false}
            onChange={handleChange}
            disabled={props.disabled === true}
        />
    );
}