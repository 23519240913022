import React, {useState} from 'react';
import {Dialog, DialogActions, DialogTitle} from "@mui/material";
import {Button} from "@material-ui/core";
import SelectInput from "../Inputs/SelectInput";
import {closeWindow, onSnippetComplete} from "../../Helpers/Globals";
import FindCustomer from "./FindCustomer";

export default function FindCustomerModal(props) {
    
    const cancel = () => {
        onSnippetComplete('')
    }
    
    return (
        <Dialog maxWidth={'lg'} fullWidth={true} open={true} scroll={'paper'}>
            <DialogTitle>Find Customer</DialogTitle>
            <div style={{padding: '0px 25px'}}>
                <FindCustomer close={props.close} />
            </div>
            <div style={{marginTop: '30px', marginRight: '30px', marginLeft: '30px'}}>
                <Button onClick={cancel} variant='text' className={'left button'} color={'primary'}>Cancel</Button>
            </div>
        </Dialog>
    )
}