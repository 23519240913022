import React, {createContext} from 'react';

// @ts-ignore
const AccountCreationContext = createContext();
AccountCreationContext.displayName = 'AccountCreationContext';

function AccountCreationProvider(props) {

    const status = props.status

    const value = React.useMemo(() => ({ status }), [
        props.status
    ]);

    return <AccountCreationContext.Provider value={value} {...props} />;
}

function useAccountCreationContext() {
    const context = React.useContext(AccountCreationContext);
    if (context === undefined) {
        throw new Error(`useAccountCreationContext must be used within a AccountCreationProvider`);
    }
    return context;
}

export {AccountCreationProvider, useAccountCreationContext};
