import {client} from "./ApiClient";
import {mapServiceUrls} from "../Helpers/Globals";
import {useMutation, useQuery, useQueryClient} from "react-query";

const calcCart = ({urls, accessKey, cartUid, cartExternalId, cartSourceExternalId, deviceCode, storeCode}) => {
    return client(mapServiceUrls(urls).LoyaltyEngineApiUrl, `api/v4/${accessKey}/carts/commands/calculates${storeCode ? `?storeCode=${storeCode}` : ''}${!!deviceCode ? `&deviceCode=${deviceCode}` : ''}`, {
        body: {
            Cart: {
                Uid: cartUid,
                ExternalId: cartExternalId,
                SourceExternalId: cartSourceExternalId,
            },
        }
    })
}

export default function useCalculateCartForCustomerFacingSnippet() {
    return useMutation(calcCart);
}