import React, {Fragment, useEffect} from 'react'
import { MDBCard, MDBCardHeader, MDBCardBody, MDBCardText } from 'mdbreact'

export default function CartCouponSummary(props) {

    useEffect(() => {
        let strings = props.coupons.map(mapCoupon)
        const result = strings.join("<br >")
        const element = document.getElementById('coupon-card')
        if (element)
            element.innerHTML = result
    }, [props.coupons])

    const mapCoupon = (coupon, index) => {
        let string = "";
        string += coupon.Redeemed ? "Applied: " : "Not Applied: ";
        string += coupon.DisplayName ? coupon.DisplayName : coupon.Name;
        string += coupon.Message ? " - " + coupon.Message : "";

        return string
    }

    return (
        <Fragment>
            <MDBCard className="cart-summary-card">
                <MDBCardHeader className="cart-summary-card-header">Applied Coupons</MDBCardHeader>
                <MDBCardBody style={{textAlign: "left"}}>
                    <MDBCardText style={{color: "#393939"}}>
                        {( props.coupons !== undefined && props.coupons.length > 0) &&
                            <span id={'coupon-card'} />
                        }
                        {(props.coupons === undefined || props.coupons.length === 0) &&
                            <Fragment>No coupons have been applied.</Fragment>
                        }
                    </MDBCardText>
                </MDBCardBody>
            </MDBCard>
        </Fragment>
    )
}
