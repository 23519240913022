import React, {createContext} from 'react';

// @ts-ignore
const EditionContext = createContext();
EditionContext.displayName = 'EditionContext';

function EditionProvider(props) {

    const editionCode = props.editionCode
    const editionUid = props.editionUid
    const setEditionUid = props.setEditionUid

    const value = React.useMemo(() => ({ editionCode, editionUid, setEditionUid }), [
        props.editionCode,
        props.editionUid,
        props.setEditionUid
    ]);

    return <EditionContext.Provider value={value} {...props} />;
}

function useEditionContext() {
    const context = React.useContext(EditionContext);
    if (context === undefined) {
        throw new Error(`useEditionContext must be used within a EditionProvider`);
    }
    return context;
}

export {EditionProvider, useEditionContext};
