import { ofType, } from 'redux-observable';
import { of, merge } from 'rxjs';
import { map, catchError, switchMap, mergeMap, withLatestFrom, tap, delay, mapTo } from 'rxjs/operators'

import { getServiceUrls } from './ServiceUrls';
import { resetPasswordRequestSuccess, resetPasswordRequestFailure } from '../Redux/Actions/ResetPassword';
import actionTypes from '../Redux/Actions/ActionTypes';

export const resetPasswordEpic = (action$, state$) => 
    action$.pipe(
        ofType(actionTypes.resetPassword.resetPasswordRequest),
        withLatestFrom(state$),
        switchMap(([action, state]) => 
            resetPasswordCall(action, action.domain, action.email)
        ),
    );
    

const resetPasswordCall = (action, domain, email) => {
    const observable = of(null);
    const delayedObservable = merge(
        observable.pipe(
            mapTo('success'),
            delay(2000))
    )
    return getServiceUrls(domain).pipe(
        tap(x => console.log(action)),
        mergeMap((x) => delayedObservable
        .pipe(
            map(response => resetPasswordRequestSuccess(response)),
            catchError(error => of(resetPasswordRequestFailure(error)))
        )));
}