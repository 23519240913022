import React, {Fragment, useEffect, useState} from 'react'
import Header from '../Header'
import { useDispatch, useSelector } from 'react-redux';
import { getCart } from '../../Redux/Actions/PreviewDiscount';
import PreviewCartTable from './PreviewCartTable';
import useServiceUrls from "../../Utils/UseServiceUrls";
import useGetCart from "../../Utils/UseGetCart";
import {getQueryString} from "../../Helpers/Globals";

export default function PreviewCart() {
    const [sessionKey, setSessionKey] = useState('');
    const [loginDomain, setLoginDomain] = useState('');
    const [cartUid, setCartUid] = useState('');
    const [cartExternalId, setCartExternalId] = useState('');
    const [cartSourceExternalId, setCartSourceExternalId] = useState('');
    const { serviceUrlsStatus, serviceUrls, serviceUrlsError } = useServiceUrls(loginDomain);
    const { status: cartStatus, cartResponse, error: cartError } = useGetCart(serviceUrls, sessionKey, cartUid, cartExternalId, cartSourceExternalId);

    useEffect(() => {
        setSessionKey(getQueryString(window.location.href, 'bl_sk') ?? getQueryString(window.location.href,'SessionKey') ?? '');
        setLoginDomain(getQueryString(window.location.href, 'bl_LoginDomain') ?? getQueryString(window.location.href,'LoginDomain') ?? '');
        setCartUid(getQueryString(window.location.href, 'bL_CartUid') ?? getQueryString(window.location.href,'CartUid') ?? '');
        setCartExternalId(getQueryString(window.location.href, 'bl_CartExternalId') ?? getQueryString(window.location.href,'cartExternalId') ?? '')
        setCartSourceExternalId(getQueryString(window.location.href, 'bl_CartSourceExternalId') ?? getQueryString(window.location.href,'CartSourceExternalId') ?? '')
    }, [window.location.search])

    return (
        <Fragment>
            <Header title="Cart Summary" />
            <div className={'table-container center'} >
                {cartResponse &&
                    <PreviewCartTable cart={cartResponse.Cart} />
                }
            </div>
        </Fragment>
    )
}
