import {useMutation, useQueryClient} from "react-query";
import {client} from "./ApiClient";
import {Settings} from "../Settings";
import {mapServiceUrls} from "../Helpers/Globals";

const provisionService = ({accessKey, serviceUrls, clientServiceUid, serviceName, serviceIntegrationUid, provisionServiceWebhookUrl, useStagingUrl, configuration, provisionSessionUid, deviceCode, storeCode, orgUnitCode}) => {
    const body = {
        ClientServiceUid: clientServiceUid,
        ServiceName: serviceName,
        ServiceIntegrationUid: serviceIntegrationUid,
        ProvisionServiceWebhookUrl: provisionServiceWebhookUrl,
        ProvisionSessionUid: provisionSessionUid,
        Configuration: {
            CustomSettings: configuration
        },
        DeviceCode: deviceCode,
        StoreCode: storeCode,
        OrgUnitCode: orgUnitCode,
    }
    
    return client(mapServiceUrls(serviceUrls).GridApiUrl, `api/v4/${accessKey}/ServiceProvisioning/Services${useStagingUrl ? `?useStagingServiceUrl=${useStagingUrl}` : ''}`, {
        body: body
    })
}

export default function   useProvisionService() {
    const queryClient = useQueryClient();
    
    return useMutation(provisionService, {
        onSuccess: () => {
            queryClient.invalidateQueries('ServiceProviders')
        }
    })
}