import {client} from "./ApiClient";
import {mapServiceUrls} from "../Helpers/Globals";
import {useMutation, useQuery, useQueryClient} from "react-query";

const joinGroup = ({urls, accessKey, customerUid, groupUid}) => {
    return client(mapServiceUrls(urls).LoyaltyEngineApiUrl, `api/v4/${accessKey}/subscriberGroups/commands/joins`, {
        body: {
            // MembershipUid,
            CustomerUid: customerUid,
            SubscriberGroupUid: groupUid
        }
    })
}

export default function useJoinGroup() {
    return useMutation(joinGroup);
}