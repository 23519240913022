import React, {useState} from "react";
import {Checkbox, FormControlLabel} from "@mui/material";

export default function CheckboxInput(props) {
    const [value, setValue] = useState(props.value)

    const handleChange = (e) => {
        e.persist();
        setValue(e.target.checked)
        props.handleChange(e.target.checked, props.fieldName)
    }

    return (
        <>
            <FormControlLabel control={<Checkbox title={props.label} checked={value} onChange={handleChange} name={props.fieldName} color='default' inputProps={{ 'aria-label': 'controlled' }} />} label={props.label} />
            <br />
        </>
    )
}