import initialState from "../InitialState";
import actionTypes from "../Actions/ActionTypes";
import {safeSetSessionStorage} from "../../Helpers/Globals";


export const loginReducer = (state = initialState.login, action) => {
    switch (action.type) {
        case actionTypes.login.submitLoginRequest:
            return {
                ...state,
                loggedIn: '',
            }
        case actionTypes.login.loginRequestSuccess:
            safeSetSessionStorage('blUserAccessKey', action.data.SessionKey)
            return {
                ...state,
                status: 'success',
                key: action.data.SessionKey,
                usageId: action.data.UsageId,
                editionUid: action.data.User.EditionUid
            };
        case actionTypes.login.loginRequestFailure:
            return {
                status: 'error',
                loggedIn: false,
                error: action.error,
                code: action.error.code,
            };

        case actionTypes.login.setLoggedIn:
            return {
                ...state,
                loggedIn: true,
            }
        case actionTypes.login.setLoggedOut:
            return {
                ...state,
                loggedIn: false,
            }
        case actionTypes.login.resetError:
            return {
                ...state,
                error: '',
                status: '',
            }
        case actionTypes.login.setLoginKey:
            sessionStorage.setItem('blUserAccessKey', action.accessKey)
            return {
                ...state,
                key: action.accessKey,
            }
        default:
            return state;
    }
}