import {ofType,} from 'redux-observable';
import {of,} from 'rxjs';
import {catchError, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators'

import actionTypes from '../Redux/Actions/ActionTypes';
import {getServiceUrls} from './ServiceUrls';
import {ajax} from 'rxjs/ajax';
import Cookies from 'js-cookie'
import Globals from '../Helpers/Globals';
import {getCartError, setCart} from '../Redux/Actions/PreviewDiscount';


export const getCartEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionTypes.PreviewDiscount.getCart),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            getCartCall(state, action)
        ),
    );

const getCartCall = (state, action) => {
    let params = '';
    params = Globals.addQueryParam(params, "CartUid", action.cartUid);
    params = Globals.addQueryParam(params, "ExternalId", action.externalId);
    params = Globals.addQueryParam(params, "SourceExternalId", action.sourceExternalId);


    return getServiceUrls(action.loginDomain).pipe(
        mergeMap((x) => ajax(x.LoyaltyEngineApiUrl + '/api/v4/' + action.sessionKey + '/Carts' + params)
            .pipe(
                map(response => processResponse(response, action)),
                catchError(error => of(getCartError(error)))
            )));
}

const processResponse = (response, action) => {
    if (response.status === 200) {
        const blResponse = response.response;
        if (blResponse.status === 'error') {
            if (blResponse.message.startsWith("Client not assigned to this cluster")) {
                Cookies.remove("serviceUrls");
                return action;
            }
            return getCartError(blResponse.message);
        }
        else {
            return setCart(blResponse.data);
        }
    } else {
        return getCartError(response.message);
    }
};
