import initialState from "../InitialState";
import actionTypes from "../Actions/ActionTypes";


export const resetPasswordReducer = (state = initialState.resetPassword, action) => {
    switch (action.type) {
        case actionTypes.resetPassword.resetPasswordRequestSuccess:
            return {
                ...state,
                status: action.status,
            };
        case actionTypes.resetPassword.resetPasswordRequestFailure:
            return {
                ...state,
                status: 'failure',
                error: action.error,
            };
            case actionTypes.resetPassword.resetError:
                    return {
                        ...state,
                        error: '',
                        status: '',
                    }
        default:
            return state;
    }
}