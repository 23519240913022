import {client} from "./ApiClient";
import {isNullOrUndefined, mapServiceUrls} from "../Helpers/Globals";
import {useQuery} from "react-query";
import {Settings} from "../Settings";

const getEditionDefinition = (key, accessKey, editionCode, editionUid) => {
    if (editionUid)
        return  client(Settings.DomainServerUrl, `api/v4/${accessKey}/Editions/${editionUid}`)
    else
        return client(Settings.DomainServerUrl, `api/v4/${accessKey}/Editions/Code/${editionCode}`)
}

export default function useEditionDefinition(accessKey, editionCode, editionUid) {
    const {status, data, error } = useQuery(['Edition', editionCode, editionUid], (key) => getEditionDefinition(key, accessKey, editionCode, editionUid), {enabled: !!accessKey && (!isNullOrUndefined(editionCode) || !isNullOrUndefined(editionUid)), staleTime: 1000*10})
    const editionDefinition = data ? data.data : undefined
    return {status, editionDefinition, error}
}