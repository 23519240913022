import React, {useMemo} from 'react'
import {MDBDataTable, MDBInput} from 'mdbreact'
import MaterialTable from "./MaterialTable";
import {v4 as uuid} from 'uuid'

export default function PreviewCartTable(props) {
    const cart = props.cart;
    const columns = useMemo(() => [
        {
            label: "Description",
            field: "description",
        },
        {
            label: "Quantity",
            field: "quantity",
        },
        {
            label: "Price",
            field: "price",
        },
        {
            label: "Sale Price",
            field: "salePrice",
        },
        {
            label: "Discount",
            field: "discount",
        },
        {
            label: "Extended Price",
            field: "extendedPrice",
        },
        {
            label: "Tax",
            field: "tax",
        }
    ], [])

    const lines = cart.Lines;
    let row;
    let extendedSubtotal = 0;
    let priceSubtotal = 0;
    let salePriceSubtotal = 0;
    let discountSubtotal = 0;
    let quantitySubtotal = 0;
    
    const rows = useMemo(() => {
        extendedSubtotal = 0;
        priceSubtotal = 0;
        salePriceSubtotal = 0;
        discountSubtotal = 0;
        quantitySubtotal = 0;
        let tempRows = []
        lines.forEach((line) => {
            let extended = line.SalePrice * line.Quantity - line.Discount * line.Quantity;
            row = {
                id: line.Uid,
                description: line.ProductName,
                quantity: line.Quantity,
                price: `$${line.Price.toFixed(2)}`,
                salePrice: `$${line.SalePrice.toFixed(2)}`,
                discount: `${line.Discount > 0 ? '-' : ''}$${line.Discount.toFixed(2)}`,
                extendedPrice: `$${extended.toFixed(2)}`,
                tax: <MDBInput label=" " type="checkbox" id="last" checked={line.TaxExempt ? false : true} />,
            }
            extendedSubtotal += extended;
            priceSubtotal += line.Price * line.Quantity;
            salePriceSubtotal += line.SalePrice * line.Quantity;
            discountSubtotal += line.Discount * line.Quantity;
            quantitySubtotal += line.Quantity;
            tempRows.push(row);
        })

        tempRows.push(
            {
                id: uuid(),
                description: "Subtotal",
                quantity: quantitySubtotal,
                price: `$${priceSubtotal.toFixed(2)}`,
                salePrice: `$${salePriceSubtotal.toFixed(2)}`,
                discount: `${discountSubtotal > 0 ? '-' : ''}$${discountSubtotal.toFixed(2)}`,
                extendedPrice: `$${extendedSubtotal.toFixed(2)}`,
                tax: '',
            }
        )
        const total = extendedSubtotal - cart.Discount;
        tempRows.push(
            {
                id: uuid(),
                description: '',
                quantity: '',
                price: '',
                salePrice: '',
                discount: 'Order Discount',
                extendedPrice: `${cart.Discount > 0 ? '-' : ''}$${cart.Discount.toFixed(2)}`,
                tax: '',
            }
        )
        tempRows.push(
            {
                id: uuid(),
                description: '',
                quantity: '',
                price: '',
                salePrice: '',
                discount: `Order Total`,
                extendedPrice: `$${total.toFixed(2)}`,
                tax: '',
            }
        )
        
        return tempRows
    }, [cart.Lines])

    return (
        <MaterialTable columns={columns} rows={rows}/>
    )
}
