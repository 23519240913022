import React, {Fragment, useEffect, useState} from 'react'
import { MDBCard, MDBCardHeader, MDBCardBody, MDBCardText } from 'mdbreact'

const getLoyaltyMessage = (summary) => {
    const loyaltyMessage = []
    summary.LoyaltyPointsAccruals.forEach(accrual => {
        if (accrual.Points > 0) {
            loyaltyMessage.push(`Will accrue ${accrual.Points} ${accrual.AccountClassLabel}`)
        }
    })
    if (summary.LoyaltyPointsUsed !== 0)
        loyaltyMessage.push("Will use " + (-summary.LoyaltyPointsUsed));

    summary.LoyaltyCurrencyAccruals.forEach(accrual => {
        if (accrual.Amount > 0) {
            loyaltyMessage.push(`Will accrue ${accrual.Amount.toFixed(2)} ${accrual.AccountClassLabel}`)
        }
    })

    summary.AwardedCoupons.forEach((coupon) => {
        loyaltyMessage.push("Will accrue coupon " + (coupon.DisplayName ? coupon.DisplayName : coupon.Name) + (coupon.Quantity === 1 ? "" : " Quantity: " + coupon.Quantity));
    })

    if (loyaltyMessage.length === 0)
        loyaltyMessage.push("No loyalty accrual awards available");

    return loyaltyMessage
} 

export default function CartLoyaltySummary(props) {
    const [loyaltyMessage, setLoyaltyMessage] = useState(getLoyaltyMessage(props.loyalty))

    useEffect(() => {
        let strings = loyaltyMessage.map(mapLines)
        const result = strings.join("<br >")
        const element = document.getElementById('loyalty-card')
        if (element)
            element.innerHTML = result
    }, [loyaltyMessage])
    
    useEffect(() => {
        if (props.loyalty)
            setLoyaltyMessage(getLoyaltyMessage(props.loyalty))
    }, [props.loyalty])
    
    const mapLines = (line, index) => {
        return line
    }

    return (
        <Fragment>
            <MDBCard className="cart-summary-card">
                <MDBCardHeader className="cart-summary-card-header">Loyalty Accrual</MDBCardHeader>
                <MDBCardBody style={{ textAlign: "left" }}>
                    <MDBCardText style={{ color: "#393939" }}>
                        <span id={'loyalty-card'} />
                    </MDBCardText>
                </MDBCardBody>
            </MDBCard>
        </Fragment>
    )
}
