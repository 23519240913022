import React, {Fragment, useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {MDBRow, MDBBtn, MDBCol, MDBInput, MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption} from 'mdbreact';

import { updateValue, validateLoginDomain } from '../../Redux/Actions/CreateAccount';
import { countries, states, provinces } from '../../Helpers/StatesAndCountries'
import Header from '../Header';
import LoadingSpinner from '../LoadingSpinner';
import {Button} from "@mui/material";

export default function FormCompanyDetails(props) {
    const companyName = useSelector(state => state.createAccount.companyName);
    const address1 = useSelector(state => state.createAccount.address1);
    const address2 = useSelector(state => state.createAccount.address2);
    const city = useSelector(state => state.createAccount.city);
    const state = useSelector(state => state.createAccount.state);
    const country = useSelector(state => state.createAccount.country);
    const postal = useSelector(state => state.createAccount.postal);

    const dispatch = useDispatch();


    const handleChange = (event) => {
        dispatch(updateValue(event.target.name, event.target.value))
        event.preventDefault();
    }

    const handleCountryChange = (event) => {
        dispatch(updateValue('country', event[0]))
    }

    const handleStateChange = (event) => {
        dispatch(updateValue('state', event[0]))
    }

    const submit = (e) => {
        e.preventDefault();
        props.nextStep();
    }

    const prevStep = e => {
        e.preventDefault();
        props.prevStep();
    }
    
    const mapCountriesToOptions = (countryOption, index) => {
        return <MDBSelectOption key={index} value={countryOption.value} checked={country === countryOption.value}>{countryOption.text}</MDBSelectOption>
    }

    return (
        <Fragment>
            <Header title="Company Details" step={1} totalSteps={5}/>
            <div className="form">
                <MDBRow className="form">
                    <MDBCol md="12">
                        <form onSubmit={submit}>
                            <div className="grey-text">
                                <MDBInput
                                    autoFocus
                                    id="companyName"
                                    name="companyName"
                                    label="Company Name"
                                    group
                                    type="text"
                                    required
                                    valueDefault={companyName}
                                    onChange={handleChange}
                                />
                                <MDBInput
                                    id="address1"
                                    name="address1"
                                    label="Address"
                                    group
                                    type="text"
                                    required
                                    valueDefault={address1}
                                    onChange={handleChange}
                                />
                                <MDBInput
                                    id="address2"
                                    name="address2"
                                    label="Apt, suite, or floor"
                                    group
                                    type="text"
                                    valueDefault={address2}
                                    onChange={handleChange}
                                />
                                <MDBInput
                                    id="city"
                                    name="city"
                                    label="Enter your city"
                                    group
                                    type="text"
                                    required
                                    valueDefault={city}
                                    onChange={handleChange}
                                />
                                <MDBSelect getValue={handleCountryChange} required label="Country">
                                    <MDBSelectInput selected={country} />
                                    <MDBSelectOptions>
                                        {countries.map(mapCountriesToOptions)}
                                    </MDBSelectOptions>
                                </MDBSelect>

                                {country === "US" &&

                                    <MDBSelect options={states}
                                        selected={state}
                                        label="State"
                                        required
                                        getValue={handleStateChange}
                                        defaultValue={state}
                                    />
                                }

                                {country === "CA" &&
                                    <MDBSelect
                                        selected={state}
                                        options={provinces}
                                        label="Province"
                                        required
                                        getValue={handleStateChange}
                                        defaultValue={state}
                                    />
                                }

                                {(country !== "US" && country !== "CA" && country !== "") &&
                                    <MDBInput
                                        id="state"
                                        name="state"
                                        label="State, Province, or Territory"
                                        group
                                        type="text"
                                        required
                                        valueDefault={state}
                                        onChange={handleChange}
                                    />
                                }

                                <MDBInput
                                    id="postal"
                                    name="postal"
                                    label="Postal Code"
                                    group
                                    type="text"
                                    required
                                    valueDefault={postal}
                                    onChange={handleChange}
                                />

                            </div>
                            <MDBRow className="btn-row">
                                <MDBCol xs="12" sm="6">
                                    <Button className="back-btn btn-pair" id="back" name="back" onClick={prevStep} >Back</Button>
                                </MDBCol>
                                <MDBCol xs="12" sm="6">
                                    <Button id="continue" className='next-btn' type="submit" >Continue</Button>
                                </MDBCol>
                            </MDBRow>
                        </form>
                    </MDBCol>
                </MDBRow>
            </div>
        </Fragment>
    )
}
