import React, {useState, Fragment, useEffect, useMemo} from 'react';
import {useSelector} from "react-redux";
import useServiceProviderConfigurationDefinition from "../../Utils/UseServiceProviderConfigurationDefinition";
import useServiceUrls from "../../Utils/UseServiceUrls";
import SelectInput from "../Inputs/SelectInput";
import TextInput from "../Inputs/TextInput";
import NumberInput from "../Inputs/NumberInput";
import {Redirect, useHistory} from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner";
import useProvisionService from "../../Utils/UseProvisionService";
import {MDBBtn} from "mdbreact";
import Header from "../Header";
import queryString from "query-string";
import useServiceUrlsFromAccessKey from "../../Utils/UseServiceUrlsFromAccessKey";
import useAccessKey from "../../Utils/UseAccessKey";
import useClientServiceProviders from "../../Utils/UseClientServiceProviders";
import useActivateService from "../../Utils/UseActivateService";
import TextInputWithHelpText from "../Inputs/TextInputWithHelpText";
import NumberInputWithHelpText from "../Inputs/NumberInputWithHelpText";
import SelectInputWithHelpText from "../Inputs/SelectInputWithHelpText";
import useServiceProviderConfiguration from "../../Utils/UseServiceProviderConfiguration";
import {Button} from "@mui/material";
import CheckboxInput from "../Inputs/CheckboxInput";
import useClientApplications from "../../Utils/UseClientApplications";

export default function ProvisioningPage(props) {
    const [state, setState] = useState({})
    const sessionKey = useSelector(state => state.login.key);
    const domain = useSelector(state => state.session.domain);
    const provisionSessionUid = useSelector(state => state.session.ProvisionSession.Uid);
    const fromExternal = domain === '' || domain === undefined
    const useStagingUrl = useSelector(state => state.session.ProvisionSession.UseStagingServiceUrl);
    const sessionDeviceCode = useSelector(state => state.session.ProvisionSession.DeviceCode);
    const sessionStoreCode = useSelector(state => state.session.ProvisionSession.StoreCode);
    const sessionOrgUnitCode = useSelector(state => state.session.ProvisionSession.OrgUnitCode);
    const [usageId, setUsageId] = useState('')
    const [activating, setActivating] = useState(false)
    const { serviceUrlsStatus, serviceUrls, serviceUrlsError } = useServiceUrls(domain);
    const { serviceUrlsStatus: urlsStatus, serviceUrls: urls, serviceUrlsError: urlsError } = useServiceUrlsFromAccessKey(usageId);
    const {status: accessKeyStatus, accessKey, error: accessKeyError } = useAccessKey(urls, usageId)
    const {status, providerConfig, error} = useServiceProviderConfigurationDefinition(fromExternal ? accessKey : sessionKey, props.match.params.providerUid, fromExternal ? urls : serviceUrls)
    const {serviceProviders, status: serviceProvidersStatus, error: serviceProviderErrors} = useClientServiceProviders(fromExternal ? accessKey : sessionKey)
    const {applications, status: appsStatus, error: appsErrors} = useClientApplications(fromExternal ? accessKey : sessionKey)
    const [clientServiceUid, setClientServiceUid] = useState('')
    const [serviceIntegrationUid, setServiceIntegrationUid] = useState(props.match.params.serviceIntegrationUid);
    const [done, setDone] = useState(false)
    const provider = useMemo(() => serviceProviders?.find((sp) => sp.ServiceUid === props.match.params.providerUid) ?? applications?.find((a) => a.ServiceUid === props.match.params.providerUid), [serviceProviders, applications, props.match.params.providerUid])
    const {status: providerConfigStatus, providerConfiguration, error: providerConfigError} = useServiceProviderConfiguration(urls, fromExternal ? accessKey : sessionKey, serviceIntegrationUid)

    const [deviceCode, setDeviceCode] = useState('');
    const [storeCode, setStoreCode] = useState('');
    const [orgUnitCode, setOrgUnitCode] = useState('');
    const [logout, setLogout] = useState(false)
    const [back, setBack] = useState(false)
    const [returnUrl, setReturnUrl] = useState('')
    const [allowErrorDisplay, setAllowErrorDisplay] = useState(false)
    
    const activateService = useActivateService()
    const provisionService = useProvisionService()
    
    useEffect(() => {
        if (providerConfiguration)
            setState({
                ...state,
                ...providerConfiguration.CustomSettings
            })
    }, [providerConfiguration])
    
    useEffect(() => {
        const lower = window.location.search.toLowerCase();
        const parsed = queryString.parse(lower);
        setUsageId(parsed.usageid)
        setReturnUrl(parsed.bl_rurl)
        if (sessionDeviceCode)
            setDeviceCode(sessionDeviceCode)
        else
            setDeviceCode(parsed.bl_device ?? null)
        if (sessionStoreCode)
            setStoreCode(sessionStoreCode)
        else
            setStoreCode(parsed.bl_store ?? null)
        if (sessionOrgUnitCode)
            setOrgUnitCode(sessionOrgUnitCode)
        else
            setOrgUnitCode(parsed.bl_orgunit ?? null)
    }, [])
    
    useEffect(() => {
        if (provisionService.status === 'success' && !fromExternal) {
            console.log('cancel')
            cancel()
        }
        else if (provisionService.status === 'success' && fromExternal) {
            if (returnUrl)
                setTimeout(() => window.location.assign(returnUrl), 2000)
            else 
                setDone(true)
        }
    }, [provisionService.status])
    
    useEffect(() => {
        if (status === 'success' && provider && !providerConfig)
            provision();
        else
            setTimeout(() => setAllowErrorDisplay(true), 1000)
    }, [status, provider])
    
    useEffect(() => {
        if (activateService.status === 'success') {
            setClientServiceUid(activateService.data.EntityUid)
        }
    }, [activateService.status, activateService.data])
    
    useEffect(() => {
        if (!provider && fromExternal && serviceProvidersStatus === 'success') {
            setActivating(true)
            activateService.mutate({
                accessKey, service: {
                    ClientUid: '',
                    ServiceUid: props.match.params.providerUid
                }
            })
        }
    }, [provider, fromExternal, serviceProvidersStatus])

    useEffect(() => {
        if ((!domain || !sessionKey) && !fromExternal && !usageId)
            setLogout(true)
    })

    if (logout)
        return <Redirect push to={'/login' + props.location.search}/>
    
    const handleCustomFieldChange = (field, value) => {
        const newState = {
            ...state,
            [field]: value
        }
        setState(newState)
    }

    const mapCustomField = (field, index) => {
        const mapListOptions = (options) => {
            return options.map((option) => {
                return {
                    text: option.Name,
                    value: option.Value,
                }
            })
        }

        let value = state[field.Code] ?? field.Default
        switch (field.FieldType) {

            case "Int":
            case 'Decimal':
                return (
                    <Fragment key={index}>
                        {field.HelpText &&
                            <NumberInputWithHelpText id={field.Code} value={value ?? ''} label={field.Name + (field.Required ? '*' : '')}
                                         handleChange={handleCustomFieldChange} required={field.Required} tooltip={field.HelpText}/>
                        }
                        {!field.HelpText &&
                            <NumberInput id={field.Code} value={value ?? ''} label={field.Name + (field.Required ? '*' : '')}
                                     handleChange={handleCustomFieldChange} required={field.Required}/>
                        }
                        <br/>
                    </Fragment>
                )
            case 'ValidatedList':
                return (
                    <Fragment key={index}>
                        {field.HelpText &&
                            <SelectInputWithHelpText options={mapListOptions(field.ValidValues)} label={field.Name + (field.Required ? '*' : '')} value={value ?? ''} fieldName={field.Code} handleChange={handleCustomFieldChange} disabled={false} required={field.Required} tooltip={field.HelpText}/>
                        }
                        {!field.HelpText &&
                        <SelectInputWithHelpText options={mapListOptions(field.ValidValues)} label={field.Name + (field.Required ? '*' : '')} value={value ?? ''} fieldName={field.Code} handleChange={handleCustomFieldChange} disabled={false} required={field.Required}/>
                        }
                    </Fragment>
                )
            case 'Bool':
                return (
                    <Fragment key={index}>
                        <CheckboxInput label={field.Name} value={value ?? false} id={field.Code} />
                    </Fragment>
                )
            case 'Money':
            case 'Date':
            case 'Unknown':
            case 'Time':
            case 'DateTime':
            case 'Byte':
            case 'Blob':
            case 'List':
            case 'MultiSelectValidatedList':
            case 'String':
            default:

                return (
                    <Fragment key={index}>

                        {field.HelpText &&
                            <TextInputWithHelpText id={field.Code} value={value ?? ''} label={field.Name}
                                                   handleChange={handleCustomFieldChange} required={field.Required}
                                                   tooltip={field.HelpText}/>
                        }
                        {!field.HelpText &&
                            <TextInput id={field.Code} value={value ?? ''} label={field.Name}
                                                   handleChange={handleCustomFieldChange} required={field.Required}/>
                        }
                        <br/>
                    </Fragment>
                )
        }
    }
    
    const cancel = (e) => {
        if (e)
            e.preventDefault();
        setBack(true)
    }
    
    const provision = e => {
        if (e)
            e.preventDefault()
        
        provisionService.mutate({provisionSessionUid: provisionSessionUid, accessKey: fromExternal ? accessKey : sessionKey, serviceUrls: fromExternal ? urls : serviceUrls, clientServiceUid: provider?.ClientServiceUid ?? clientServiceUid, provisionServiceWebhookUrl: providerConfig?.ProvisionServiceWebhookUrl, useStagingUrl, configuration: state, deviceCode, storeCode, orgUnitCode, serviceIntegrationUid})
    }
    
    if (back) {
        console.log('back')
        return <Redirect to={`/devicesetup${window.location.search}&toNext=true`}/>
    }

    return (
        <>
            <Header title={`Provision Service ${provider ? ' - ' + provider.ServiceName : ''}`}/>
            {activateService.status === 'loading' &&
                <div>
                    <p>Activating Service</p>
                </div>
            }
            {provisionService.status === 'loading' && !providerConfig &&
                <div>
                    <p>Provisioning Service</p>
                </div>
            }
            {(status === 'loading' || serviceUrlsStatus === 'loading' || urlsStatus === 'loading' || accessKeyStatus === 'loading' || serviceProvidersStatus === 'loading' || (activating && activateService.status === 'loading')) && <LoadingSpinner />}
            {status === 'success' && (!activating || activating && activateService.status === 'success') &&
                <>
                    {!providerConfig && provisionService.status !== 'loading' && provisionService.status !== 'success' && allowErrorDisplay &&
                        <div>
                            <Header title={`Provision Service`}/>
                            <p>{`Unable to provision service (error context: ${props.match.params.providerUid}).  Please contact bLoyal Support to complete your provisioning request atsupport@bloyal.com
                                or 877-388-7638.`}</p>
                        </div>
                    }
                    {provisionService.status === 'success' && fromExternal && returnUrl && <p>Service Provisioned</p>}
                    <div>
                        
                        {!done && providerConfig &&
                            <>
                                <p>{providerConfig?.ConfigurationHelpText}</p>
                                <form onSubmit={provision} >
                                    {providerConfig?.ConfigurationFields.map(mapCustomField)}
                                    <br />
                                    {!fromExternal && <Button className="back-btn btn-pair" id="back" name="back" onClick={cancel} >Cancel</Button>}
                                    <Button className="btn-pair next-btn" id="confirm" type="submit" >Provision</Button>
                                </form>
                            </>
                        }
                        {done &&
                            <>
                                <h5>Provisioning complete, please close this window to return to bLoyal Director.</h5>
                            </>
                        }
                        {provisionService.status === 'loading' && <LoadingSpinner />}
                        {provisionService.error && <p className='error-text'>{provisionService.error}</p>}
                    </div>
                    </>
            }
            {status === 'error' && <p className='error-text'>{error}</p>}
        </>
    )
}