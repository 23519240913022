import React, { Fragment, useState } from 'react'
import Header from '../Header'
import Jumbotron from 'react-bootstrap/Jumbotron'
import { MDBBtn } from 'mdbreact'
import { Redirect } from 'react-router-dom'

export default function AccountCreationComplete(props) {
    return (
        <Redirect to={"/login" + window.location.search} />
    )
}
