import { ofType, } from 'redux-observable';
import { of, } from 'rxjs';
import { map, catchError, switchMap, mergeMap, withLatestFrom, tap } from 'rxjs/operators'


import actionTypes from '../Redux/Actions/ActionTypes';
import { getServiceUrls } from './ServiceUrls';
import { checkProvisionStatus, checkProvisionStatusSuccess, checkProvisionStatusFailure, provisionDeviceRequestFailure, provisionDeviceRequestSuccess, provisionDeviceRequest } from '../Redux/Actions/ProvisionDevice';
import { ajax } from 'rxjs/ajax';
import Cookies from 'js-cookie'


export const checkDeviceProvisionStatusEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionTypes.provisionDevice.checkProvisionStatus),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            checkDeviceProvisionStatusCall(action, state)
        ),
    );

const checkDeviceProvisionStatusCall = (action, state) => {
    const domain = state.session.domain;
    const connectorKey = state.session.connectorKey;
    const accessKey = state.login.key;
    if (!accessKey)
        return of({type: 'null'});
    return getServiceUrls(domain).pipe(
        mergeMap((x) => ajax(x.GridApiUrl + '/api/v4/' + accessKey + '/ServiceProvisioning/Devices?connectorUid=' + connectorKey + '&storeCode=' + action.storeCode + '&deviceCode=' + action.deviceCode)
            .pipe(
                map(response => processCheckProvisionStatusResponse(response, action)),
                catchError(error => of(checkProvisionStatusFailure(error)))
            )));
}

const processCheckProvisionStatusResponse = (response, action) => {
    if (response.status === 200) {
        const blResponse = response.response;
        if (blResponse.status === 'error') {
            if (blResponse.message.startsWith("Client not assigned to this cluster")) {
                Cookies.remove("serviceUrls");
                return checkProvisionStatus(action.storeCode, action.deviceCode);
            }
            return checkProvisionStatusFailure(blResponse.message);
        }
        else {
            return checkProvisionStatusSuccess(blResponse);
        }
    } else {
        return checkProvisionStatusFailure(response.message);
    }
}

export const provisionDeviceEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionTypes.provisionDevice.provisionDeviceRequest),
        withLatestFrom(state$),
        switchMap(([action, state]) => 
            provisionDeviceCall(action, state)
        ),
    );


const provisionDeviceCall = (action, state) => {
    const connectorUid = state.session.ProvisionSession.ConnectorUid;
    const accessKey = state.login.key;

    if (!accessKey)
        return of({type: 'null'});
    
    let body = {
        ConnectorUid: connectorUid,
        DeviceCode: action.deviceCode,
        StoreCode: action.storeCode,
        DeviceName: action.deviceName,
        ProvisionSessionUid: state.session.provisionUid,
    }
    
    return getServiceUrls(state.session.domain).pipe(
        mergeMap((x) => ajax.post(x.GridApiUrl + '/api/v4/' + accessKey + '/ServiceProvisioning/Devices', body)
        .pipe(
            map(response => processProvisionDeviceRequest(response, action)),
            catchError(error => of(provisionDeviceRequestFailure(error)))
        )));
}

const processProvisionDeviceRequest = (response, action) => {
    if (response.status === 200) {
        const blResponse = response.response;
        if (blResponse.status === 'error') {
            if (blResponse.message.startsWith("Client not assigned to this cluster")) {
                Cookies.remove("serviceUrls");
                return provisionDeviceRequest(action.deviceName, action.deviceCode, action.storeCode);
            }
            return provisionDeviceRequestFailure(blResponse.message);
        }
        else {
            return provisionDeviceRequestSuccess(blResponse);
        }
    } else {
        return provisionDeviceRequestFailure(response.message);
    }
}