import {useMutation, useQueryClient} from "react-query";
import {client} from "./ApiClient";
import {Settings} from "../Settings";

const activateService = ({accessKey, service}) => {
    return client(Settings.DomainServerUrl, `api/v4/${accessKey}/ServiceActivations/Services`, {
        body: {
            ...service,
            // ClientUid: "00000000-0000-0000-0000-000000000000"
        }
    })
}

export default function useActivateService() {
    const queryClient = useQueryClient();
    
    return useMutation(activateService, {
        onSuccess: () => {
            queryClient.invalidateQueries('ServiceProviders')
            queryClient.invalidateQueries('Applications')
        },
        retry: 1
    })
}