import {client} from "./ApiClient";
import {isNullOrUndefined, mapServiceUrls} from "../Helpers/Globals";
import {useQuery} from "react-query";
import {Settings} from "../Settings";

const getUsageId = (key, serviceUrls, sessionKey) => {
    let url = mapServiceUrls(serviceUrls).DirectorApiUrl.toLowerCase();
    if (url.includes('api')) {
        url = url.replace('api', 'model')
        url = url.replace('com', 'io')
    }
    return client(url, `api/v4/UsageIds/SessionKey/${sessionKey}`)
}

export default function useUsageId(serviceUrls, sessionKey) {
    const {status, data, error } = useQuery(['UsageId', sessionKey], (key) => getUsageId(key, serviceUrls, sessionKey), {enabled: !!sessionKey && !!serviceUrls, staleTime: 1000*10})
    const usageId = data ? data.data.UsageId : undefined
    return {status, usageId, error}
}