import { ofType, } from 'redux-observable';
import { of, } from 'rxjs';
import { map, catchError, switchMap, mergeMap, withLatestFrom, tap } from 'rxjs/operators'

import actionTypes from '../Redux/Actions/ActionTypes';
import { getServiceUrls } from './ServiceUrls';
import { checkProvisionStatusSuccess, checkProvisionStatusFailure, provisionStoreRequestFailure, provisionStoreRequestSuccess, checkProvisionStatus, provisionStoreRequest, } from '../Redux/Actions/ProvisionStore';
import { ajax } from 'rxjs/ajax';
import { setBackOfficeReady } from '../Redux/Actions/BackOfficeConnector';
import Cookies from 'js-cookie'


export const checkStoreProvisionStatusEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionTypes.provisionStore.checkProvisionStatus),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            checkProvisionStatusCall(state, action)
        ),
    );

const checkProvisionStatusCall = (state, action) => {
    const domain = state.session.domain;
    const connectorKey = state.session.connectorKey;
    const accessKey = state.login.key;
    
    if (!accessKey)
        return of({type: 'null'});

    return getServiceUrls(domain).pipe(
        mergeMap((x) => ajax(x.GridApiUrl + '/api/v4/' + accessKey + '/ServiceProvisioning/Stores?connectorUid=' + connectorKey + '&storeCode=' + action.code)
            .pipe(
                map(response => processResponse(response, action)),
                catchError(error => of(checkProvisionStatusFailure(error)))
            )));
}

const processResponse = (response, action) => {
    if (response.status === 200) {
        const blResponse = response.response;
        if (blResponse.status === 'error') {
            if (blResponse.message.startsWith("Client not assigned to this cluster")) {
                Cookies.remove("serviceUrls");
                return checkProvisionStatus(action.code);
            }
            return checkProvisionStatusFailure(blResponse.message);
        }
        else {
            return checkProvisionStatusSuccess(blResponse);
        }
    } else {
        return checkProvisionStatusFailure(response.message);
    }
};

export const provisionStoreEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionTypes.provisionStore.provisionStoreRequest),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            provisionStoreCall(action, state)
        ),
    );

const provisionStoreCall = (action, state) => {
    const connectorUid = state.session.ProvisionSession.ConnectorUid;
    let accessKey = state.login.key;
    if (!accessKey)
        return of({type: 'null'});
    const provisionSessionUid = state.session.provisionUid;
    const body = {
        ProvisionSessionUid: provisionSessionUid,
        ConnectorUid: connectorUid,
        StoreCode: action.code,
        Address1: action.address1,
        Address2: action.address2,
        City: action.city,
        State: action.state,
        Country: action.country,
        PostalCode: action.postal,
        StoreName: action.name,
        Email: action.email,
        Phone: action.phone,
    };

    return getServiceUrls(state.session.domain).pipe(
        mergeMap((x) => ajax.post(x.GridApiUrl + '/api/v4/' + accessKey + '/ServiceProvisioning/stores', body)
            .pipe(
                tap(x => console.log(x)),
                map(response => processProvisionStoreRequest(response, action)),
                catchError(error => of(provisionStoreRequestFailure(error)))
            )));
}

const processProvisionStoreRequest = (response, action) => {
    if (response.status === 200) {
        const blResponse = response.response;
        if (blResponse.status === 'error') {
            if (blResponse.message.startsWith("Client not assigned to this cluster")) {
                Cookies.remove("serviceUrls");
                return provisionStoreRequest(action.name, action.code, action.address1, action.address2, action.city, action.state, action.country, action.postal, action.email, action.phone);
            }
            return provisionStoreRequestFailure(blResponse.message);
        }
        else {
            return provisionStoreRequestSuccess(blResponse);
        }
    } else {
        return provisionStoreRequestFailure(response.message);
    }
}

export const storeProvisionedEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionTypes.provisionStore.provisionStoreRequestSuccess, actionTypes.provisionStore.checkProvisionStatusSuccess),
        withLatestFrom(state$),
        switchMap(([action,]) =>
            setBackOfficeStatus(action)
        ),
    );


const setBackOfficeStatus = (action) => {
    if ((action.type === actionTypes.provisionStore.checkProvisionStatusSuccess) && (action.data.data.IsProvisioned === false))
        return of({type: ""});
    
    return of(setBackOfficeReady());
}