import React from 'react';
import {Grid} from "@mui/material";
import {Button} from "@material-ui/core";


export default function CustomerFacingCouponSection(props) {

    const getCouponMessage = (coupon) => {
        let string = "";
        string += coupon.Redeemed ? "Applied: " : "Not Applied: ";
        string += coupon.DisplayName ? coupon.DisplayName : coupon.Name;
        string += coupon.Message ? " - " + coupon.Message : "";

        return string
    }
    
    const mapCoupon = (coupon) => {
        const isApplied = props.appliedCoupons.some(ac => ac.Code === coupon.Code)
        return <Grid key={coupon.Uid} item xs={12} sm={6} >
            <div className={'bl-coupon center'}>
                <p className='bl-coupon-name center'>{coupon.Name}</p>
                {isApplied && <p className={'center'}>Coupon added</p>}
                {/*{coupon.QuantityAvailable !== null &&*/}
                {/*    <>*/}
                {/*        <br />*/}
                {/*        <p>Quantity Available: {coupon.QuantityAvailable}</p>*/}
                {/*    </>*/}
                {/*}*/}
                {!isApplied && <Button onClick={() => props.applyCoupon(coupon)} style={{marginTop: '15px'}} variant='contained' color={'primary'}>Apply</Button>}
            </div>
        </Grid>
    }

    return (
        <Grid container spacing={4} className={'bl-coupon-container center'}>
            {props.coupons?.map(mapCoupon)}
            {props.coupons.length === 0 && <p className={'center'}>No coupons available.</p>}
        </Grid>
    )
}