import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "bootstrap-css-only/css/bootstrap.min.css";
import {MDBContainer} from 'mdbreact';
import {useDispatch, useSelector} from 'react-redux';
import {resetSessionError} from '../Redux/Actions/Session';
import {statuses} from '../Helpers/Status';
import {resetCreateAccountError} from '../Redux/Actions/CreateAccount';
import {resetProvisionStoreError} from '../Redux/Actions/ProvisionStore';
import {resetProvisionDeviceError} from '../Redux/Actions/ProvisionDevice';
import {AccountCreationProvider} from "../Utils/Contexts/AccountCreationContext";

export default function Layout(props) {
    const dispatch = useDispatch();
    const sessionError = useSelector(state => state.session.error);
    const sessionStatus = useSelector(state => state.session.status);
    const provisionDeviceError = useSelector(state => state.provisionDevice.error);
    const provisionDeviceStatus = useSelector(state => state.provisionDevice.status);
    const provisionStoreError = useSelector(state => state.provisionStore.error);
    const provisionStoreStatus = useSelector(state => state.provisionStore.status);

    if (sessionStatus === statuses.error) {
        window.alert('Session Error: ' + sessionError + '.');
        dispatch(resetSessionError());
    }
    if (provisionDeviceStatus === statuses.error) {
        window.alert('Provision Device Error: ' + provisionDeviceError + '.');
        dispatch(resetProvisionDeviceError());
    }
    if (provisionStoreStatus === statuses.error) {
        window.alert('Provision Store Error: ' + provisionStoreError + '.');
        dispatch(resetProvisionStoreError());
    }

    return (
        <MDBContainer className="font">
                {props.children}
        </MDBContainer>
    )
}