import React from 'react';
import {AppBar, Grid, Toolbar, Typography} from "@mui/material";
import PhoneInput from "../Inputs/PhoneInput";
import NumberPad from "./NumberPad";
import {Button} from "@material-ui/core";

export default function CustomPhoneInput(props) {
    
    const onChange = (char) => {
        props.onChange(props.value.concat(char))
    }
    
    const onBackspace = () => {
        props.onChange(props.value.slice(0, -1))
    }
    
    const onSave = () => {
        console.log('onSave')
        props.login()
    }
    
    return (
        <>
        <AppBar style={{ background: '#ececec', color: 'black', height: '64px' }} position={'sticky'}>
            <Toolbar style={{width: '100%'}} sx={{ justifyContent: "space-between" }}>
                <PhoneInput value={props.value} label={'Phone Number'} id={'CustomerPhoneNumber'}/>
                <Button onClick={onSave} style={{marginTop: '10px'}} variant='contained' color={'primary'}>Done</Button>
            </Toolbar>
        </AppBar>
            <Grid container style={{marginTop: '15px'}}>
                <Grid item container sm={7} xs={12}>
                    <NumberPad onChange={onChange} backspace={onBackspace} onSave={onSave} value={props.value}/>
                </Grid>
                <Grid item sm={5} xs={12} className={'center'} style={{paddingTop: '20px'}}>
                    <p className={'center'} style={{width: '90%'}}>Enter your phone number to earn great rewards and see the unique offers we have for you today!</p>
                </Grid>
            </Grid>
        </>
    )
}