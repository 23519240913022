import React, {useEffect, useState} from 'react';
import useServiceUrls from "../../Utils/UseServiceUrls";
import useDeviceProfile from "../../Utils/UseDeviceProfile";
import LoadingSpinner from "../LoadingSpinner";
import {formatDate, getQueryString, mapServiceUrls, onSnippetComplete} from "../../Helpers/Globals";
import {Button} from "@material-ui/core";
import useSubscriberGroups from "../../Utils/UseSubscriberGroups";
import Header from "../Header";
import useCustomer from "../../Utils/UseCustomer";
import useGetCart from "../../Utils/UseGetCart";
import {mapSignupField} from "../QuickSignUp/QuickSignup";
import FindCustomerModal from "../FindCustomer/FindCustomerModal";
import useSaveCustomer from "../../Utils/UseSaveCustomer";
import useJoinGroup from "../../Utils/UseJoinGroup";
import useLeaveGroup from "../../Utils/UseLeaveGroup";


export default function QuickEdit(props) {
    const [sessionKey, setSessionKey] = useState('');
    const [loginDomain, setLoginDomain] = useState('');
    const [cartUid, setCartUid] = useState('');
    const [cartExternalId, setCartExternalId] = useState('');
    const [cartSourceExternalId, setCartSourceExternalId] = useState('');
    const [returnUrl, setReturnUrl] = useState('')
    const [customerUid, setCustomerUid] = useState('')
    const { serviceUrlsStatus, serviceUrls, serviceUrlsError } = useServiceUrls(loginDomain);
    const {status, deviceProfile, error} = useDeviceProfile(mapServiceUrls(serviceUrls), sessionKey);
    const {status: groupsStatus, subscriberGroups, error: groupsError} = useSubscriberGroups(serviceUrls, sessionKey)
    const { status: cartStatus, cartResponse, error: cartError, refetch } = useGetCart(serviceUrls, sessionKey, cartUid, cartExternalId, cartSourceExternalId);
    const {status: customerStatus, customer, error: customerError} = useCustomer(serviceUrls, sessionKey, customerUid)
    const [selectedGroups, setSelectedGroups] = useState([])
    const [signupState, setSignupState] = useState({})
    const [signupComplete, setSignupComplete] = useState(false)
    const saveCustomer = useSaveCustomer()
    const joinGroup = useJoinGroup()
    const leaveGroup = useLeaveGroup()
    
    const [noCustomer, setNoCustomer] = useState(false)
    const [joinComplete, setJoinComplete] = useState(false)
    const [leaveComplete, setLeaveComplete] = useState(false)

    useEffect(() => {
        setSessionKey(getQueryString(window.location.href, 'bl_sk') ?? getQueryString(window.location.href,'SessionKey') ?? '');
        setLoginDomain(getQueryString(window.location.href, 'bl_LoginDomain') ?? getQueryString(window.location.href,'LoginDomain') ?? '');
        setReturnUrl(getQueryString(window.location.href, 'bl_rurl') ?? getQueryString(window.location.href,'ReturnUrl') ?? '')
        setCartUid(getQueryString(window.location.href, 'bL_CartUid') ?? getQueryString(window.location.href,'CartUid') ?? '');
        setCartExternalId(getQueryString(window.location.href, 'bl_CartExternalId') ?? getQueryString(window.location.href,'cartExternalId') ?? '')
        setCartSourceExternalId(getQueryString(window.location.href, 'bl_CartSourceExternalId') ?? getQueryString(window.location.href,'CartSourceExternalId') ?? '')
    }, [window.location.href])
    
    useEffect(() => {
        if (cartResponse === undefined)
            return
        if (cartResponse.Cart.Customer)
            setCustomerUid(cartResponse.Cart.Customer?.Uid)
        else
            setNoCustomer(true)
    }, [cartResponse])
    
    useEffect(() => {
        if (customerStatus === 'success') {
            if (!customer)
                setNoCustomer(true)
            else
                setSignupState({...customer, BirthDate: formatDate(customer.BirthDate), ...customer.Address})
        }
    }, [customerStatus])
    
    const joinGroups = (groups) => {
        if (groups.length === 0)
            setJoinComplete(true)
        
         groups.forEach(async (group, index) => {
            try {
                // @ts-ignore
                await joinGroup.mutateAsync({accessKey: sessionKey, customerUid: customer.Uid, groupUid: group, urls: serviceUrls})
                
                if (index === groups.length - 1)
                    setJoinComplete(true)
                
            } catch (error) {
                console.log(error)
            }
        })
    }
    
    const leaveGroups = (groups) => {
        if (groups.length === 0)
            setLeaveComplete(true)
        
        groups.forEach(async (group, index) => {
            try {
                // @ts-ignore
                await leaveGroup.mutateAsync({accessKey: sessionKey, customerUid: customer.Uid, groupUid: group.Group.Uid, urls: serviceUrls})
                if (index === groups.length - 1)
                    setLeaveComplete(true)
            } catch (error) {
                console.log(error)
            }
        })
    }
    
    const updateSubscriberGroups = () => {
        const customerGroups = customer.SubscriberGroupMemberships
        const newGroups = selectedGroups.filter(g => !customerGroups.some(cg => cg.Group.Uid === g))
        const removeGroups = customerGroups.filter(cg => !selectedGroups.some(g => cg.Group.Uid === g))
        joinGroups(newGroups)
        leaveGroups(removeGroups)
    }
    
    useEffect(() => {
        if (saveCustomer.status === 'success') {
            updateSubscriberGroups()
        }
    }, [saveCustomer.status])
    
    useEffect(() => {
        if (joinComplete && leaveComplete)
           onSnippetComplete(returnUrl)
    }, [joinComplete, leaveComplete])
    
    useEffect(() => {
        if (customer) {
            let groups = []
            customer.SubscriberGroupMemberships.forEach((g) => groups.push(g.Group.Uid))
            setSelectedGroups(groups)
        }
    }, [customer])
    
    const handleChange = (field, value) => {
        setSignupState({
            ...signupState,
            [field]: value
        })
    }
    
    const mapSnippetFields = (field, index) => {
        const fieldName = field.FieldName === 'LoyaltyCard' ? 'LoyaltyCardNumber' : field.FieldName
        const current = signupState[fieldName] ?? ''
        return mapSignupField(field, index, current, handleChange)
    }
    
    const toggleGroup = (uid) => {
        if (selectedGroups.includes(uid)) {
            setSelectedGroups(selectedGroups.filter((g) => g !== uid))
            return;
        }
        
        let groups = selectedGroups.slice()
        groups.push(uid)
        setSelectedGroups(groups)
    }
    
    const mapSubscriberGroup = (group, index) => {
        return <div key={index} className={`quicksignup-subscribergroup ${selectedGroups?.includes(group.Uid) ? 'selected' : ''}`} onClick={() => toggleGroup(group.Uid)}>
            {group.Name}
        </div>
    }
    
    const closeFind = () => {
        setNoCustomer(false)
        refetch()
    }
    
    const submit = (event) => {
        event.preventDefault()
        saveCustomer.mutate({
            urls: serviceUrls,
            accessKey: sessionKey,
            customer: signupState,
        })
    }

    return (
        <div className={'container'}>
            <Header title={'Quick Edit'}/>
            {status === 'loading' && <LoadingSpinner />}
            {cartStatus === 'success' && !cartResponse && <div> <p>Unable to find cart</p><Button onClick={() => onSnippetComplete(returnUrl)} variant='contained' color={'primary'}>Close</Button></div>}
            {noCustomer && <FindCustomerModal close={closeFind}/>}
            {deviceProfile && cartResponse &&
                <>
                    <div className={'snippetContainer center'}>
                        <form onSubmit={submit}>
                            {deviceProfile.POSSnippetSettings.QuickEditFields.map(mapSnippetFields)}
                            <br />
                            {subscriberGroups && 
                                <div>
                                    {subscriberGroups.length > 0 && <p className={'bold center'}>Add Customer To Groups</p>}
                                    <div style={{marginTop: '25px'}}>
                                        {subscriberGroups.map(mapSubscriberGroup)}
                                    </div>
                                </div>
                            }
                            <br />
                            <br />
                            <Button style={{margin: '5px'}} variant={'text'} className={'left'} color={'primary'} onClick={() => onSnippetComplete(returnUrl)}>Close</Button>
                            <Button style={{margin: '5px'}} type='submit' className={'right'} variant='contained' color={'primary'} disabled={saveCustomer.status === 'loading' || joinGroup.status === 'loading' || leaveGroup.status === 'loading' || customer?.EditAtPOS === false}>Save</Button>
                            {(saveCustomer.status === 'loading' || joinGroup.status === 'loading' || leaveGroup.status === 'loading') && <LoadingSpinner />}
                            {saveCustomer.status === 'error' && <p className='error-text'>{saveCustomer.error}</p>}
                            {leaveGroup.status === 'error' && <p className='error-text'>{leaveGroup.error}</p>}
                            {joinGroup.status === 'error' && <p className='error-text'>{joinGroup.error}</p>}
                            {customerStatus === 'error' && <p className='error-text'>{customerStatus}</p>}
                            {serviceUrlsStatus === 'error' && <p className='error-text'>{serviceUrlsError}</p>}
                        </form>
                    </div>
                </>
            }
        </div>
    )
}