import React, {Fragment, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {Link, Redirect} from 'react-router-dom'
import {MDBBtn, MDBCol, MDBInput, MDBRow} from 'mdbreact';

import Header from './Header';
import {resetLoginError, setLoggedIn, submitLoginRequest} from '../Redux/Actions/Login';
import LoadingSpinner from './LoadingSpinner';
import {setDomain} from '../Redux/Actions/Session';
import {getSessionLoginDomain} from "../Helpers/Globals";
import {Button} from "@mui/material";

export default function Login(props) {
    const initUsername = useSelector(state => state.createAccount.username)
    let initialDomain = useSelector(state => state.createAccount.domain);
    if (!initialDomain)
        initialDomain = getSessionLoginDomain(window)
    const dispatch = useDispatch()
    const [domain, setDomainValue] = useState(initialDomain ?? '')
    const [username, setUsername] = useState(initUsername)
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const redirectUrl = useSelector(state => state.session.ProvisionSession.PostLogonRedirectUrl);
    const usageId = useSelector(state => state.session.usageId);
    const error = useSelector(state => state.login.error)
    const loggedIn = useSelector(state => state.login.loggedIn)
    const status = useSelector(state => state.login.status)

    const handleInputChange = (event) => {
        event.preventDefault();
        switch (event.target.name) {
            case 'username':
                setUsername(event.target.value)
                break;
            case 'password':
                setPassword(event.target.value)
                break;
            case 'domain':
                setDomainValue(event.target.value)
                break;
            default:
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        dispatch(setDomain(domain))
        dispatch(submitLoginRequest(domain, username, password))
    }

    if (loggedIn === true) {
        if (!domain) {
            dispatch(setLoggedIn(false));
        }
        else {
            dispatch(setDomain(domain))
            if (redirectUrl === undefined || redirectUrl === null)
                return <Redirect push to={'/devicesetup' + props.location.search}/>
            else {
                const url = redirectUrl + '?UsageID=' + usageId;
                window.location.assign(url);
            }
        }
    }

    if (status === 'error') {
        window.alert('Login error: ' + error + '.');
        dispatch(resetLoginError());
    }

    return (
        <Fragment>
            <Header title="Log In"/>
            <MDBRow className="form">
                <MDBCol md="12">
                    <form onSubmit={onSubmit}>
                        <div className="grey-text">
                            <MDBInput
                                autoFocus
                                id="domain"
                                name="domain"
                                label="Enter your bLoyal login domain"
                                group
                                type="text"
                                required
                                value={domain}
                                onChange={handleInputChange}
                            />
                            <MDBInput
                                id="username"
                                name="username"
                                label="Enter your username"
                                group
                                type="text"
                                required
                                value={username}
                                onChange={handleInputChange}
                            />
                            <MDBInput
                                id="password"
                                name="password"
                                label="Enter your password"
                                group
                                type="password"
                                required
                                value={password}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="text-center">
                            <Button id="submit" className='next-btn' type="submit">Login</Button>
                        </div>
                    </form>
                </MDBCol>
            </MDBRow>
            {(loading && loggedIn !== false) &&
            <LoadingSpinner/>
            }
            <div>
                <Link to="/resetpassword">Forgot Your Password?</Link>
            </div>

            <div>
                <Link to="/createaccount">Create an Account</Link>
            </div>
        </Fragment>
    )

}

