import {client} from "./ApiClient";
import {mapServiceUrls} from "../Helpers/Globals";
import {useQuery} from "react-query";

const getCart = (key, urls, sessionKey, cartUid, cartExternalId, cartSourceExternalId, deviceCode, storeCode) => {
    const uniqueParam = new Date().getTime();
    return client(urls.LoyaltyEngineApiUrl, `api/v4/${sessionKey}/carts?${!!cartUid ? `cartUid=${cartUid}&` : ''}${!!cartExternalId ? `externalId=${cartExternalId}&` : ''}${!!cartSourceExternalId ? `sourceExternalId=${cartSourceExternalId}&` : ''}${!!storeCode ? `storeCode=${storeCode}&` : ''}${!!deviceCode ? `deviceCode=${deviceCode}&` : ''}&_=${uniqueParam}`)
}

export default function useGetCart(urls, sessionKey, cartUid, cartExternalId, cartSourceExternalId, deviceCode, storeCode) {
    const {status, data, error, refetch } = useQuery(['Cart', cartUid, cartExternalId, cartSourceExternalId], (key) => getCart(key, mapServiceUrls(urls), sessionKey, cartUid, cartExternalId, cartSourceExternalId, deviceCode, storeCode), {enabled: !!sessionKey && !!urls, staleTime: 1000*10})
    const cartResponse = data ? data.data : undefined
    return {status, cartResponse, error, refetch}
}