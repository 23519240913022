import {client} from "./ApiClient";
import {mapServiceUrls} from "../Helpers/Globals";
import {useQuery} from "react-query";
import {Settings} from "../Settings";

const getServiceProviderConfigurationDefinition = (key, accessKey, providerUid, serviceUrls) => {
    return client(serviceUrls.GridApiUrl, `api/v4/${accessKey}/ServiceProvisioning/Definitions/${providerUid}`)
}

export default function useServiceProviderConfigurationDefinition(accessKey, providerUid, serviceUrls) {
    const {status, data, error } = useQuery(['ServiceProviders', providerUid], (key) => getServiceProviderConfigurationDefinition(key, accessKey, providerUid, mapServiceUrls(serviceUrls)), {enabled: !!accessKey && !!serviceUrls, staleTime: 1000*10})
    const providerConfig = data ? data.data : undefined
    return {status, providerConfig, error}
}