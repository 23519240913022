import React, {Fragment, useEffect, useState} from 'react'
import Header from '../Header'
import {useDispatch, useSelector} from 'react-redux'
import {getCart} from '../../Redux/Actions/PreviewDiscount';
import CartPricingSummary from './CartPricingSummary';
import CartDiscountSummary from './CartDiscountSummary';
import CartLoyaltySummary from './CartLoyaltySummary';
import CartCouponSummary from './CartCouponSummary';
import {MDBBtn} from 'mdbreact';
import {Redirect} from 'react-router-dom';
import useServiceUrls from "../../Utils/UseServiceUrls";
import useGetCart from "../../Utils/UseGetCart";
import LoadingSpinner from "../LoadingSpinner";
import {Button} from "@mui/material";
import {getQueryString} from "../../Helpers/Globals";

export default function PreviewDiscount() {
    const [preview, setPreview] = useState(false)
    const [sessionKey, setSessionKey] = useState('');
    const [loginDomain, setLoginDomain] = useState('');
    const [cartUid, setCartUid] = useState('');
    const [cartExternalId, setCartExternalId] = useState('');
    const [cartSourceExternalId, setCartSourceExternalId] = useState('');
    const { serviceUrlsStatus, serviceUrls, serviceUrlsError } = useServiceUrls(loginDomain);
    const { status: cartStatus, cartResponse, error: cartError } = useGetCart(serviceUrls, sessionKey, cartUid, cartExternalId, cartSourceExternalId);
    const [loyaltySummary, setLoyaltySummary ] = useState(cartResponse?.LoyaltySummary);
    
    useEffect(() => {
        if (cartResponse)
            setLoyaltySummary(cartResponse.LoyaltySummary);
    }, [cartResponse])

    useEffect(() => {
        setSessionKey(getQueryString(window.location.href, 'bl_sk') ?? getQueryString(window.location.href,'SessionKey') ?? '');
        setLoginDomain(getQueryString(window.location.href, 'bl_LoginDomain') ?? getQueryString(window.location.href,'LoginDomain') ?? '');
        setCartUid(getQueryString(window.location.href, 'bL_CartUid') ?? getQueryString(window.location.href,'CartUid') ?? '');
        setCartExternalId(getQueryString(window.location.href, 'bl_CartExternalId') ?? getQueryString(window.location.href,'cartExternalId') ?? '')
        setCartSourceExternalId(getQueryString(window.location.href, 'bl_CartSourceExternalId') ?? getQueryString(window.location.href,'CartSourceExternalId') ?? '')
    }, [window.location.search])

    const previewCart = () => {
        setPreview(true);
    }

    if (preview) {
        return (
            <Redirect push to={"/POS/previewcart" + window.location.search} />
        )
    }

    return (
        <Fragment>
            <Header title="Cart Promotion Summary" />
            {cartStatus === 'loading' || serviceUrlsStatus === 'loading' ? <LoadingSpinner /> :
            loyaltySummary ?
                <div style={{ maxWidth: "500px", marginRight: "auto", marginLeft: "auto" }}>
                    <CartPricingSummary prices={loyaltySummary.AppliedPrices} />
                    <CartDiscountSummary discounts={loyaltySummary.AppliedDiscounts} />
                    <CartLoyaltySummary loyalty={loyaltySummary} />
                    <CartCouponSummary coupons={loyaltySummary.AppliedCoupons} />
                    <Button id="preview" style={{ padding: "5px", width: "100px", float: "right" }} className='next-btn' onClick={previewCart}>Preview</Button>
                </div> :
                cartStatus === 'error' || serviceUrlsStatus === 'error' ?
                <p>{`Error: ${cartError ? cartError : serviceUrlsError ? serviceUrlsError : 'Unable to get cart details' }`}</p> : <p>No cart found</p>
            }
        </Fragment>
    )
}
