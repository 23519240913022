import {client} from "./ApiClient";
import {isNullOrUndefined, mapServiceUrls} from "../Helpers/Globals";
import {useQuery} from "react-query";
import {Settings} from "../Settings";

const getServiceProviderConfiguration = (key, serviceUrls, accessKey, serviceIntegrationUid) => {
        return  client(mapServiceUrls(serviceUrls).GridApiUrl, `api/v4/${accessKey}/ServiceProvisioning/Services/${serviceIntegrationUid}`)
}

export default function useServiceProviderConfiguration(serviceUrls, accessKey, serviceIntegrationUid) {
    const {status, data, error } = useQuery(['ProviderConfiguration', accessKey, serviceIntegrationUid], (key) => getServiceProviderConfiguration(key, serviceUrls, accessKey, serviceIntegrationUid), {enabled: !!serviceUrls && !!accessKey && serviceIntegrationUid !== undefined, staleTime: 1000*10})
    const providerConfiguration = data ? data.data : undefined
    return {status, providerConfiguration, error}
}