import React, {Fragment, useEffect} from 'react'
import { MDBCard, MDBCardHeader, MDBCardBody, MDBCardText } from 'mdbreact'

export default function CartDiscountSummary(props) {
    
    useEffect(() => {
        let strings = props.discounts.map(mapDiscount)
        const result = strings.join("<br >")
        const element = document.getElementById('discount-card')
        if (element)
            element.innerHTML = result
    }, [])

    const mapDiscount = (discount, index) => {
        return "Applied Discount: " + discount.Name + " (" + discount.Code + ")"
    }

    return (
        <Fragment>
            <MDBCard className="cart-summary-card">
                <MDBCardHeader className="cart-summary-card-header">Discounts</MDBCardHeader>
                <MDBCardBody style={{textAlign: "left"}}>
                    <MDBCardText style={{color: "#393939"}}>
                        {( props.discounts !== undefined && props.discounts.length > 0) &&
                            <span id={'discount-card'} />
                        }
                        {(props.discounts === undefined || props.discounts.length === 0) &&
                            <Fragment>No discounts available.</Fragment>
                        }
                    </MDBCardText>
                </MDBCardBody>
            </MDBCard>
        </Fragment>
    )
}
