import React, {useEffect, useState} from 'react';
import {getQueryString} from "../../Helpers/Globals";
import LoadingSpinner from "../LoadingSpinner";
import {Receipt} from "@mui/icons-material";

export default function KoronaCustomerFacingWrapper(props) {
    const [loginDomain, setLoginDomain] = useState('')
    const [deviceCode, setDeviceCode] = useState('')
    const [storeCode, setStoreCode] = useState('')
    const [receiptNumber, setReceiptNumber] = useState('')
    const [sessionKey, setSessionKey] = useState('')
    const [variablesSet, setVariablesSet] = useState(false)
    const [errorString, setErrorString] = useState(false)
    
    useEffect(() => {
        setLoginDomain(getQueryString(window.location.href, 'bl_loginDomain'))
        setDeviceCode(getQueryString(window.location.href, 'bl_deviceCode'))
        setStoreCode(getQueryString(window.location.href, 'bl_storeCode'))
        setReceiptNumber(getQueryString(window.location.href, 'receiptNumber'))
        setSessionKey(getQueryString(window.location.href, 'bl_sk'))
        setVariablesSet(true)
    }, [window.location.href])
    
    useEffect(() => {
        if (variablesSet) {
            if (!deviceCode || !storeCode || !receiptNumber || !sessionKey || !loginDomain) {
                let error = 'Missing required data:\n'
                let errors = []
                if (!loginDomain)
                    errors.push('Login Domain')
                if (!deviceCode)
                    errors.push('Device Code')
                if (!storeCode)
                    errors.push('Store Code')
                if (!receiptNumber)
                    errors.push('Korona Receipt Number')
                if (!sessionKey)
                    errors.push('Access Key')
                setErrorString(error.concat(errors.join(', ')))
            } else
                window.location.assign(`${window.location.origin}/POS/CustomerFacing?bl_sk=${sessionKey}&bl_CartSourceExternalId=${receiptNumber}&bl_loginDomain=${loginDomain}&bl_StoreCode=${storeCode}&bl_DeviceCode=${deviceCode}`)
        }
    }, [variablesSet])
    
    if (!errorString)
        return (<LoadingSpinner />)
            
    return (<p className={'error-text'}>{errorString}</p>)
}