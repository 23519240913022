import React, { Fragment, useEffect } from 'react'
import LoadingSpinner from '../LoadingSpinner';
import Header from '../Header';
import { useDispatch, useSelector } from 'react-redux';
import { checkAccountCreationStatus } from '../../Redux/Actions/CreateAccount';

export default function FormAccountProcessing(props) {
    const dispatch = useDispatch();
    const percent = useSelector(state => state.createAccount.percentCompleted);

    useEffect(() => {
        dispatch(checkAccountCreationStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (percent === 100) {
        props.nextStep()
    }

    return (
        <Fragment>
            <Header title="Account Processing" step={3} totalSteps={5}/>
            <br />
            <h4>Your account is being created, this will take 5-10 minutes.</h4>
            <h4>Please do not close this page until this process is complete.</h4>
            <h4>Your account is {percent}% done</h4>
            <LoadingSpinner />
        </Fragment>
    )
}

