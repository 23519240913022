import {client} from "./ApiClient";
import {mapServiceUrls} from "../Helpers/Globals";
import {useQuery} from "react-query";

const getAvailableCoupons = (key, urls, accessKey, cartUid, externalId, sourceExternalId, deviceCode, storeCode) => {
    return client(urls.LoyaltyEngineApiUrl, `api/v4/${accessKey}/carts/AvailableCoupons?${!!cartUid ? `cartUid=${cartUid}&` : ''}${!!externalId ? `externalId=${externalId}&` : ''}${!!sourceExternalId ? `sourceExternalId=${sourceExternalId}&` : ''}${!!storeCode ? `storeCode=${storeCode}&` : ''}${!!deviceCode ? `deviceCode=${deviceCode}&` : ''}`)
}

export default function useAvailableCoupons(urls, accessKey, cartUid, externalId, sourceExternalId, deviceCode, storeCode) {
    const {status, data, error, refetch } = useQuery(['Coupons', accessKey], (key) => getAvailableCoupons(key, mapServiceUrls(urls), accessKey, cartUid, externalId, sourceExternalId, deviceCode, storeCode), {enabled: !!accessKey && !!urls, staleTime: 1000*5*60})
    const coupons = data ? data.data : undefined
    return {status, coupons, error, refetch}
}