import {ofType} from "redux-observable";
import actionTypes from "../Redux/Actions/ActionTypes";
import {catchError, map, mapTo, mergeMap, switchMap, tap, withLatestFrom} from "rxjs/operators";
import {
    checkExistingCreationSession,
    getProvisionSessionFailure,
    getProvisionSessionSuccess,
    sessionRestored,
    setExistingCreationSession,
    setNoExistingCreationSession,
    setSessionKey, setUsageId
} from "../Redux/Actions/Session";
import {ajax} from 'rxjs/ajax';
import {of} from "rxjs";
import {setStoreData} from "../Redux/Actions/ProvisionStore";
import {setDeviceData, setDeviceKeyFailure, setDeviceKeySuccess} from "../Redux/Actions/ProvisionDevice";
import {Settings} from "../Settings";
import {
    checkAccountCreationStatus,
    createAccountFailure,
    setAccountCreationStatus
} from "../Redux/Actions/CreateAccount";
import {CreateAccountStatus} from "../Helpers/Enums";

export const sessionKeyEpic = (action$, state$) => 
    action$.pipe(
        ofType(actionTypes.login.loginRequestSuccess),
        withLatestFrom(state$),
        switchMap(([action, state]) => 
            setSessionKeyCall(action),
        ),
    )

const setSessionKeyCall = (action) => {
    return of(setSessionKey(action.data.SessionKey), setUsageId(action.data.UsageId));
}

export const getProvisionSessionEpic = (action$, state$) => 
    action$.pipe(
        ofType(actionTypes.session.getProvisionSession),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            getProvisionSessionCall(action, state)
        )
    )

const getProvisionSessionCall = (action, state) => {
    const provisionKey = state.session.provisionKey;
    return ajax(Settings.DomainServerUrl + "/api/v4/ServiceProvisioning/ProvisionSessions/" + provisionKey)
        .pipe(
            tap(response => console.log(response)),
            mergeMap(response => processGetProvisionSessionCall(response.response)),
            catchError(error => of(getProvisionSessionFailure(error)))
        )
}

const processGetProvisionSessionCall = (response) => {
    if (response.status === 'error') {
        return of(getProvisionSessionFailure(response.message));
    }
    else {
        const session = response.data;
        console.log(session)
        return of(
            getProvisionSessionSuccess(response),
            setStoreData(session.StoreName, session.StoreCode),
            setDeviceData(session.DeviceName, session.DeviceCode)
        )
    }
}

export const setDataFromProvisionEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionTypes.session.getProvisionSessionSuccess),
        mapTo(checkExistingCreationSession())
    )

export const postProvisionSessionEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionTypes.session.postProvisionSession),
        withLatestFrom(state$),
        switchMap(([action, state]) => 
            postProvisionSessionCall(action, state)
        )
    )

const postProvisionSessionCall = (action, state) => {

}

export const restoreSessionEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionTypes.backOfficeConnector.restoreBackOfficeConnectorForm),
        withLatestFrom(state$),
        switchMap(([action,]) =>
            restoreSession(action)
        ),
    );


const restoreSession = (action) => {
    return of(sessionRestored());
}

export const checkExistingAccountStatusEpic = (action$, state$) => 
    action$.pipe(
        ofType(actionTypes.session.checkExistingCreationSession),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            checkAccountCreationCall(action, state)
        )
    )

const checkAccountCreationCall = (action, state) => {
    const provisionKey = state.session.provisionKey;
    return ajax(Settings.DomainServerUrl + `/api/v4/${provisionKey}/ServiceProvisioning/ClientCreationSessions/`)
        .pipe(
            map(response => processCheckAccountCreationCall(response.response)),
            catchError(error => of(checkAccountCreationStatus()))
        )
}

const processCheckAccountCreationCall = (response) => {
    if (response.status === 'error') {
        return checkAccountCreationStatus()
    } else if (response.data === undefined) {
        return setNoExistingCreationSession();
    } else {
        if (response.data.Status === CreateAccountStatus.Failed) {
            return createAccountFailure(response.data.StatusMessage);
        }
        return setExistingCreationSession(response.data);
    }
}

export const setDeviceKeyEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionTypes.provisionDevice.provisionDeviceRequestSuccess),
        withLatestFrom(state$),
        switchMap(([action, state]) => 
            setDeviceKeyCall(action, state)
        ),
    );


const setDeviceKeyCall = (action, state) => {
    const deviceKey = state.provisionDevice.deviceKey;
    let body = {
        DeviceKey: deviceKey,
        Uid: state.session.provisionKey,
    }
    
    return ajax.post(Settings.DomainServerUrl + '/api/v4/Provision/ProvisionSessions', body)
        .pipe(
            map(response => processSetDeviceKey(response.response, action)),
            catchError(error => of(setDeviceKeyFailure(error)))
        )
}

const processSetDeviceKey = (response) => {
    if (response.status === 'error') {
        return setDeviceKeyFailure(response.message);
    }
    else {
        return setDeviceKeySuccess();
    }
}

export const processEpic = (response, action, success, failure) => {
    if (response.status === 'error') {
        if (response.message.startsWith())
        return setDeviceKeyFailure(response.message);
    }
    else {
        return setDeviceKeySuccess();
    }
}