import {client} from "./ApiClient";
import {mapServiceUrls} from "../Helpers/Globals";
import {useMutation, useQuery, useQueryClient} from "react-query";

const signupCustomer = ({urls, accessKey, customer, groupsToJoin, deviceCode, storeCode, signupDuplicate}) => {
    return client(mapServiceUrls(urls).LoyaltyEngineApiUrl, `api/v4/${accessKey}/customers/commands/signups?${!!storeCode ? `storeCode=${storeCode}&` : ''}${!!deviceCode ? `deviceCode=${deviceCode}` : ''}`, {
        body: {
            Customer: {
                ...customer,
                Address: {
                    Address1: customer.Address1,
                    Address2: customer.Address2,
                    City: customer.City,
                    State: customer.State,
                    PostalCode: customer.PostalCode,
                    Country: customer.Country
                }
            },
            GroupsToJoin: groupsToJoin,
            SignupDuplicate: signupDuplicate
            // ClientUid: "00000000-0000-0000-0000-000000000000"
        }
    })
}

export default function useSignupCustomer() {
    return useMutation(signupCustomer);
}