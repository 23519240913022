import { createStore, applyMiddleware } from 'redux';
import  { rootReducer, rootEpic } from './Root';
import initialState from './InitialState';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';




const composeEnhancers = composeWithDevTools({
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
  });    
const epicMiddleware = createEpicMiddleware();

const enhancer = composeEnhancers(
    applyMiddleware(epicMiddleware),
    // other store enhancers if any
  );

export default function configureStore() {
  
  const store = createStore(rootReducer, initialState, enhancer);

    epicMiddleware.run(rootEpic);
    return store;

}