import React from 'react'
import FormCompanyDetails from './FormCompanyDetails';
import FormOwnerDetails from './FormOwnerDetails';
import FormConfirmation from './FormConfirmation';
import FormAccountProcessing from './FormAccountProcessing';
import Wizard from '../Wizard';
import { useSelector } from 'react-redux';
import AccountCreationComplete from './AccountCreationComplete';
import CreateAccountError from "./CreateAccountError";
import {useAccountCreationContext} from "../../Utils/Contexts/AccountCreationContext";

export default function CreateAccountForm(props) {
    // const {status} = useAccountCreationContext()
    const existingSession = useSelector(state => state.session.existingSession);
    const error = useSelector(state => state.createAccount.error);

    const map = {
        currentPage: FormCompanyDetails,
        [FormCompanyDetails]: [null, FormOwnerDetails],
        [FormOwnerDetails]: [FormCompanyDetails, FormConfirmation],
        [FormConfirmation]: [FormOwnerDetails, FormAccountProcessing],
        [FormAccountProcessing]: [FormConfirmation, AccountCreationComplete]
    }
    const closeWindow = () => {
        const ww = window.open('', '_self');
        ww.close();
    }
    
    if (error) {
        return <CreateAccountError />
    }

    if (existingSession) {
        const mapForExisting = {
            currentPage: FormAccountProcessing,
            [FormAccountProcessing]: [null, AccountCreationComplete],
        }

        return (
            <Wizard pageMap={mapForExisting} closeWindow={closeWindow} redirect="/" location={props.location}/>
        )
    }
    
    // if (status === 100) {
    //     const mapForSuccess = {
    //         currentPage: AccountCreationComplete,
    //         [AccountCreationComplete]: [null, null]
    //     }
    //
    //     return (
    //         <Wizard pageMap={mapForSuccess} closeWindow={closeWindow} redirect="/" location={props.location}/>
    //     )
    // }

    return (
        <Wizard pageMap={map} closeWindow={closeWindow} redirect="/" location={props.location}/>
    )
}
