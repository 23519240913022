import initialState from "../InitialState";
import actionTypes from "../Actions/ActionTypes";


export const previewDiscountReducer = (state = initialState.PreviewDiscount, action) => {
    switch (action.type) {
        case actionTypes.PreviewDiscount.getCart:
            return {
                ...state,
                LoadingCart: true,
                Session: {
                    SessionKey: action.sessionKey,
                    LoginDomain: action.loginDomain,
                    CartUid: action.cartUid,
                    ExternalId: action.externalId,
                    SourceExternalId: action.sourceExternalId
                }
            }
        case actionTypes.PreviewDiscount.setCart:
            return {
                ...state,
                LoadingCart: false,
                CartSet: true,
                Cart: action.data.Cart,
                LoyaltySummary: action.data.LoyaltySummary,
            }
        default:
            return state;
    }
}