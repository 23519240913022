import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createAccountRequest, resetCreateAccountError } from '../../Redux/Actions/CreateAccount';
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact';
import Header from '../Header';
import LoadingSpinner from '../LoadingSpinner';
import {useEditionContext} from "../../Utils/Contexts/EditionContext";
import {Button} from "@mui/material";

export default function FormConfirmation(props) {
    const dispatch = useDispatch();
    const { editionCode } = useEditionContext()
    const companyName = useSelector(state => state.createAccount.companyName);
    const domain = useSelector(state => state.createAccount.domain);
    const address1 = useSelector(state => state.createAccount.address1);
    const address2 = useSelector(state => state.createAccount.address2);
    const city = useSelector(state => state.createAccount.city);
    const state = useSelector(state => state.createAccount.state);
    const country = useSelector(state => state.createAccount.country);
    const postal = useSelector(state => state.createAccount.postal);
    const firstName = useSelector(state => state.createAccount.firstName);
    const lastName = useSelector(state => state.createAccount.lastName);
    const phone = useSelector(state => state.createAccount.phone);
    const email = useSelector(state => state.createAccount.email);
    const username = useSelector(state => state.createAccount.username);
    const errorMessage = useSelector(state => state.createAccount.error)
    const password = useSelector(state => state.createAccount.password);
    const status = useSelector(state => state.createAccount.status);

    const [loading, setLoading] = useState(false);

    const nextStep = e => {
        e.preventDefault();
        setLoading(true);
        dispatch(createAccountRequest(companyName, domain, address1, address2, city, state, country, postal, firstName, lastName, email, username, password, editionCode, phone));
    }

    const prevStep = e => {
        e.preventDefault();
        props.prevStep();
    }

    if (status === 'success') {
        props.nextStep();
    }

    if (status === 'failure') {
        window.alert('Account creation error: ' + errorMessage + '.');
        dispatch(resetCreateAccountError());
    }
        

    let address;
    if (address2 === '') {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        address = <Fragment><dt>Address</dt>
            <dd>{address1 + ', ' + city + ', ' + state + ' ' + postal + ' ' + country}</dd></Fragment>;
    } else {
        address = <Fragment><dt>Address</dt><dd>{address1 + ' ' + address2 + ', ' + city + ', ' + state + ' ' + postal + ' ' + country}</dd></Fragment>;
    }

    return (
        <Fragment>
            <Header title="Confirm Details" />
            <div className="form">
                <dl>
                    <dt>Company Name</dt>
                    <dd>{companyName}</dd>
                    <dt>Domain</dt>
                    <dd>{domain}</dd>
                    {address}
                    <dt>Owner</dt>
                    <dd>{firstName + ' ' + lastName}</dd>
                    <dt>Email</dt>
                    <dd>{email}</dd>
                    <dt>Phone</dt>
                    <dd>{phone}</dd>
                    <dt>Login</dt>
                    <dd>Username: {username}</dd>
                </dl>

                <MDBRow className="btn-row">
                    <MDBCol xs="12" sm="6">
                        <Button className="back-btn btn-pair" id="back" name="back" color="primary" onClick={prevStep} >Back</Button>
                    </MDBCol>
                    <MDBCol xs="12" sm="6">
                        <Button id="confirm" className='next-btn' type="button" onClick={nextStep}>Confirm</Button>
                    </MDBCol>
                </MDBRow>
                {loading &&
                    <LoadingSpinner />
                }
            </div>
        </Fragment>
    )
}
