import React, {useMemo, useState} from 'react';
import {Dialog, DialogActions, DialogTitle} from "@mui/material";
import {Button} from "@material-ui/core";
import BasicTable from "../Tables/BasicTable";

export default function DuplicateCustomerModal(props) {
    
    const cancel = () => {
        props.onCancel()
    }
    
    const signupAnyways = () => {
        props.signup()
    }
    
    const loadExisting = () => {
        props.loadExisting()
    }
    
    const columns = useMemo(() => [
        {
            Header: 'Account Number',
            accessor: 'Code',
        },
        {
            Header: 'Name',
            accessor: 'FirstName',
            Cell: ({row: {original}}) => <p className={'table-text'}>{`${original.FirstName} ${original.LastName}`}</p>
        },
        {
            Header: 'Phone Number',
            accessor: 'Phone1',
        },
        {
            Header: 'Address',
            accessor: 'Address',
            Cell: ({row: {original}}) => <p className={'table-text'}>{`${original.Address.Address1 === null ? '' : original.Address.Address1} ${original.Address.Address2 === null ? '' : original.Address.Address2} ${original.Address.City === null ? '' : original.Address.City} ${original.Address.State === null ? '' : original.Address.State}`}</p>
        },
        {
            Header: 'Zip',
            accessor: 'Zip',
            Cell: ({row: {original}}) => <p className={'table-text'}>{`${original.Address.PostalCode === null ? '' : original.Address.PostalCode}`}</p>
        },
    ])
    
    return (
        <Dialog maxWidth={'lg'} fullWidth={true} open={true} scroll={'paper'}>
            <DialogTitle>Duplicate Customer</DialogTitle>
            <div style={{padding: '0px 25px'}}>
                <BasicTable columns={columns} data={[props.resolvedCustomer]} />
            </div>
            <div style={{marginTop: '30px', marginRight: '30px', marginLeft: '30px'}}>
                <Button onClick={cancel} variant='text' className={'left button'} color={'primary'}>Cancel</Button>
                <Button onClick={loadExisting} variant='contained' className={'right button'} color={'primary'}>Load Existing</Button>
                {props.allowSignup && <Button onClick={signupAnyways} variant='contained' className={'right button'} color={'primary'}>Signup Anyway</Button>}
            </div>
        </Dialog>
    )
}