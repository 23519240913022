import actionTypes from './ActionTypes';

export const resetPasswordRequest = (domain, email) => {
    return {
        type: actionTypes.resetPassword.resetPasswordRequest,
        domain,
        email,
    };
}

export const resetPasswordRequestSuccess = (status) => {
    return {
        type: actionTypes.resetPassword.resetPasswordRequestSuccess,
        status,
    }
}

export const resetPasswordRequestFailure = (error) => {
    return {
        type: actionTypes.resetPassword.resetPasswordRequestFailure,
        error
    }
}

export const resetError = () => {
    return {
        type: actionTypes.resetPassword.resetError,
    }
}