import React, {useEffect, useState} from 'react';
import {TextField} from '@material-ui/core';
import {FormControl, Input, InputAdornment, InputLabel, Tooltip} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";


export default function NumberInputWithHelpText(props) {
    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    const handleChange = (event) => {
        event.preventDefault();
        setValue(event.target.value);
        props.handleChange(props.id, event.target.value);
    };

    return (
        <FormControl variant="standard" className='input'>
            <InputLabel htmlFor={props.id}>{`${props.label}${props.required ? '*' : ''}`}</InputLabel>
            <Input
                style={{marginTop: '5px', marginBottom: '5px', width: '260px', height: '38px'}}
                id={props.id}
                label={props.label}
                value={value}
                type='number'
                onChange={handleChange}
                inputProps={{min: 0}}
                endAdornment={
                    <InputAdornment style={{cursor: 'pointer'}} position="end">
                        <Tooltip title={props.tooltip}>
                            <InfoOutlinedIcon />
                        </Tooltip>
                    </InputAdornment>
                }
            />
        </FormControl>
    );
}