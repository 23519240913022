export const CreateAccountStatus = {
    Undefined: "Undefined",
    Waiting: "Waiting",
    Started: "Started",
    Complete: "Complete",
    Failed: "Failed",
}

export default class GetEnumValue {

}