import {client} from "./ApiClient";
import {mapServiceUrls} from "../Helpers/Globals";
import {useMutation, useQuery, useQueryClient} from "react-query";

const acknowledgeAlert = ({urls, accessKey, cartUid, cartExternalId, cartSourceExternalId, alertUid}) => {
    return client(mapServiceUrls(urls).LoyaltyEngineApiUrl, `api/v4/${accessKey}/alerts/commands/acknowledge`, {
        body: {
            CartUid: cartUid,
            CartExternalId: cartExternalId,
            CartSourceExternalId: cartSourceExternalId,
            AlertUid: alertUid,
        }
    })
}

export default function useAcknowledgeAlert() {
    return useMutation(acknowledgeAlert);
}