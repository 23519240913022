import React, { Fragment } from 'react'
import {MDBRow, MDBCol} from 'mdbreact'
import logo from '../bLoyalLogo.png'

export default function LandingHeader(props) {
    
    if (!props.partnerLogoUrl)
        return (
            <Fragment>
                <MDBRow style={{ minHeight: "115px" }}>
                    <MDBCol md="4" xs="4" style={{textAlign: "left" }}>
                        <p><i className="far fa-envelope"></i> info@bloyal.com &nbsp; <i className="fas fa-phone"></i> (877)869-1715</p>
                        <img src={logo} alt="bLoyal Logo" />
                    </MDBCol>
                    <MDBCol md="8" xs="8" >
                        <h5 style={{marginTop: "55px", fontSize: "1.5rem", fontWeight: "400"}}>{props.description}</h5>
                    </MDBCol>
                </MDBRow>
                <hr className="lineBreak" style={{marginTop: "5px"}}/>
            </Fragment>
        )
    
    return (
        <Fragment>
            <MDBRow style={{ minHeight: "115px" }}>
                <MDBCol md="4" xs="4" style={{textAlign: "left" }}>
                    <p><i className="far fa-envelope"></i> info@bloyal.com &nbsp; <i className="fas fa-phone"></i> (877)869-1715</p>
                    <img src={logo} alt="bLoyal Logo" />
                </MDBCol>
                <MDBCol md="4" xs="4" >
                <h5 style={{marginTop: "55px", fontSize: "1.5rem", fontWeight: "400"}}>{props.description}</h5>
                </MDBCol>
                <MDBCol md='4' xs='4' style={{paddingTop: "50px"}}>
                    <img src={props.partnerLogoUrl} alt="Partner Logo" />
                </MDBCol>
            </MDBRow>
            <hr className="lineBreak" style={{marginTop: "5px"}}/>
        </Fragment>
    )
}
