import actionTypes from '../Actions/ActionTypes';
import initialState from '../InitialState';
import { statuses } from '../../Helpers/Status';

export const backOfficeConnectorReducer = (state = initialState.backOfficeConnector, action) => {
    switch (action.type) {
        case actionTypes.backOfficeConnector.restoreBackOfficeConnectorForm:
            return {
                ...state,
                status: statuses.provisioned,
            };
        case actionTypes.backOfficeConnector.setBackOfficeReady:
            return {
                ...state,
                status: statuses.found,
            }
        default:
            return state;
    }
}