import actionTypes from './ActionTypes';

export const checkProvisionStatus = (code) => {
    return {
        type: actionTypes.provisionStore.checkProvisionStatus,
        code,
    }
}

export const checkProvisionStatusSuccess = (data) => {
    return {
        type: actionTypes.provisionStore.checkProvisionStatusSuccess,
        data,
    }
}

export const checkProvisionStatusFailure = (error) => {
    return {
        type: actionTypes.provisionStore.checkProvisionStatusFailure,
        error
    }
}

export const provisionStoreRequest = (name, code, address1, address2, city, state, country, postal, email, phone) => {
    return {
        type: actionTypes.provisionStore.provisionStoreRequest,
        name,
        code,
        address1,
        address2,
        city,
        state,
        country,
        postal,
        email,
        phone,
    }
}

export const provisionStoreRequestSuccess = (data) => {
    return {
        type: actionTypes.provisionStore.provisionStoreRequestSuccess,
        data,
    }
}

export const provisionStoreRequestFailure = (error) => {
    return {
        type: actionTypes.provisionStore.provisionStoreRequestFailure,
        error
    }
}


export const setStoreData = (name, code) => {
    return {
        type: actionTypes.provisionStore.setStoreData,
        name,
        code,
    }
}

export const restoreProvisionStoreForm = () => {
    return {
        type: actionTypes.provisionStore.restoreProvisionStoreForm,
    }
}

export const resetProvisionStoreError = () => {
    return {
        type: actionTypes.provisionStore.resetError,
    }
}