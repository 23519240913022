import React, {useEffect, useState} from 'react';
import {TextField} from '@material-ui/core';


export default function NumberInput(props) {

    const handleChange = (event) => {
        event.preventDefault();
        props.handleChange(props.id, event.target.value);
    };

    return (
        <TextField
            style={{marginTop: '5px', marginBottom: '5px', width: '260px'}}
            id={props.id}
            aria-label={props.id}
            label={props.label}
            value={props.value}
            type='number'
            onChange={handleChange}
            className={props.className}
            inputProps={{min: 0}}
        />
    );
}