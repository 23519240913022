import {client} from "./ApiClient";
import {mapServiceUrls} from "../Helpers/Globals";
import {useMutation, useQuery, useQueryClient} from "react-query";

const removeCoupon = ({urls, accessKey, cartUid, cartExternalId, cartSourceExternalId, code, uid, quantity}) => {
    return client(mapServiceUrls(urls).LoyaltyEngineApiUrl, `api/v4/${accessKey}/carts/coupons?cartUid=${cartUid === undefined ? null : cartUid}&externalId=${cartExternalId === undefined ? null : cartExternalId}&sourceExternalId=${cartSourceExternalId === undefined ? null : cartSourceExternalId}&uid=${uid}&code=${code}&quantity=${quantity}`, {
        body: {
        },
        customConfig: {
            method: "DELETE"
        }
    })
}

export default function useRemoveCoupon() {
    return useMutation(removeCoupon);
}