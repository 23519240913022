import {client} from "./ApiClient";
import {mapServiceUrls} from "../Helpers/Globals";
import {useQuery} from "react-query";

const getDeviceProfile = (key, urls, accessKey) => {
    return client(urls.LoyaltyEngineApiUrl, `api/v4/${accessKey}/Devices`)
}

export default function useDeviceProfile(urls, accessKey) {
    const {status, data, error } = useQuery(['DeviceProfile', accessKey], (key) => getDeviceProfile(key, mapServiceUrls(urls), accessKey), {enabled: !!accessKey && !!urls, staleTime: 1000*5*60})
    const deviceProfile = data ? data.data : undefined
    return {status, deviceProfile, error}
}