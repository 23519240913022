import React from 'react';
import {Button} from "@material-ui/core";

export default function CloseButton(props) {

    const close = (e) => {
        e.preventDefault()
        props.onClose()
    }

    return (
        <Button style={{marginTop: '15px'}} className={'left'} variant={'text'} color={'primary'} onClick={close}>{props.label}</Button>
    )
}