import React, {Fragment, useEffect, useMemo, useState} from 'react';
import useServiceUrls from "../../Utils/UseServiceUrls";
import useDeviceProfile from "../../Utils/UseDeviceProfile";
import LoadingSpinner from "../LoadingSpinner";
import {
    checkBirthDate, checkValidDate,
    formatDate, getDatePattern,
    getDisplayValue,
    getFullDate, getQueryString,
    mapServiceUrls,
    onSnippetComplete
} from "../../Helpers/Globals";
import PasswordInput from "../Inputs/PasswordInput";
import EmailInput from "../Inputs/EmailInput";
import TextInput from "../Inputs/TextInput";
import {Button} from "@material-ui/core";
import useSignupCustomer from "../../Utils/UseSignupCustomer";
import useSubscriberGroups from "../../Utils/UseSubscriberGroups";
import HeaderWithButtons from "../HeaderWithButtons";
import useSetCartCustomer from "../../Utils/UseSetCartCustomer";
import Header from "../Header";
import CloseIcon from "@mui/icons-material/Close";
import CloseButton from "../PageControls/CloseButton";
import TextInputWithHelpText from "../Inputs/TextInputWithHelpText";
import useResolveCustomer from "../../Utils/UseResolveCustomer";
import FindCustomerModal from "../FindCustomer/FindCustomerModal";
import DuplicateCustomerModal from "./DuplicateCustomerModal";
import DateInputWithHelpText from "../Inputs/DateInputWithHelpText";

export const mapSignupField = (field, index, current, handleChange) => {
    const fieldName = field.FieldName === 'LoyaltyCard' ? 'LoyaltyCardNumber' : field.FieldName
    const label = getDisplayValue(fieldName);
    let inputField;
    switch (fieldName) {
        case 'Password':
            inputField = <>
                <PasswordInput required={field.IsRequired} id={fieldName} value={current} label={label}
                               handleChange={handleChange}/>
            </>;
            break;
        case 'EmailAddress':
            inputField = <EmailInput required={field.IsRequired} id={fieldName} value={current} label={label}
                                     handleChange={handleChange}/>;
            break;
        case 'BirthDate':
            inputField = <TextInputWithHelpText required={field.IsRequired} id={fieldName} value={current} label={label}
                                    handleChange={handleChange} tooltip={'Please enter date in a m/d/yyyy format'}/>;
            break;
        case 'Country':
            return (
                <Fragment key={index}>
                    {/*<SelectInput current={current} disabled={false} fieldName={field.FieldName} handleChange={handleChange} options={countryOptions}*/}
                    {/*             label={label} required={field.IsRequired}/>*/}
                </Fragment>
            );
        case 'State':
            // if (state.Country === '') {
            //     return;
            // }
            // const selected = countries?.find((curr) => curr.Code === state.Country);
            // if (selected?.HasStates)
            //     return (
            //         <Fragment key={index}>
            //             {/*<SelectInput current={value} disabled={false} fieldName={field.FieldName} handleChange={handleChange}*/}
            //             {/*             options={stateOptions} label={label} required={field.IsRequired}/>*/}
            //         </Fragment>
            //     );
            // else
                inputField = <TextInput required={field.IsRequired} id={fieldName} value={current} label={label}
                                        handleChange={handleChange}/>;

            break;
        default:
            inputField = <TextInput required={field.IsRequired} id={fieldName} value={current} label={label}
                                    handleChange={handleChange}/>;
    }
    return (
        <Fragment key={index}>
            {inputField}
            <br/>
        </Fragment>
    );
};

export default function QuickSignup(props) {
    const [sessionKey, setSessionKey] = useState('');
    const [loginDomain, setLoginDomain] = useState('');
    const [cartUid, setCartUid] = useState('');
    const [cartExternalId, setCartExternalId] = useState('');
    const [cartSourceExternalId, setCartSourceExternalId] = useState('');
    const [returnUrl, setReturnUrl] = useState('')
    const { serviceUrlsStatus, serviceUrls, serviceUrlsError } = useServiceUrls(loginDomain);
    const {status, deviceProfile, error} = useDeviceProfile(mapServiceUrls(serviceUrls), sessionKey);
    const {status: groupsStatus, subscriberGroups, error: groupsError} = useSubscriberGroups(serviceUrls, sessionKey)
    const [selectedGroups, setSelectedGroups] = useState([])
    const [signupState, setSignupState] = useState({})
    const [badDate, setBadDate] = useState(false)
    const [signupComplete, setSignupComplete] = useState(false)
    const [resolvedCustomer, setResolvedCustomer] = useState(undefined)
    const {status: resolveStatus, resolvedCustomerResponse, error: resolveError, refetch} = useResolveCustomer(serviceUrls, sessionKey, signupState)
    const signup = useSignupCustomer()
    const setCartCustomer = useSetCartCustomer()
    const [allowSignup, setAllowSignup] = useState(true)
    
    useEffect(() => {
        if (resolvedCustomer === undefined || (deviceProfile.CustomerResolutionRules === undefined || deviceProfile.CustomerResolutionRules === null))
            setAllowSignup(true)
        else {
            let foundMatch = false
            const rules = deviceProfile.CustomerResolutionRules?.Rules.map((rule) => {
                if (rule.Unique && resolvedCustomer[rule.Field] === signupState[rule.Field])
                        foundMatch = true
            })

            setAllowSignup(!foundMatch)
        }
    }, [resolvedCustomer, signupState])

    useEffect(() => {
        setSessionKey(getQueryString(window.location.href, 'bl_sk') ?? getQueryString(window.location.href,'SessionKey') ?? '');
        setLoginDomain(getQueryString(window.location.href, 'bl_LoginDomain') ?? getQueryString(window.location.href,'LoginDomain') ?? '');
        setReturnUrl(getQueryString(window.location.href, 'bl_rurl') ?? getQueryString(window.location.href,'ReturnUrl') ?? '')
        setCartUid(getQueryString(window.location.href, 'bL_CartUid') ?? getQueryString(window.location.href,'CartUid') ?? '');
        setCartExternalId(getQueryString(window.location.href, 'bl_CartExternalId') ?? getQueryString(window.location.href,'cartExternalId') ?? '')
        setCartSourceExternalId(getQueryString(window.location.href, 'bl_CartSourceExternalId') ?? getQueryString(window.location.href,'CartSourceExternalId') ?? '')
    }, [window.location.href])
    
    useEffect(() => {
        if (resolveStatus === 'success') {
            if (resolvedCustomerResponse === undefined)
                signup.mutate({
                    urls: serviceUrls,
                    accessKey: sessionKey,
                    customer: signupState,
                    groupsToJoin: selectedGroups
                })
            else
                setResolvedCustomer(resolvedCustomerResponse)
        }
    }, [resolveStatus])
    
    useEffect(() => {
        if (signup.status === 'success') {
            console.log(signup.data.data.Entity)
            const customer = signup.data.data.Entity
            setCartCustomer.mutate({urls: serviceUrls, cartUid, cartExternalId, cartSourceExternalId, customer, accessKey: sessionKey})
        }
    }, [signup.status])
    
    useEffect(() => {
        if (setCartCustomer.status === 'success') {
            if (props.onComplete !== undefined)
                props.onComplete()
            else {
                onSnippetComplete(returnUrl)
                setTimeout(() => setSignupComplete(true), 1000)
            }
        }
    }, [setCartCustomer.status])
    
    useEffect(() => {
        if (deviceProfile && deviceProfile.SubscriberGroupUids !== undefined) {
            let groups = selectedGroups.slice()
            deviceProfile.SubscriberGroupUids.forEach((g) => groups.push(g))
            setSelectedGroups(groups)
        }
    }, [deviceProfile])
    
    const handleChange = (field, value) => {
        setSignupState({
            ...signupState,
            [field]: value
        })
        if (field === 'BirthDate' && badDate)
            setBadDate(false)
            
    }
    
    const mapSnippetFields = (field, index) => {
        const fieldName = field.FieldName === 'LoyaltyCard' ? 'LoyaltyCardNumber' : field.FieldName
        const current = signupState[fieldName] ?? ''
        return mapSignupField(field, index, current, handleChange)
    }
    
    const toggleGroup = (uid) => {
        if (selectedGroups.includes(uid)) {
            setSelectedGroups(selectedGroups.filter((g) => g !== uid))
            return;
        }
        
        let groups = selectedGroups.slice()
        groups.push(uid)
        setSelectedGroups(groups)
    }
    
    const mapSubscriberGroup = (group, index) => {
        return <div key={index} className={`quicksignup-subscribergroup ${selectedGroups?.includes(group.Uid) ? 'selected' : ''}`} onClick={() => toggleGroup(group.Uid)}>
            {group.Name}
        </div>
    }
    
    const submit = (event) => {
        event.preventDefault()
        if (checkValidDate(signupState.BirthDate) || signupState.BirthDate === '')
            refetch()
        else
            setBadDate(true)
    }
    
    const loadExisting = () => {
        setResolvedCustomer(undefined)
        setCartCustomer.mutate({urls: serviceUrls, cartUid, cartExternalId, cartSourceExternalId, customer: resolvedCustomer, accessKey: sessionKey})
    }
    
    const signupAnyway = () => {
        setResolvedCustomer(undefined)
        signup.mutate({
            urls: serviceUrls,
            accessKey: sessionKey,
            customer: signupState,
            groupsToJoin: selectedGroups,
            signupDuplicate: true,
        })
    }


    return (
        <div className={'container'}>
            {status === 'loading' && <LoadingSpinner />}
            {deviceProfile &&
                <>
                    {resolvedCustomer && <DuplicateCustomerModal allowSignup={allowSignup} resolvedCustomer={resolvedCustomer} onCancel={() => setResolvedCustomer(undefined)} loadExisting={loadExisting} signup={signupAnyway} />}
                    <Header title={'Quick Signup'}/>
                    <div className={'snippetContainer center'}>
                        <form onSubmit={submit}>
                            {deviceProfile.POSSnippetSettings.QuickSignupFields.map(mapSnippetFields)}
                            {badDate && <p className='error-text'>Please enter a valid birth date.</p>}
                            <br />
                            {subscriberGroups &&
                                <>
                                    <div>
                                        {subscriberGroups.length > 0 && <p className={'bold center'}>Add Customer To Groups</p>}
                                        <div style={{marginTop: '25px'}}>
                                            {subscriberGroups.map(mapSubscriberGroup)}
                                        </div>
                                    </div>
                                    <br />
                                </>
                            }
                            <br />
                            <Button style={{margin: '5px'}} variant={'text'} className={'left'} color={'primary'} onClick={() => onSnippetComplete(returnUrl)}>Close</Button>
                            <Button style={{margin: '5px'}} type='submit' className={'right'} variant='contained' color={'primary'} disabled={signup.status === 'loading' || setCartCustomer.status === 'loading' || resolveStatus === 'loading'}>Save</Button>
                            {(signup.status === 'loading' || setCartCustomer.status === 'loading') && <LoadingSpinner />}
                            {signupComplete && <p>Signup is complete and the customer has been set on the cart.</p>}
                            {signup.status === 'error' && <p className='error-text'>{signup.error}</p>}
                            {setCartCustomer.status === 'error' && <p className='error-text'>{setCartCustomer.error}</p>}
                            {serviceUrlsStatus === 'error' && <p className='error-text'>{serviceUrlsError}</p>}
                        </form>
                    </div>
                </>
            }
        </div>
    )
}