import {client} from "./ApiClient";
import {mapServiceUrls} from "../Helpers/Globals";
import {useQuery} from "react-query";
import {Settings} from "../Settings";

const getClientApplications = (key, accessKey, editionCode) => {
    return client(Settings.DomainServerUrl, `api/v4/${accessKey}/ServiceActivations/ClientApplications`)
}

export default function useClientApplications(accessKey) {
    const {status, data, error } = useQuery(['Applications', accessKey], (key) => getClientApplications(key, accessKey), {enabled: !!accessKey, staleTime: 1000*10})
    const applications = data ? data.data : undefined
    return {status, applications, error}
}