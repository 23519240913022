import {client} from "./ApiClient";
import {useQuery} from "react-query";
import {mapServiceUrls} from "../Helpers/Globals";

const getEngagementTypes = (key, urls, accessKey) => {
    return client(urls.LoyaltyEngineApiUrl, `api/v4/${accessKey}/engagement/types`)
}

export default function useEngagementTypes(urls, accessKey) {
    const {status, data, error } = useQuery(['EngagementTypes', accessKey], (key) => getEngagementTypes(key, mapServiceUrls(urls), accessKey), {enabled: !!accessKey && !!urls, staleTime: 1000*5*60})
    const engagementTypes = data ? data.data : undefined
    return {status, engagementTypes, error}
}