import React, {useEffect, useState} from 'react';
import {InputLabel, MenuItem, Select} from '@material-ui/core';
import {InputAdornment, Tooltip} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function SelectInputWithHelpText(props) {

    const handleChange = (event) => {
        const value = event.target.value
        props.handleChange(props.fieldName, value)
    };
    
    return (
        <div style={{width: '260px'}} className='center'>
            {props.value !== '' && props.value !== undefined &&
                <InputLabel shrink={props.value !== '' && props.value !== undefined} htmlFor={props.fieldName} style={{
                    width: '100%',
                    marginTop: "5px",
                    marginBottom: "0px",
                    float: 'left',
                    textAlign: 'left'
                }}>{props.label}</InputLabel>
            }
            <Select
                id={props.fieldName}
                style={{width: '90%', marginTop: props.value === '' && props.value !== undefined ? '15px' : '0px', marginBottom: '5px'}}
                value={props.value}
                onChange={handleChange}
                disabled={props.disabled}
                displayEmpty
                required={props.required ? props.required : false}
            >
                <MenuItem value=''><em style={{color: 'rgba(0, 0, 0, 0.54)', float: 'left'}}>{props.label}</em></MenuItem>
                {props.options.map(option => {
                    return (
                        <MenuItem  key={`${option.value}-${option.text}`}
                                  value={option.value}>{option.text}</MenuItem>
                    )
                })}
            </Select>
            <Tooltip title={props.tooltip} style={{marginBottom: '20px', cursor: 'pointer', color: 'rgba(0, 0, 0, 0.54)'}}>
                <InfoOutlinedIcon />
            </Tooltip>
        </div>
    );
}