import {client} from "./ApiClient";
import {isNullOrUndefined, mapServiceUrls, getQueryParam} from "../Helpers/Globals";
import {useQuery} from "react-query";
import {Settings} from "../Settings";

const getResolvedCustomer = (key, serviceUrls, accessKey, customer, deviceCode, storeCode) => {
    return client(mapServiceUrls(serviceUrls).LoyaltyEngineApiUrl, `api/v4/${accessKey}/resolvedCustomers?${getQueryParam("Uid", customer.Uid)}${getQueryParam("Code", customer.Code)}${getQueryParam("CompanyName", customer.CompanyName)}${getQueryParam("LastName", customer.LastName)}${getQueryParam("FirstName", customer.FirstName)}${getQueryParam("LoyaltyCardNumber", customer.LoyaltyCardNumber)}${getQueryParam("EmailAddress", customer.EmailAddress)}${getQueryParam("ExternalId", customer.ExternalId)}${getQueryParam("Phone1", customer.Phone1)}${getQueryParam("MobilePhone", customer.MobilePhone)}${!!storeCode ? `&storeCode=${storeCode}` : ''}${!!deviceCode ? `&deviceCode=${deviceCode}` : ''}`)
}

export default function useResolveCustomer(serviceUrls, sessionKey, customer, deviceCode, storeCode) {
    const {status, data, error, refetch } = useQuery(['ResolvedCustomer', customer], (key) => getResolvedCustomer(key, serviceUrls, sessionKey, customer, deviceCode, storeCode), {enabled: false, staleTime: 1000*10, retry: false})
    const resolvedCustomerResponse = data ? data.data : undefined
    return {status, resolvedCustomerResponse, error, refetch}
}