import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button} from "@material-ui/core";
import useApplyCoupon from "../../Utils/UseApplyCoupon";
import BasicTable from "../Tables/BasicTable";
import LoadingSpinner from "../LoadingSpinner";
import useServiceUrls from "../../Utils/UseServiceUrls";
import useGetCart from "../../Utils/UseGetCart";
import useAlert from "../../Utils/UseAlert";
import {areAnyLoading, getQueryString, hasCartIdentifier, onSnippetComplete} from "../../Helpers/Globals";
import Header from "../Header";
import CloseButton from '../PageControls/CloseButton'
import App from "../App";
import useAcknowledgeAlert from "../../Utils/UseAcknowledgeAlert";

export default function CouponAlertSnippet(props) {
    const [alertUid, setAlertUid] = useState('')
    const [domain, setDomain] = useState('')
    const [sessionKey, setSessionKey] = useState('')
    const [cartUid, setCartUid] = useState('')
    const [cartExternalId, setCartExternalId] = useState('');
    const [cartSourceExternalId, setCartSourceExternalId] = useState('');
    const [returnUrl, setReturnUrl] = useState('')
    const [coupons, setCoupons] = useState([])
    const [applyCouponCode, setApplyCouponCode] = useState('')
    const { serviceUrlsStatus, serviceUrls, serviceUrlsError } = useServiceUrls(domain);
    const { status: cartStatus, cartResponse, error: cartError, refetch } = useGetCart(serviceUrls, sessionKey, cartUid, cartExternalId, cartSourceExternalId);
    const { status: alertStatus, alert, error: alertError } = useAlert(serviceUrls, sessionKey, alertUid);

    const acknowledge = useAcknowledgeAlert();
    const apply = useApplyCoupon()

    useEffect(() => {
        setDomain(getQueryString(window.location.href, 'bL_LoginDomain'))
        setSessionKey(getQueryString(window.location.href, 'bL_sk'))
        setAlertUid(getQueryString(window.location.href, 'bL_alertUid'))
        setCartUid(getQueryString(window.location.href, 'bL_cartUid'))
        setCartExternalId(getQueryString(window.location.href, 'bL_cartExternalId'))
        setCartSourceExternalId(getQueryString(window.location.href, 'bL_cartsourceExternalId'))
        setReturnUrl(getQueryString(window.location.href, 'bl_rurl') ?? getQueryString(window.location.href,'ReturnUrl') ?? '')
    }, [window.location.href]);

    useEffect(() => {
        if (alertUid && hasCartIdentifier(cartUid, cartExternalId, cartSourceExternalId) && serviceUrls) {
            acknowledge.mutate({urls: serviceUrls, accessKey: sessionKey, cartUid, cartExternalId, cartSourceExternalId, alertUid})
        }
    }, [alertUid, cartUid, cartExternalId, cartSourceExternalId, serviceUrls])

    useEffect(() => {
        if (apply.status === 'success') {
            updateCouponStatus(apply.data.data.Code)
            setApplyCouponCode('')
        }
    }, [apply.status]);

    useEffect(() => {
        if (alert)
            setCoupons(mapCoupons(parseCoupons(alert.AdditionalData)))
    }, [alert])

    useEffect(() => {
        if (applyCouponCode)
            applyCoupon(applyCouponCode)
    }, [applyCouponCode])

    
    const parseCoupons = (couponList) => {
        console.log(couponList)
        var coupons = []
        couponList.forEach(d => {
            try {
                const coupon = JSON.parse(d)
                coupons.push(coupon)
            } catch (e) {
                console.log(e)
            }
        })
        
        return coupons
    }
    
    const mapCoupons = (couponList) => {
        return couponList.map(d => {
            return {
                ...d,
                Applied: false
            }
        })
    }
    
    const updateCouponStatus = (code) => {
        const couponList = []
        coupons.forEach(c => {
            if (c.Code === code)
               couponList.push({
                    ...c,
                    Applied: true
                })
            else
                couponList.push(c)
        })
        setCoupons(couponList)
    }
    
    const applyCoupon = (couponCode) => {
        apply.mutate({urls: serviceUrls, cartUid, cartExternalId, cartSourceExternalId, code: couponCode, quantity: 1, accessKey: sessionKey})
    }
    
    const applyCouponWrapper = (couponCode) => {
        setApplyCouponCode(couponCode)   
    }

    const couponColumns = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'Name',
        },
        {
            Header: 'Code',
            accessor: 'Code',
        },
        {
            Header: 'Apply',
            accessor: 'UsageLimit',
            Cell: ({row: {original}}) => <Button className='table-button' disabled={original.Applied} onClick={() => applyCouponWrapper(original.Code)} variant='contained' color={'primary'}>Apply</Button>
        },
        {
            Header: '',
            accessor: 'applied',
            Cell: ({row: {original}}) => original.Applied ? <>Applied</> : <></>
        }
    ], [])


    return (
        <>
            <Header title={'Coupon Alert'}/>
            {cartStatus === 'success' && !cartResponse && <div> <p>Unable to find cart</p><Button onClick={() => onSnippetComplete(returnUrl)} variant='contained' color={'primary'}>Close</Button></div>}
            {cartResponse && alert &&
                <>
                    <p>{alert.Message}</p>
                    <br />
                    <div className={'container'}>
                        <div className={'snippetContainer center'}>
                                <>
                                    <div className={`table-wrapper-div`}>
                                        <BasicTable columns={couponColumns} data={coupons} hideHeader={true}/>
                                        {apply.status === 'loading' ? <LoadingSpinner /> : null}
                                    </div>
                                    <CloseButton onClose={() => onSnippetComplete(returnUrl)} label={'Close'}/>
                                </>
                        </div>
                    </div>
                </>
            }
            {areAnyLoading([alertStatus, serviceUrlsStatus, cartStatus]) ? <LoadingSpinner /> : null}
            {alertError ? <p className='error-text'>{alertError}</p> : null}
            {serviceUrlsError ? <p className='error-text'>{serviceUrlsError}</p> : null}
            {cartError ? <p className='error-text'>{cartError}</p> : null}
        </>
    )
}