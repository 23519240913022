import { useQuery } from 'react-query';
import {mapServiceUrls} from "../Helpers/Globals";
import { client } from './ApiClient'


const getAccessKey = (key, usageId, serviceUrls) => {
    let url = mapServiceUrls(serviceUrls).DirectorApiUrl.toLowerCase();
    if (url.includes('api')) {
        url = url.replace('api', 'model')
        url = url.replace('com', 'io')
    }
    
    return client(url, `api/v4/SessionKey/UsageId/${usageId}`);
}

export default function useAccessKey(serviceUrls, usageId) {
    const { status, data, error, isFetching } = useQuery(['AccessKey', usageId], (key) => getAccessKey(key, usageId, serviceUrls), { enabled: !!serviceUrls, staleTime: 1000*60*20})
    const accessKey = data ? data.data.AccessKey : undefined
    return {status, accessKey, error, isFetching}
}