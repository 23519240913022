import React, {useEffect, useMemo, useState} from 'react';
import {Grid} from "@mui/material";
import TextInput from "../Inputs/TextInput";
import {Button} from "@material-ui/core";
import useServiceUrls from "../../Utils/UseServiceUrls";
import useDeviceProfile from "../../Utils/UseDeviceProfile";
import {closeWindow, getQueryString, mapServiceUrls, onSnippetComplete} from "../../Helpers/Globals";
import ClickableRowTable from "../Tables/ClickableRowTable";
import useSearchCustomer from "../../Utils/UseSearchCustomer";
import queryString from "query-string";
import useSetCartCustomer from "../../Utils/UseSetCartCustomer";

export default function FindCustomer(props) {
    const [sessionKey, setSessionKey] = useState('');
    const [loginDomain, setLoginDomain] = useState('');
    const [cartUid, setCartUid] = useState('');
    const [cartExternalId, setCartExternalId] = useState('');
    const [cartSourceExternalId, setCartSourceExternalId] = useState('');
    const [returnUrl, setReturnUrl] = useState('')
    const [customerUid, setCustomerUid] = useState('')
    const { serviceUrlsStatus, serviceUrls, serviceUrlsError } = useServiceUrls(loginDomain);
    const {status, deviceProfile, error} = useDeviceProfile(mapServiceUrls(serviceUrls), sessionKey);
    
    const [search, setSearch] = useState('')
    const [selectedCustomer, setSelectedCustomer] = useState('')
    const[customer, setCustomer] = useState()
    
    const {status: searchStatus, searchResults, error: searchError, refetch} = useSearchCustomer(serviceUrls, sessionKey, search)
    const setCartCustomer = useSetCartCustomer()

    useEffect(() => {
        setSessionKey(getQueryString(window.location.href, 'bl_sk') ?? getQueryString(window.location.href,'SessionKey') ?? '');
        setLoginDomain(getQueryString(window.location.href, 'bl_LoginDomain') ?? getQueryString(window.location.href,'LoginDomain') ?? '');
        setReturnUrl(getQueryString(window.location.href, 'bl_rurl') ?? getQueryString(window.location.href,'ReturnUrl') ?? '')
        setCartUid(getQueryString(window.location.href, 'bL_CartUid') ?? getQueryString(window.location.href,'CartUid') ?? '');
        setCartExternalId(getQueryString(window.location.href, 'bl_CartExternalId') ?? getQueryString(window.location.href,'cartExternalId') ?? '')
        setCartSourceExternalId(getQueryString(window.location.href, 'bl_CartSourceExternalId') ?? getQueryString(window.location.href,'CartSourceExternalId') ?? '')
    }, [window.location.search])

    useEffect(() => {
        if (setCartCustomer.status === 'success') {
            if (props.close !== undefined)
                props.close(customer)
            else
                onSnippetComplete(returnUrl)
        }
    }, [setCartCustomer.status])

    const handleChange = (field, value) => {
        setSearch(value)
    }
    
    const findCustomer = (event) => {
        event.preventDefault()
        refetch()
    }
    
    const hasCart = () => {
        return !cartUid.isEmpty() || !cartExternalId.isEmpty() || !cartSourceExternalId.isEmpty()
    }
    
    const setCustomerToCart = (event) => {
        event.preventDefault();
        const customer = searchResults.find(c => c.Uid === selectedCustomer)
        console.log(customer)
        console.log(hasCart())
        if (hasCart())
            setCartCustomer.mutate({urls: serviceUrls, cartUid, cartExternalId, cartSourceExternalId, customer, accessKey: sessionKey})
        else
            props.close(customer)
    }

    const onSearchTableRowClick = customer => {
        if (selectedCustomer === customer.Uid) {
            setSelectedCustomer('')
        } else {
            setCustomer(customer)
            setSelectedCustomer(customer.Uid)
        }
    }

    const onSearchTableRowDoubleClick = customer => {
        setCustomer(customer)
        setCartCustomer.mutate({urls: serviceUrls, cartUid, cartExternalId, cartSourceExternalId, customer, accessKey: sessionKey})
    }

    const customerColumns = useMemo(() => [
        {
            Header: 'Account Number',
            accessor: 'Code',
        },
        {
            Header: 'First Name',
            accessor: 'FirstName',
        },
        {
            Header: 'Last Name',
            accessor: 'LastName',
        },
        {
            Header: 'Email',
            accessor: 'EmailAddress',
        },
        {
            Header: 'Mobile Phone',
            accessor: 'MobilePhone',
        },
    ], [])
    
    return (
        <>
            <form onSubmit={selectedCustomer ? setCustomerToCart : findCustomer} >
                <Grid container>
                    <Grid item xs={10}>
                        <TextInput required={false} id={'Customer-Search'} value={search} label={'Last Name, First/Last Name Combination, Email, Account Number, Phone Number'} variant={'outlined'} width={'100%'}
                                   handleChange={handleChange}/>
                    </Grid>
                    <Grid item xs={2}>
                        <Button type={'submit'} style={{marginTop: '15px', marginLeft: '15px'}} variant='contained' color={'primary'}>{selectedCustomer ? 'Set' : 'Search'}</Button>
                    </Grid>
                </Grid>
            </form>
            {searchResults &&
                <>
                    <br />
                    {/*<p className={'bold left'}>Select Coupons to Apply</p>*/}
                    <div className={`table-wrapper-div`}>
                        <ClickableRowTable columns={customerColumns} data={searchResults} hideHeader={false} onRowClick={onSearchTableRowClick} onDoubleClick={onSearchTableRowDoubleClick} selected={selectedCustomer}/>
                    </div>
                </>
            }
            {serviceUrlsStatus === 'error' && <p className='error-text'>{serviceUrlsError}</p>}
        </>
    )
}