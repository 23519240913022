import {client} from "./ApiClient";
import {isNullOrUndefined, mapServiceUrls} from "../Helpers/Globals";
import {useQuery} from "react-query";
import {Settings} from "../Settings";

const getMarketingInfo = (key, accessKey, editionCode) => {
    return client(Settings.DomainServerUrl, `api/v4/${accessKey}/ServiceProvisioning/Sessions/MarketingInfo${editionCode ? `?bL_Edition=${editionCode}` : ''}`)
}

export default function useMarketingInfo(sessionKey, editionCode) {
    const {status, data, error } = useQuery(['Marketing', sessionKey], (key) => getMarketingInfo(key, sessionKey, editionCode), {enabled: !!sessionKey, staleTime: 1000*10})
    const marketingInfo = data ? data.data : undefined
    return {status, marketingInfo, error}
}