import React, { useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MDBRow, MDBBtn, MDBCol, MDBInput, MDBSelect } from 'mdbreact';
import Header from '../../Header';
import SelectGroups from '../../../Helpers/StatesAndCountries';
import { provisionStoreRequest } from '../../../Redux/Actions/ProvisionStore';
import {Button} from "@mui/material";

export default function CreateStore() {
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [postal, setPostal] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const initName = useSelector(state => state.provisionStore.store.Name);
    const code = useSelector(state => state.provisionStore.store.Code);
    const percent = useSelector(state => state.createAccount.percentCompleted);
    const [name, setName] = useState(initName);
    const countryOptions = SelectGroups.setSelectedCountry(country);
    const stateOptions = SelectGroups.setSelectedState(state);
    const provinceOptions = SelectGroups.setSelectedProvince(state);
    const dispatch = useDispatch();

    const createStoreCall = (e) => {
        dispatch(provisionStoreRequest(name, code, address1, address2, city, state, country, postal, email, phone))
        e.preventDefault()
    }

    return (
        <Fragment>
            <Header title="Store Details" step={percent === 100 ? 4 : 1} totalSteps={percent === 100 ? 5 : 2}/>
            <div className="form">
            <h4>Please enter the information for this store</h4>
                <MDBRow className="form">
                    <MDBCol md="12">
                        <form onSubmit={createStoreCall}>
                            <div className="grey-text">
                                <MDBInput
                                    id="name"
                                    name="name"
                                    label="Store Name"
                                    group
                                    required
                                    autoFocus
                                    type="text"
                                    valueDefault={name}
                                    onChange={e => setName(e.target.value)}
                                />
                                <MDBInput
                                    id="email"
                                    name="email"
                                    label="Email"
                                    group
                                    autoFocus
                                    type="email"
                                    valueDefault={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                                 <MDBInput
                                    id="phone"
                                    name="phone"
                                    label="Phone"
                                    group
                                    type="text"
                                    valueDefault={phone}
                                    onChange={e => setPhone(e.target.value)}
                                />
                                <MDBInput
                                    id="address1"
                                    name="address1"
                                    label="Address"
                                    group
                                    type="text"
                                    valueDefault={address1}
                                    onChange={e => setAddress1(e.target.value)}
                                />
                                <MDBInput
                                    id="address2"
                                    name="address2"
                                    label="Apt, suite, or floor"
                                    group
                                    type="text"
                                    valueDefault={address2}
                                    onChange={e => setAddress2(e.target.value)}
                                />
                                <MDBInput
                                    id="city"
                                    name="city"
                                    label="Enter your city"
                                    group
                                    type="text"
                                    valueDefault={city}
                                    onChange={e => setCity(e.target.value)}
                                />
                                <MDBSelect
                                    options={countryOptions}
                                    label="Country"
                                    selected={country}
                                    defaultValue={country}
                                    getValue={e => setCountry(e[0])}
                                />

                                {country === "US" &&

                                    <MDBSelect options={stateOptions}
                                        selected={state}
                                        label="State"
                                        getValue={e => setState(e[0])}
                                        defaultValue={state}
                                    />
                                }

                                {country === "CA" &&
                                    <MDBSelect
                                        selected={state}
                                        options={provinceOptions}
                                        label="Province"
                                        getValue={e => setState(e[0])}
                                        defaultValue={state}
                                    />
                                }

                                {(country !== "US" && country !== "CA" && country !== "") &&
                                    <MDBInput
                                        id="state"
                                        name="state"
                                        label="State, Province, or Territory"
                                        group
                                        type="text"
                                        valueDefault={state}
                                        onChange={e => setState(e.target.value)}
                                    />
                                }

                                <MDBInput
                                    id="postal"
                                    name="postal"
                                    label="Postal Code"
                                    group
                                    type="text"
                                    valueDefault={postal}
                                    onChange={e => setPostal(e.target.value)}
                                />

                            </div>
                            <div className="text-center">
                                <Button id="continue" className="reset-btn next-btn" type="submit">Save</Button>
                            </div>
                        </form>
                    </MDBCol>
                </MDBRow>
            </div>
        </Fragment>
    )
}
