import {client} from "./ApiClient";
import {mapServiceUrls} from "../Helpers/Globals";
import {useMutation, useQuery, useQueryClient} from "react-query";

const applyCoupon = ({urls, accessKey, cartUid, cartExternalId, cartSourceExternalId, code, uid, quantity, deviceCode, storeCode}) => {
    return client(mapServiceUrls(urls).LoyaltyEngineApiUrl, `api/v4/${accessKey}/carts/coupons?cartUid=${cartUid === undefined ? null : cartUid}&externalId=${cartExternalId === undefined ? null : cartExternalId}&sourceExternalId=${cartSourceExternalId === undefined ? null : cartSourceExternalId}&uid=${uid}&code=${code}&quantity=${quantity}${!!storeCode ? `&storeCode=${storeCode}` : ''}${!!deviceCode ? `&deviceCode=${deviceCode}` : ''}`, {
        body: {
        }
    })
}

export default function useApplyCoupon() {
    const queryClient = useQueryClient();
    return useMutation(applyCoupon, {
        onSuccess: () => {
            queryClient.invalidateQueries('Coupons')
            queryClient.invalidateQueries('Cart')
        }
    });
}