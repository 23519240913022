import actionTypes from './ActionTypes';

export const validateLoginDomain = (domain) => {
    return {
        type: actionTypes.createAccount.validateLoginDomain,
        domain,
    }
}

export const validateLoginDomainSuccess = (response) => {
    return {
        type: actionTypes.createAccount.validateLoginSuccess,
        response,
    }
}

export const validateLoginDomainFailure = (error) => {
    return {
        type: actionTypes.createAccount.validateLoginFailure,
        error,
    }
}

export const createAccountRequest = (companyName, domain, address1, address2, city, state, country, postal, firstName, lastName, email, username, password, edition, phone) => {
    return {
        type: actionTypes.createAccount.createAccountRequest,
        companyName,
        domain,
        address1,
        address2,
        city,
        state,
        country,
        postal,
        firstName,
        lastName,
        email,
        username,
        password,
        edition,
        phone
    };
}

export const createAccountSuccess = (data) => {
    return {
        type: actionTypes.createAccount.createAccountRequestSuccess,
        data,
    }
}

export const createAccountFailure = (error) => {
    return {
        type: actionTypes.createAccount.createAccountRequestFailure,
        error
    }
}

export const error = (error) => {
    return {
        type: actionTypes.createAccount.error,
        error,
    }
}

export const resetCreateAccountError = () => {
    return {
        type: actionTypes.createAccount.resetError,
    }
}

export const updateValue = (key, value) => {
    return {
        type: actionTypes.createAccount.updateValue,
        key,
        value,
    }
}

export const checkAccountCreationStatus = () => {
    return {
        type: actionTypes.createAccount.checkAccountCreationStatus,
    }
}

export const setAccountCreationStatus = (data) => {
    return {
        type: actionTypes.createAccount.setAccountCreationStatus,
        data,
    }
}

export const accountCreationCompleted = (data) => {
    return {
        type: actionTypes.createAccount.accountCreationCompleted,
        data,
    }
}

export const resetAccountCreationSession = () => {
    return {
        type: actionTypes.createAccount.resetAccountCreationSession
    }
}