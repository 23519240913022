import {client} from "./ApiClient";
import {mapServiceUrls} from "../Helpers/Globals";
import {useMutation, useQuery, useQueryClient} from "react-query";

const recordEngagement = ({urls, accessKey, customer, quantity, typeUid}) => {
    return client(mapServiceUrls(urls).LoyaltyEngineApiUrl, `api/v4/${accessKey}/engagements/commands/record`, {
        body: {
            CustomerUid: customer.Uid,
            CustomerExternalId: customer.ExternalId,
            CustomerCode: customer.Code,
            EngagementTypeUid: typeUid,
            Quantity: quantity
        }
    })
}

export default function useRecordEngagement() {
    return useMutation(recordEngagement);
}