import React from 'react';
import {Button, Grid} from "@mui/material";
import BackspaceIcon from '@mui/icons-material/Backspace';
import LoginIcon from '@mui/icons-material/Login';

export default function NumberPad(props) {
    
    const login = (e) => {
        e.preventDefault()
        props.onSave()
    }
    
    return (
        <>
            <Grid item xs={4}>
                <Button className={'number-button'} onClick={() => props.onChange('1')} disabled={props.value.length === 10}>1</Button>
            </Grid>
            <Grid item xs={4}>
                <Button className={'number-button'} onClick={() => props.onChange('2')} disabled={props.value.length === 10}>2</Button>
            </Grid>
            <Grid item xs={4}>
                <Button className={'number-button'} onClick={() => props.onChange('3')} disabled={props.value.length === 10}>3</Button>
            </Grid>
            <Grid item xs={4}>
                <Button className={'number-button'} onClick={() => props.onChange('4')} disabled={props.value.length === 10}>4</Button>
            </Grid>
            <Grid item xs={4}>
                <Button className={'number-button'} onClick={() => props.onChange('5')} disabled={props.value.length === 10}>5</Button>
            </Grid>
            <Grid item xs={4}>
                <Button className={'number-button'} onClick={() => props.onChange('6')} disabled={props.value.length === 10}>6</Button>
            </Grid>
            <Grid item xs={4}>
                <Button className={'number-button'} onClick={() => props.onChange('7')} disabled={props.value.length === 10}>7</Button>
            </Grid>
            <Grid item xs={4}>
                <Button className={'number-button'} onClick={() => props.onChange('8')} disabled={props.value.length === 10}>8</Button>
            </Grid>
            <Grid item xs={4}>
                <Button className={'number-button'} onClick={() => props.onChange('9')} disabled={props.value.length === 10}>9</Button>
            </Grid>
            <Grid item xs={4}>
                <Button className={'number-button'} onClick={props.backspace} disabled={props.value.length === 0}><BackspaceIcon /></Button>
            </Grid>
            <Grid item xs={4}>
                <Button className={'number-button'} onClick={() => props.onChange('0')} disabled={props.value.length === 10}>0</Button>
            </Grid>
        </>
    )
}