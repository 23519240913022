import React, {useEffect, useState} from 'react';
import {useEditionContext} from "../../Utils/Contexts/EditionContext";
import useEditionDefinition from "../../Utils/UseEditionDefinition";
import {useSelector} from "react-redux";
import ServiceProviderRow from "./ServiceProviderRow";
import Header from "../Header";
import useClientServiceProviders from "../../Utils/UseClientServiceProviders";
import LoadingSpinner from "../LoadingSpinner";
import {MDBBtn} from "mdbreact";
import {Redirect} from "react-router-dom";
import {Button} from "@mui/material";

export default function ServiceProvidersList(props) {
    const {editionCode} = useEditionContext()
    const sessionKey = useSelector(state => state.session.sessionKey);
    const editionUid = useSelector(state => state.login.editionUid)
    const {editionDefinition, status, error} = useEditionDefinition(sessionKey, editionCode, editionUid)
    const {serviceProviders, status: serviceProvidersStatus, error: serviceProviderErrors} = useClientServiceProviders(sessionKey)
    const percent = useSelector(state => state.createAccount.percentCompleted);
    const [redirect, setRedirect] = useState(false)
    const [serviceUid, setServiceUid] = useState('')
    const [clientServiceUid, setClientServiceUid] = useState('')

    useEffect(() => {
        if (status === 'success' && editionDefinition.AvailableServiceProviders.length === 0)
            nextStep()
    }, [editionDefinition])

    const nextStep = e => {
        props.nextStep();
    }

    const setProvisionServiceUid = (serviceUid, clientServiceUid) => {
        setRedirect(true);
        setServiceUid(serviceUid)
        setClientServiceUid(clientServiceUid)
    }

    const setProvisionActiveServiceUid = (serviceUid) => {
        setRedirect(true);
        const sp = serviceProviders.find(sp => sp.ServiceUid === serviceUid)
        setServiceUid(serviceUid)
        setClientServiceUid(sp.Uid)
    }
    
    if (redirect)
        return <Redirect to={`/provisionservice/${serviceUid}/${clientServiceUid}${window.location.search}`} />
    
    if (!editionCode && !editionUid)
        nextStep()
    
    const mapServiceProvider = (provider, index) => {
        const found = serviceProviders.some(sp => sp.ServiceUid === provider.Uid)
        return <ServiceProviderRow key={index} provider={provider} activated={found} setActiveService={setProvisionActiveServiceUid} setService={setProvisionServiceUid}/>
    }
    
    return (
        <div >
            <Header title={'Available Service Providers'} step={percent === 100 ? props.steps + 3 : props.steps} totalSteps={percent === 100 ? props.steps + 3 : props.steps}/>
            {(status === 'loading' || serviceProvidersStatus === 'loading') && <LoadingSpinner />}
            {status === 'success' && serviceProvidersStatus === 'success' && editionDefinition.AvailableServiceProviders.map(mapServiceProvider)}
            <Button className="btn-pair next-btn" id="confirm" type="button" onClick={nextStep}>Next</Button>
        </div>
    )
}