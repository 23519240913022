import React, { useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { checkProvisionStatus } from '../../../Redux/Actions/ProvisionStore';
import { statuses } from '../../../Helpers/Status';
import CreateStore from './CreateStore';
import Header from '../../Header';
import { provisionStoreRequest } from '../../../Redux/Actions/ProvisionStore';
import LoadingSpinner from '../../LoadingSpinner';
import ProvisionExistingStore from './ProvisionExistingStore';

export default function FormProvisionStore(props) {
    const store = useSelector(state => state.provisionStore.store);
    const status = useSelector(state => state.provisionStore.status);
    const percent = useSelector(state => state.createAccount.percentCompleted);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(checkProvisionStatus(store.Code));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const provisionStore = () => {
        dispatch(provisionStoreRequest(store.Name, store.Code, store.Address1, store.Address2, store.City, store.State, store.Country, store.Postal, store.Email, store.Phone1));
    }

    const nextStep = e => {
        props.nextStep();
    }

    let page;
    switch (status) {
        case statuses.loading:
            page = <Fragment>
                <Header title="Store Details"  step={percent === 100 ? 4 : 1} totalSteps={percent === 100 ? 5 : 2}/>
                <LoadingSpinner />
            </Fragment>
            break;
        case statuses.notFound:
            page = <CreateStore />
            break;
        case statuses.found:
            page = <ProvisionExistingStore name={store.Name} provisionStore={provisionStore} />
            break;
        case statuses.provisioned:
            nextStep();
            break;
        default:
            page = <div></div>;
    }

    return (
        <Fragment>
            {page}
        </Fragment>
    )
}
