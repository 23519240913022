
const initialState = {
    login: {
        loggedIn: '',
        key: '',
        error: '',
        editionUid: '',
    },
    resetPassword: {
        status: '',
        error: '',
    },
    createAccount: {
        isDomainValid: '',
        validatingDomain: false,
        companyName: '',
        domain: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        postal: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        username: '',
        password: '',
        verifyPassword: '',
        status: '',
        error: '',
        percentCompleted: '0',
    },
    provisionStore: {
        store: {},
        status: '',
        error: '',
    },
    backOfficeConnector: {
        status: '',
    },
    provisionDevice: {
        device: {},
        status: '',
        error: '',
    },
    session: {
        keySet: false,
        status: '',
        sessionKey: '',
        domain: '',
        provisionKey: '',
        provisionUid: '',
        connectorKey: '',
        error: '',
        existingSession: false,
        ProvisionSession: {},
        RedirectUrl: ''
    },
    PreviewDiscount: {
        CartSet: false,
        Cart: {
        }
    }
}

export default initialState;
