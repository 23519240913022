import React, {useEffect} from 'react';
import {Col, Row} from "react-bootstrap";
import useActivateService from "../../Utils/UseActivateService";
import {useSelector} from "react-redux";
import logo from '../../blLogo.ico'
import {MDBBtn} from "mdbreact";
import LoadingSpinner from "../LoadingSpinner";
import {Button} from "@mui/material";

export default function ServiceProviderRow(props) {
    const provider = props.provider
    const sessionKey = useSelector(state => state.session.sessionKey);
    const activateService = useActivateService()
    
    useEffect(() => {
        if (activateService.status === 'success' && !provider.DisableClientSelfProvisioning)
            props.setService(provider.Uid, activateService.data.data.EntityUid)
    }, [activateService.status])
    
    const provisionActiveService = (e) => {
        e.preventDefault()
        props.setActiveService(provider.Uid)
    }
    
    const activateServiceProvider = (e) => {
        e.preventDefault()
        activateService.mutate({accessKey: sessionKey, service: {
                ServiceType: provider.ServiceType,
                ClientUid: '',
                ServiceUid: provider.Uid
            }})
    }
    
    return (
        <>
            <Row className='provider-row'>
                <Col style={{"paddingTop": "20px"}} xs={1} sm={4}>
                    <Row>
                        <Col xs={3}>
                            {provider.LogoUrl && <img src={provider.LogoUrl}  alt={`${provider.Name} logo`} className='provider-logo'/>}
                            {!provider.LogoUrl && <img src={logo} alt='bLoyal Logo' className='provider-logo'/>}
                        </Col>
                        <Col xs={9}>
                            <p>{provider.Name}</p>
                        </Col>
                    </Row>
                </Col>
                <Col xs={1} sm={6}>
                    <p style={{"fontWeight": "300"}}>{provider.ShortDescription}</p>
                </Col>
                <Col xs={1} sm={2}>
                    {activateService.status === 'loading' && <LoadingSpinner />}
                    {!props.activated && activateService.status !== 'loading' &&
                        <>
                            {provider.DisableClientSelfProvisioning &&
                                <Button id={`activate-${provider.Uid}`} className='activate-provider-button next-btn'
                                        type="button"
                                        onClick={activateServiceProvider}>{'Request'}</Button>
                            }
                            {!provider.DisableClientSelfProvisioning &&
                                <Button id={`provision-${provider.Uid}`} className='activate-provider-button next-btn'
                                        type="button"
                                        onClick={activateServiceProvider}>{'Provision'}</Button>
                            }
                        </>
                    }
                    {props.activated && provider.DisableClientSelfProvisioning && activateService.status !== 'loading' &&
                        <p style={{"fontWeight": "300"}}>Service has been requested. We'll be in touch shortly.</p>
                    }
                    {props.activated && !provider.DisableClientSelfProvisioning && activateService.status !== 'loading' &&
                        <Button id={`provision-${provider.Uid}`} className='activate-provider-button next-btn'
                                type="button"
                                onClick={provisionActiveService}>{'Provision'}</Button>
                    }
                </Col>
            </Row>
            {activateService.status === 'error' && <Row className='provider-row center'>{activateService.error}</Row>}
        </>
    )
}