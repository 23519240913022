import {client} from "./ApiClient";
import {mapServiceUrls} from "../Helpers/Globals";
import {useMutation, useQuery, useQueryClient} from "react-query";

const saveCustomer = ({urls, accessKey, customer}) => {
    return client(mapServiceUrls(urls).LoyaltyEngineApiUrl, `api/v4/${accessKey}/customers/commands/saves`, {
        body: {
            Customer: {
                ...customer,
                Address: {
                    Address1: customer.Address1,
                    Address2: customer.Address2,
                    City: customer.City,
                    State: customer.State,
                    PostalCode: customer.PostalCode,
                    Country: customer.Country
                }
            },
            // ClientUid: "00000000-0000-0000-0000-000000000000"
        }
    })
}

export default function useSaveCustomer() {
    return useMutation(saveCustomer);
}