import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux'

import { loginReducer } from './Reducers/Login';
import { loginEpic, setLoggedInEpic } from '../Epics/LoginEpics';
import { resetPasswordReducer } from './Reducers/ResetPassword';
import { resetPasswordEpic } from '../Epics/ResetPasswordEpics';
import { createAccountReducer } from './Reducers/CreateAccount';
import {
    createAccountEpic,
    checkCreateAccountStatusEpic,
    accountCreationLoopEpic,
    validateLoginDomainEpic,
    deleteAccountCreationSessionEpic
} from '../Epics/CreateAccountEpics';
import { sessionReducer } from './Reducers/Session';
import { sessionKeyEpic, restoreSessionEpic, getProvisionSessionEpic, checkExistingAccountStatusEpic, setDataFromProvisionEpic } from '../Epics/SessionEpics';
import { provisionStoreReducer } from './Reducers/ProvisionStore';
import { checkStoreProvisionStatusEpic, provisionStoreEpic, storeProvisionedEpic } from '../Epics/ProvisionStoreEpics';
import { provisionDeviceReducer } from './Reducers/ProvisionDevice';
import { checkDeviceProvisionStatusEpic, provisionDeviceEpic } from '../Epics/ProvisionDeviceEpics';
import { backOfficeConnectorReducer } from './Reducers/BackOfficeConnector';
import { previewDiscountReducer } from './Reducers/PreviewDiscountReducer';
import { getCartEpic } from '../Epics/CartEpics';

export const rootReducer = combineReducers({
    login: loginReducer,
    resetPassword: resetPasswordReducer,
    createAccount: createAccountReducer,
    provisionStore: provisionStoreReducer,
    backOfficeConnector: backOfficeConnectorReducer,
    provisionDevice: provisionDeviceReducer,
    PreviewDiscount: previewDiscountReducer,
    session: sessionReducer,
    routing: routerReducer
}) 

export const rootEpic = combineEpics(
    loginEpic,
    resetPasswordEpic,
    validateLoginDomainEpic,
    createAccountEpic,
    checkCreateAccountStatusEpic,
    deleteAccountCreationSessionEpic,
    accountCreationLoopEpic,
    checkStoreProvisionStatusEpic,
    checkDeviceProvisionStatusEpic,
    sessionKeyEpic,
    setLoggedInEpic,
    provisionDeviceEpic,
    provisionStoreEpic,
    storeProvisionedEpic,
    restoreSessionEpic,
    getProvisionSessionEpic,
    checkExistingAccountStatusEpic,
    setDataFromProvisionEpic,
    getCartEpic,
);