import React, { Component } from 'react'

export default class Header extends Component {
    render() {
        var style = {
            marginTop: "10px"
        }

        return (
            <div style={style}>
                <h3>{this.props.step ? `Step ${this.props.step} of ${this.props.totalSteps} - ${this.props.title}` : this.props.title}</h3>
                <hr className="lineBreak"></hr>
            </div>
        )
    }
}
