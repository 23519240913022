import React, {useEffect, useMemo, useState} from 'react';
import Header from '../Header'
import TextInput from "../Inputs/TextInput";
import NumberInput from "../Inputs/NumberInput";
import SelectInput from "../Inputs/SelectInput";
import useServiceUrls from "../../Utils/UseServiceUrls";
import useGetCart from "../../Utils/UseGetCart";
import useAlert from "../../Utils/UseAlert";
import useAcknowledgeAlert from "../../Utils/UseAcknowledgeAlert";
import {getQueryString, mapServiceUrls, onSnippetComplete} from "../../Helpers/Globals";
import useEngagementTypes from "../../Utils/UseEngagementTypes";
import LoadingSpinner from "../LoadingSpinner";
import {Button} from "@material-ui/core";
import useRecordEngagement from "../../Utils/UseRecordEngagement";
import useResolveCustomer from "../../Utils/UseResolveCustomer";
import useSearchCustomer from "../../Utils/UseSearchCustomer";
import FindCustomerModal from "../FindCustomer/FindCustomerModal";
import useDeviceProfile from "../../Utils/UseDeviceProfile";
import ClickableRowTable from "../Tables/ClickableRowTable";

export default function RecordEngagement(props) {
    const [state, setState] = useState({quantity: '1', id: '', visitType: ''})
    const [notFound, setNotFound] = useState(false)
    const [findCustomer, setFindCustomer] = useState(false)
    
    const [domain, setDomain] = useState('')
    const [sessionKey, setSessionKey] = useState('')
    const [cartUid, setCartUid] = useState('')
    const [cartExternalId, setCartExternalId] = useState('');
    const [cartSourceExternalId, setCartSourceExternalId] = useState('');
    const [returnUrl, setReturnUrl] = useState('')
    const { serviceUrlsStatus, serviceUrls, serviceUrlsError } = useServiceUrls(domain);
    const {status, engagementTypes, error} = useEngagementTypes(serviceUrls, sessionKey);
    const { status: cartStatus, cartResponse, error: cartError } = useGetCart(serviceUrls, sessionKey, cartUid, cartExternalId, cartSourceExternalId);
    const {status: deviceStatus, deviceProfile, error: deviceError} = useDeviceProfile(mapServiceUrls(serviceUrls), sessionKey);
    const {status: searchStatus, searchResults, error: errorStatus, refetch} = useSearchCustomer(serviceUrls, sessionKey, state.id)
    const recordEngagement = useRecordEngagement()
    

    useEffect(() => {
        setDomain(getQueryString(window.location.href, 'bL_LoginDomain'))
        setSessionKey(getQueryString(window.location.href, 'bL_sk'))
        setCartUid(getQueryString(window.location.href, 'bL_cartUid'))
        setCartExternalId(getQueryString(window.location.href, 'bL_cartExternalId'))
        setCartSourceExternalId(getQueryString(window.location.href, 'bL_cartsourceExternalId'))
        setReturnUrl(getQueryString(window.location.href, 'bl_rurl') ?? getQueryString(window.location.href,'ReturnUrl') ?? '')
    }, [window.location.href]);
    
    useEffect(() => {
        if (state.visitType === '' && deviceProfile)
            setState({...state, visitType: deviceProfile.DefaultEngagementType ?? ''})
    }, [deviceProfile])
    
    useEffect(() => {
        if (cartStatus === 'success' && cartResponse?.Cart?.Customer)
            setState({...state, id: cartResponse.Cart.Customer.Code})
            
    }, [cartStatus])
    
    useEffect(() => {
        if (searchStatus === 'success') {
            if (searchResults.length === 1)
                record(searchResults[0])
            else
                setNotFound(true)
        }
    }, [searchStatus])

    useEffect(() => {
        if (recordEngagement.status === 'success')
            onSnippetComplete(returnUrl)
    }, [recordEngagement.status]);
    
    const typeOptions = useMemo(() => engagementTypes?.map((type) => {
        return {
            text: type.Name,
            value: type.Uid
        }
    }))
    
    const record = (customer) => {
        recordEngagement.mutate({urls: serviceUrls, accessKey: sessionKey, customer: customer, quantity: state.quantity, typeUid: state.visitType})
    }
    
    const submit = (e) => {
        e.preventDefault()
        refetch()
    }
    
    const handleChange = (field, value) => {
        setState({...state, [field]: value})
    }
    
    const closeFindCustomer = (customer) => {
        setState({...state, id: customer.Code})
        setFindCustomer(false)
    }

    const onSearchTableRowClick = type => {
       if (type === state.visitType)
           setState({...state, visitType: ''})
       else
           setState({...state, visitType: type.Uid})
    }

    const onSearchTableRowDoubleClick = type => {
        setState({...state, visitType: type.Uid})
        refetch()
    }
    
    const engagementTypeCols = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'Name',
        },
        {
            Header: 'Code',
            accessor: 'Code',
        },
    ], [])
    
    return (
        <> 
            {findCustomer && <FindCustomerModal close={closeFindCustomer}/>}
            <Header title={'Record Engagement'} />
            <div className={'record-engagement-container center'} >
                {engagementTypes &&
                    <form onSubmit={submit}>
                        <TextInput id={'id'} value={state.id ?? ''} label={'Account Identifier'}
                                   handleChange={handleChange} required={true}/>
                        <br/>
                        <NumberInput id={'quantity'} value={state.quantity ?? ''} label={'Quantity'}
                                     handleChange={handleChange} required={true}/>
                        <br/>
                        <br />
                        <p className={'bold left'}>Select Engagement type to record</p>
                        <div className={`table-wrapper-div`}>
                            <ClickableRowTable columns={engagementTypeCols} data={engagementTypes} hideHeader={true} onRowClick={onSearchTableRowClick} selected={state.visitType} onDoubleClick={onSearchTableRowDoubleClick}/>
                        </div>
                        {/*<SelectInput fieldName={'visitType'} value={state.visitType} label={'Engagement Type'} options={typeOptions} required={true} handleChange={handleChange}/>*/}
                        <br />
                        <Button style={{margin: '5px'}} variant={'text'} className={'left'} color={'primary'} onClick={() => onSnippetComplete(returnUrl)}>Close</Button>
                        <Button style={{margin: '5px'}} type='submit' className={'right'} variant='contained' color={'primary'} disabled={(recordEngagement.status === 'loading' || searchStatus === 'loading')}>Record</Button>
                        <Button style={{margin: '5px'}} className={'right'} variant={'contained'} color={'primary'} onClick={() => setFindCustomer(true)}>Find Customer</Button>
                        <br />
                        <br />
                    </form>
                }
                {notFound && <p className={'error-text'}>Customer not found.</p> }
                {(deviceStatus === 'loading' || status === 'loading' || recordEngagement.status === 'loading' || searchStatus === 'loading') && <LoadingSpinner />}
            </div>
        </>
    )
}