import initialState from "../InitialState";
import actionTypes from "../Actions/ActionTypes";
import { statuses } from "../../Helpers/Status";


export const provisionStoreReducer = (state = initialState.provisionStore, action) => {
    switch (action.type) {
        case actionTypes.provisionStore.setStoreData:
            return {
                ...state,
                store: {
                    Name: action.name,
                    Code: action.code,
                }
            }
        case actionTypes.provisionStore.checkProvisionStatus:
            return {
                ...state,
                status: statuses.loading,
            };
        case actionTypes.provisionStore.checkProvisionStatusSuccess:
            let data = action.data;
            if (data.data.Store === null) {
                return {
                    ...state,
                    status: statuses.notFound,
                };
            } else {
                if (!data.data.IsProvisioned)
                    return {
                        ...state,
                        store: data.data.Store,
                        status: statuses.found,
                    };
                else {
                    return {
                        ...state,
                        store: data.data.Store,
                        status: statuses.provisioned,
                        backOfficeUrl: data.data.BackOfficeConnectorConfigUrl,
                    }
                }
            }
        case actionTypes.provisionStore.checkProvisionStatusFailure:
            return {
                ...state,
                error: action.error,
                status: statuses.error,
            };
            case actionTypes.provisionStore.provisionStoreRequest:
                return {
                    ...state,
                    status: statuses.loading,
                }
        case actionTypes.provisionStore.provisionStoreRequestSuccess:
            return {
                ...state,
                status: statuses.provisioned,
                store: action.data.data.Store,
                backOfficeUrl: action.data.data.BackOfficeConnectorConfigUrl,
            };
        case actionTypes.provisionStore.provisionStoreRequestFailure:
            return {
                ...state,
                status: statuses.error,
                error: action.error,
            };
        case actionTypes.provisionStore.restoreProvisionStoreForm:
            return {
                ...state,
                status: statuses.provisioned,
            };
        default:
            return state;
    }
}