import { of } from "rxjs";
import { map, tap, catchError } from "rxjs/operators";
import { ajax } from 'rxjs/ajax'
import Cookies from 'js-cookie'
import { Settings } from "../Settings";
import {getSessionLoginDomain} from "../Helpers/Globals";

export const getServiceUrls = (domain) => {
    let loginDomain = domain
    if (loginDomain === '') {
        loginDomain = getSessionLoginDomain(window)
        if (!loginDomain)
            throw new Error("Service Urls called with no domain");
    }
    let serviceUrls = getServiceUrlsLocally();
    if (serviceUrls !== "undefined")
        return of(applyOverrides(serviceUrls)); //.map(applyOverrides);

    return ajax(Settings.DomainServerUrl + "/api/v4/serviceurls/" + loginDomain)
        .pipe(
            tap(x => Cookies.set('serviceUrls', x.response.data)),
            tap(x => console.log(x.response.data)),
            map(x => applyOverrides(x.response.data)),
            catchError(err => {
                console.log(err);
            }),
            // tap(x => console.log(x)),
        );
}

const applyOverrides = (urls) => {
    if (Settings.SiteType !== 'Development')
        return urls;
    if (Settings.ServiceUrls === undefined)
        return urls;        

    let updatedUrls = {...urls};

    if (Settings.ServiceUrls.GridApiUrl !== undefined && Settings.ServiceUrls.GridApiUrl.trim() !== '')
        updatedUrls.GridApiUrl = Settings.ServiceUrls.GridApiUrl;

    return updatedUrls;
}

//const processServiceUrlsCall = (response) => {
//    if (response.status === 'error') {
//        return of((response.message))
//    }
//    else {
//        return of(loginRequestSuccess(response))
//    }
//}

const getServiceUrlsLocally = () => {
    var urls = Cookies.getJSON("serviceUrls");
    if (urls !== undefined && urls !== "undefined") {
        return urls
    } else {
        return "undefined";
    }
}