import actionTypes from './ActionTypes';

export const setSessionKey = (sessionKey) => {
    return {
        type: actionTypes.session.setSessionKey,
        sessionKey,
    };
}

export const setUsageId = (usageId) => {
    return {
        type: actionTypes.session.setUsageId,
        usageId,
    }
}

export const setDomain = (domain) => {
    return {
        type: actionTypes.session.setDomain,
        domain,
    }
}

export const setMerchantData = (merchantId, merchantName) => {
    return {
        type: actionTypes.session.setMerchantData,
        merchantId,
        merchantName,
    }
}

export const setProvisionKey = (provisionKey) => {
    return {
        type: actionTypes.session.setProvisionKey,
        provisionKey,
    }
}

export const setConnectorKey = (connectorKey) => {
    return {
        type: actionTypes.session.setConnectorKey,
        connectorKey,
    }
}

export const getProvisionSession = () => {
    return {
        type: actionTypes.session.getProvisionSession
    }
}
export const getProvisionSessionSuccess = (response) => {
    return {
        type: actionTypes.session.getProvisionSessionSuccess,
        response,
    }
}
export const getProvisionSessionFailure = (error) => {
    return {
        type: actionTypes.session.getProvisionSessionFailure,
        error,
    }
}

export const postProvisionSession = () => {
    return {
        type: actionTypes.session.postProvisionSession
    }
}

export const postProvisionSessionSuccess = (response) => {
    return {
        type: actionTypes.session.postProvisionSessionSuccess,
        response,
    }
}

export const postProvisionSessionFailure = (error) => {
    return {
        type: actionTypes.session.postProvisionSessionFailure,
        error,
    }
}

export const sessionRestored = () => {
    return {
        type: actionTypes.session.sessionRestored,
    }
}

export const resetSessionError = () => {
    return {
        type: actionTypes.session.resetError,
    }
}

export const checkExistingCreationSession = () => {
    return {
        type: actionTypes.session.checkExistingCreationSession,
    }
}

export const setExistingCreationSession = (data) => {
    return {
        type: actionTypes.session.setExistingCreationSession,
        data
    }
}

export const setNoExistingCreationSession = () => {
    return {
        type: actionTypes.session.setNoExistingCreationSession,
    }
}

export const setRedirectUrl = (url) => {
    return {
        type: actionTypes.session.setRedirectUrl,
        url
    }
}