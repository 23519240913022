import React, {Fragment, useState} from 'react';
import {Redirect, useNavigate} from "react-router-dom";
import Header from "./Header";
import {MDBBtn, MDBCol, MDBInput, MDBRow} from "mdbreact";
import LoadingSpinner from "./LoadingSpinner";
import {Button} from "@mui/material";


export default function ResetPassword(props) {
    const [domain, setDomain] = useState('')
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    
    const handleChange = (e) => {
        switch (e.target.name) {
            case 'domain':
                setDomain(e.target.value)
                break;
            case 'email':
                setEmail(e.target.value)
                break;
        }
    }
    
    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        props.resetPassword(domain, email)
    }
    
    if (props.status === 'success') {
        window.alert('Please check your email for a link to reset your password');
        props.resetError();
        return <Redirect to={'/login' + window.location.search} />
    }
    
    if (props.status === 'failure') {
        window.alert('Something went wrong, please contact support.');
        props.resetError();
        return <Redirect to={'/login' + window.location.search} />
    }
    
    return (
        <>
            <Header title="Reset Password" />
            <MDBRow className="form">
                <MDBCol md="12">
                    <form onSubmit={onSubmit}>
                        <div className="grey-text">
                            <MDBInput
                                id="domain"
                                name="domain"
                                autoFocus
                                label="Enter your domain"
                                group
                                type="text"
                                required
                                onChange={handleChange}
                            />
                            <MDBInput
                                id="email"
                                name="email"
                                label="Enter your email"
                                group
                                type="email"
                                required
                                onChange={handleChange}
                            />
                        </div>
                        <div className="text-center">
                            <Button id="reset" className="reset-btn next-btn" type="submit">Reset Password</Button>
                        </div>
                    </form>
                </MDBCol>
            </MDBRow>
            {loading &&
                <LoadingSpinner />
            }
        </>
    )
}