import {client} from "./ApiClient";
import {mapServiceUrls} from "../Helpers/Globals";
import {useQuery} from "react-query";

const searchCustomer = (key, urls, accessKey, search) => {
    return client(urls.LoyaltyEngineApiUrl, `api/v4/${accessKey}/Customers?quickSearch=${search}`)
}

export default function useSearchCustomer(urls, accessKey, search) {
    const {status, data, error, refetch } = useQuery(['customer', search], (key) => searchCustomer(key, mapServiceUrls(urls), accessKey, search), {enabled: false, staleTime: 1000*5*60})
    const searchResults = data ? data.data : undefined
    return {status, searchResults, error, refetch}
}