import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, useNavigate} from 'react-router-dom';
import {MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCol, MDBRow} from 'mdbreact';
import queryString from 'query-string';

import {getProvisionSession, setProvisionKey, setRedirectUrl} from '../Redux/Actions/Session';
import LandingHeader from './LandingHeader';
import useMarketingInfo from "../Utils/UseMarketingInfo";
import LoadingSpinner from "./LoadingSpinner";
import {Col, Row} from "react-bootstrap";
import {useEditionContext} from "../Utils/Contexts/EditionContext";
import {Button} from "@mui/material";

export default function LandingPage(props) {
    const { editionCode } = useEditionContext();
    const [redirect, setRedirect] = useState(false);
    const [redirectPath, setRedirectPath] = useState('');
    const sessionKey = useSelector(state => state.session.provisionKey)
    const {status, marketingInfo, error} = useMarketingInfo(sessionKey, editionCode)
    
    useEffect(() => {
        if (error)
            window.alert(`Unable to get marketing info for edition code ${editionCode}`)
    }, [error, editionCode])

    const doRedirect = (url) => {
        setRedirect(true);
        setRedirectPath(url);
    }

    if (redirect === true) {
        return (
            <Redirect push to={redirectPath + props.location.search} />
        )
    }

    return (
        <Fragment>
            {status === 'loading' && <LoadingSpinner />}
            {(status === 'success' || status === 'error') &&
            <>
                <LandingHeader description={marketingInfo?.EditionShortDescription ?? undefined} partnerLogoUrl={marketingInfo?.PartnerLogoUrl ?? undefined}/>

                {marketingInfo &&
                    <p>{marketingInfo.EditionLongDescription}</p>
                }

                <MDBRow className="landingBody">
                    <MDBCol className="bodyItem leftItem" sm='12' md='5'>
                        <MDBCard narrow>
                            <MDBCardImage className="view view-cascade gradient-card-header gradient" cascade tag="div">
                                <h2 className="h2-responsive">Have a bLoyal account?</h2>
                            </MDBCardImage>
                            <MDBCardBody cascade>
                                <MDBCardTitle>Login to connect your device to bLoyal and start selling!</MDBCardTitle>
                                <Button id="toLogin" className='next-btn' type="button"
                                        onClick={e => doRedirect('/login')}>Login</Button>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol className="divider" md='2' sm='0'>
                        <div className="dividerLine">

                        </div>
                    </MDBCol>
                    <MDBCol className="vert" sm="12" md="auto">
                        <div className="verticalBreak">
                        </div>
                    </MDBCol>
                    <MDBCol className="bodyItem rightItem" md='5' sm='12'>
                        <MDBCard narrow>
                            <MDBCardImage className="view view-cascade gradient-card-header gradient" cascade tag="div">
                                <h2 className="h2-responsive">New to bLoyal?</h2>
                            </MDBCardImage>
                            <MDBCardBody cascade>
                                <MDBCardTitle>Create an account and start earning loyal customers!</MDBCardTitle>
                                <Button id="toCreateAccount" className="reset-btn next-btn" type="button"
                                        onClick={e => doRedirect('/createaccount')}>Create Account</Button>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                {marketingInfo &&
                    <Row className='video-row'>
                        {marketingInfo.ProvisioningHelpPartnerVideoUrl &&
                            <Col>
                                <h2>{marketingInfo.ProvisioningHelpPartnerVideoTitle}</h2>
                                <iframe className='video' src={marketingInfo.ProvisioningHelpPartnerVideoUrl}
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen/>
                            </Col>
                        }
                        {marketingInfo.ProvisioningHelpVideoUrl &&
                            <Col>
                                <h2>{marketingInfo.ProvisioningHelpVideoTitle}</h2>
                                <iframe className='video' src={marketingInfo.ProvisioningHelpVideoUrl}
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen/>
                            </Col>
                        }
                    </Row>
                }
            </>
            }
        </Fragment>
    )
}
