import {client} from "./ApiClient";
import {mapServiceUrls} from "../Helpers/Globals";
import {useQuery} from "react-query";

const getSubscriberGroups = (key, urls, accessKey) => {
    return client(urls.LoyaltyEngineApiUrl, `api/v4/${accessKey}/SubscriberGroups`)
}

export default function useSubscriberGroups(urls, accessKey) {
    const {status, data, error } = useQuery(['SubscriberGroups', accessKey], (key) => getSubscriberGroups(key, mapServiceUrls(urls), accessKey), {enabled: !!accessKey && !!urls, staleTime: 1000*5*60})
    const subscriberGroups = data ? data.data : undefined
    return {status, subscriberGroups, error}
}