import React from 'react'
import { ImpulseSpinner } from 'react-spinners-kit';

export default function LoadingSpinner() {
    return (
        <div className="center-spinner" id="loading">
            <ImpulseSpinner loading={true} size={40} frontColor="#393939" backColor="#619700" />
        </div>
    )
}
