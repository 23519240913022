import React, {Fragment, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux';
import {MDBRow, MDBBtn, MDBCol, MDBInput, MDBTooltip} from 'mdbreact';
import {updateValue, validateLoginDomain} from '../../Redux/Actions/CreateAccount';
import Header from '../Header';
import LoadingSpinner from "../LoadingSpinner";
import {Button, Tooltip} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";


export default function FormOwnerDetails(props) {
    const firstName = useSelector(state => state.createAccount.firstName);
    const lastName = useSelector(state => state.createAccount.lastName);
    const phone = useSelector(state => state.createAccount.phone);
    const email = useSelector(state => state.createAccount.email);
    const domain = useSelector(state => state.createAccount.domain);
    const companyName = useSelector(state => state.createAccount.companyName);
    const username = useSelector(state => state.createAccount.username);
    const password = useSelector(state => state.createAccount.password);
    const verifyPassword = useSelector(state => state.createAccount.verifyPassword);
    const isDomainValid = useSelector(state => state.createAccount.isDomainValid);
    const validatingDomain = useSelector(state => state.createAccount.validatingDomain);
    const [validPassword, setValidPassword] = useState(true)
    const [validUsername, setValidUsername] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [invalidDomainFormat, setInvalidDomainFormat] = useState(false)
    const dispatch = useDispatch();


    const handleChange = (event) => {
        dispatch(updateValue(event.target.name, event.target.value))
        event.preventDefault();
        if (event.target.name === 'domain') {
            validateDomainString(event.target.value);
        }
        if (event.target.name !== "username")
            return;
        const value = event.target.value;
        const regex = /^[0-9a-zA-Z./-]+$/
        if (regex.test(value) && value.length < 51)
            setValidUsername(true)
        else
            setValidUsername(false);
    }

    const validateDomainString = (domain) => {
        if (domain.indexOf(' ') >= 0 || domain.length > 20 || domain.indexOf("'") >= 0) {
            console.log('true')
            setInvalidDomainFormat(true)
        } else {
            console.log('false')
            setInvalidDomainFormat(false)
        }
    }

    const nextStep = e => {
        e.preventDefault();
        if (!validUsername)
            return;
        if (password === verifyPassword) {
            if (invalidDomainFormat) {

            } else {
                dispatch(validateLoginDomain(domain));
                setSubmitted(true)
            }
        } else {
            setValidPassword(false)
        }
    }

    const prevStep = e => {
        e.preventDefault();
        props.prevStep();
    }

    if (isDomainValid && submitted) {
        props.nextStep();
    }
    
    if (!domain)
        dispatch(updateValue('domain', companyName.replace(/[\s|\W]/g, '').substring(0, 19)))

    return (
        <Fragment>
            <Header title="Owner Details" step={2} totalSteps={5}/>
            <div className="form">
                <MDBRow className="form">
                    <MDBCol md="12">
                        <form onSubmit={nextStep}>
                            <div className="grey-text">
                                <MDBInput
                                    autoFocus
                                    id="firstName"
                                    name="firstName"
                                    label="First Name"
                                    group
                                    type="text"
                                    required
                                    valueDefault={firstName}
                                    onChange={handleChange}
                                />
                                <MDBInput
                                    id="lastName"
                                    name="lastName"
                                    label="Last Name"
                                    group
                                    type="text"
                                    required
                                    valueDefault={lastName}
                                    onChange={handleChange}
                                />
                                <MDBInput
                                    id="email"
                                    name="email"
                                    label="Email"
                                    group
                                    type="email"
                                    required
                                    valueDefault={email}
                                    onChange={handleChange}
                                />
                                <MDBInput
                                    id="phone"
                                    name="phone"
                                    label="Mobile Phone"
                                    group
                                    type="tel"
                                    required
                                    valueDefault={phone}
                                    onChange={handleChange}
                                />
                                <br/>
                                <h4 className="create-login">Create your login credentials</h4>
                                <MDBRow className='domain-row'>
                                    <MDBCol size="10">
                                        <MDBInput
                                            id="domain"
                                            name="domain"
                                            label="Company login domain"
                                            group
                                            type="text"
                                            required
                                            value={domain}
                                            onChange={handleChange}
                                            className="domain-input"
                                        />
                                    </MDBCol>
                                    <MDBCol size="2">
                                        <Tooltip title={'This is used to uniquely identify your company and required when logging into your bLoyal account. It should only consist of letters with no spaces included.'}>
                                            <InfoOutlinedIcon style={{marginTop: '40px'}} className={'cursor-pointer'}/>
                                        </Tooltip>
                                    </MDBCol>
                                </MDBRow>
                                
                                <MDBInput
                                    id="username"
                                    name="username"
                                    label="Username"
                                    group
                                    type="text"
                                    required
                                    valueDefault={username}
                                    onChange={handleChange}
                                />
                                <MDBInput
                                    id="password"
                                    name="password"
                                    label="Password"
                                    group
                                    type="password"
                                    required
                                    valueDefault={password}
                                    onChange={handleChange}
                                />
                                <MDBInput
                                    id="verifyPassword"
                                    name="verifyPassword"
                                    label="Verify Password"
                                    group
                                    type="password"
                                    required
                                    valueDefault={verifyPassword}
                                    onChange={handleChange}
                                />
                            </div>
                            {!validPassword &&
                            <h5 className="error-text">Passwords must match</h5>
                            }
                            {!validUsername &&
                            <h5 className="error-text">Username must be less than 50 characters and contain only letters, numbers, "-", and "."</h5>
                            }
                            {invalidDomainFormat &&
                            <h5 className="error-text">Your login domain must be no more than 20 characters and contain no whitespace or apostrophe's</h5>
                            }
                            {isDomainValid === false &&
                            <h5 className="error-text">Login domain is already in use, please choose another</h5>
                            }
                            <MDBRow className="btn-row">
                                <MDBCol xs="12" sm="6">
                                    <Button className="back-btn btn-pair" id="back" name="back" color="primary"
                                            onClick={prevStep}>Back</Button>
                                </MDBCol>
                                <MDBCol xs="12" sm="6">
                                    <Button id="continue" className='next-btn' type="submit" >Continue</Button>
                                </MDBCol>
                            </MDBRow>
                            {validatingDomain &&
                            <LoadingSpinner />
                            }
                        </form>
                    </MDBCol>
                </MDBRow>
            </div>
        </Fragment>

    )
}
