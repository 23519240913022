import { ofType, } from 'redux-observable';
import { of } from 'rxjs';
import { map, catchError, switchMap, mergeMap, withLatestFrom, mapTo, delay } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax';

import actionTypes from '../Redux/Actions/ActionTypes';
import { createAccountSuccess, createAccountFailure, checkAccountCreationStatus, accountCreationCompleted, setAccountCreationStatus, validateLoginDomainFailure, validateLoginDomainSuccess } from '../Redux/Actions/CreateAccount';
import { Settings } from '../Settings';
import {setNoExistingCreationSession, setSessionKey} from '../Redux/Actions/Session';
import { CreateAccountStatus } from '../Helpers/Enums';
import {loginRequestSuccess} from "../Redux/Actions/Login";

export const validateLoginDomainEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionTypes.createAccount.validateLoginDomain),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            validateLoginDomainCall(action, state)
        )
    )

const validateLoginDomainCall = (action, state) => {
    const provisionKey = state.session.provisionKey;
    return ajax.getJSON(Settings.DomainServerUrl + `/api/v4/${provisionKey}/ServiceProvisioning/Clients/LoginDomains/` + action.domain)
        .pipe(
            map(response => processValidateLoginDomain(response)),
            catchError(error => validateLoginDomainFailure(error))
        )
}

const processValidateLoginDomain = (response) => {
    if (response.status === 'error')
        return validateLoginDomainFailure(response.message);
    else
        return validateLoginDomainSuccess(response);
}

export const createAccountEpic = (action$, state$) => 
    action$.pipe(
        ofType(actionTypes.createAccount.createAccountRequest),
        withLatestFrom(state$),
        switchMap(([action, state]) => 
            createAccountCall(action, state)
        ),
    );
    

const createAccountCall = (action, state) => {
    const uuid = require('uuid/v4');
    const command = {
        FirstName: action.firstName,
        LastName: action.lastName,
        Email: action.email,
        MobilePhone: action.phone,
        PostalCode: action.postal,
        CountryCode: action.country,
        StateCode: action.state,
        City: action.city,
        Address1: action.address1,
        Address2: action.address2,
        LoginDomain: action.domain,
        CompanyName: action.companyName,
        Password: action.password,
        Username: action.username,
        ConnectorKey: state.session.connectorKey,
        ProvisionUid: state.session.provisionUid,
        EditionCode: action.edition,
        SiteType: Settings.SiteType,
        Uid: uuid(),
    }
    return ajax.post(Settings.DomainServerUrl + `/api/v4/${state.session.provisionKey}/ServiceProvisioning/sessions/clients`, command)
        .pipe(
            map(response => processCreateAccountCall(response.response)),
            catchError(error => of(createAccountFailure(error)))
        )
}

const processCreateAccountCall = (response) => {
    if (response.status === 'error') {
        return createAccountFailure(response.message);
    }
    else {
        return createAccountSuccess(response);
    }
}

export const checkCreateAccountStatusEpic = (action$, state$) => 
    action$.pipe(
        ofType(actionTypes.createAccount.checkAccountCreationStatus),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            checkAccountCreationCall(action, state)
        )
    )

const checkAccountCreationCall = (action, state) => {
    const provisionKey = state.session.provisionKey;
    return ajax(Settings.DomainServerUrl + `/api/v4/${provisionKey}/ServiceProvisioning/Sessions/Clients`)
        .pipe(
            mergeMap(response => processCheckAccountCreationCall(response.response)),
            catchError(error => of(createAccountFailure(error)))
        )
}

const processCheckAccountCreationCall = (response) => {
    if (response.status === 'error') {
        return of(createAccountFailure(response.message));
    } else if (response.data.StatusMessage === "Complete") {
        if (response.data.UserSession === undefined || response.data.UserSession === null || response.data.UserSession.SessionKey === undefined || response.data.UserSession.SessionKey === null)
            return of(accountCreationCompleted(response.data));
        else 
            return of(accountCreationCompleted(response.data), loginRequestSuccess(response.data.UserSession));
    }
    else {
        if (response.data.Status === CreateAccountStatus.Failed) {
            return of(createAccountFailure(response.data.StatusMessage));
        }
        return of(setAccountCreationStatus(response.data));
    }
}

export const accountCreationLoopEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionTypes.createAccount.setAccountCreationStatus),
        delay(30000),
        mapTo(checkAccountCreationStatus())
    );

export const deleteAccountCreationSessionEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionTypes.createAccount.resetAccountCreationSession),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            deleteAccountCreationSession(action, state)
        )
    )

const deleteAccountCreationSession = (action, state) => {
    const provisionKey = state.session.provisionKey;
    return ajax.delete(Settings.DomainServerUrl + `/api/v4/${provisionKey}/ServiceProvisioning/ClientCreationSessions`)
        .pipe(
            mergeMap(response => processDeleteAccountCreationSession(response.response)),
            catchError(error => of(createAccountFailure(error)))
        )
}

const processDeleteAccountCreationSession = (response) => {
    if (response.status === 'error') {
        return of(createAccountFailure(response.message));
        }
    else {
        return of(setNoExistingCreationSession());
    }
}