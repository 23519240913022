import React, { useState } from 'react'
import { Redirect } from 'react-router-dom';

export default function Wizard(props) {
    const [, updateState] = useState();
    const [redirect, setRedirect] = useState(false)
    const pageMap = props.pageMap;
    let CurrPage = pageMap.currentPage;

    const nextStep = () => {
        let options = pageMap[CurrPage];
        pageMap.currentPage = options[1];
        CurrPage = pageMap.currentPage;
        updateState({});
    }

    const prevStep = () => {
        let options = pageMap[CurrPage];
        if (options[0] === null) {
            setRedirect(true)
        }
        pageMap.currentPage = options[0];
        CurrPage = pageMap.currentPage;
        updateState({});
    }

    if (redirect) {
        return (
            <Redirect to={props.redirect + props.location.search} />
        )
    }

    return (
            <CurrPage 
                nextStep={nextStep}
                prevStep={prevStep}
                closeWindow={props.closeWindow}
                steps={props.steps}
            />
    )
}
