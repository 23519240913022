import actionTypes from './ActionTypes';

export const submitLoginRequest = (domain, username, password) => {
    return {
        type: actionTypes.login.submitLoginRequest,
        domain,
        username,
        password
    };
}

export const loginRequestSuccess = (data) => {
    return {
        type: actionTypes.login.loginRequestSuccess,
        data,
    };
}

export const loginRequestFailure = (error) => {
    return {
        type: actionTypes.login.loginRequestFailure,
        error
    };
}

export const setLoggedIn = () => {
    return {
        type: actionTypes.login.setLoggedIn,
    };
}

export const setLoggedOut = () => {
    return {
        type: actionTypes.login.setLoggedOut,
    };
}

export const resetLoginError = () => {
    return {
        type: actionTypes.login.resetError,
    }
}

export const loginFailed = () => {
    return {
        type: actionTypes.login.loginFailed,
    }
}

export const setLoginKey = (accessKey) => {
    return {
        type: actionTypes.login.setLoginKey,
        accessKey,
    }
}