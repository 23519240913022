import React, {useEffect} from 'react';
import {useTable} from "react-table";
import MaUTable from "@material-ui/core/Table";
import {TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";

export default function ClickableRowTable({ columns, data, hideHeader, onRowClick, selected, onDoubleClick }) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })

    // Render the UI for your table
    return (
        <MaUTable {...getTableProps()}>
            <TableHead>
                {headerGroups.map(headerGroup => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => {
                            
                            return hideHeader ? null : <TableCell {...column.getHeaderProps()}
                                       style={{
                                           borderBottom: 'solid 3px black',
                                           color: 'black',
                                           fontWeight: 'bold',
                                           cursor: 'pointer'
                                       }}
                                       className={`react-table-cells`}>
                                {column.render('Header')}
                            </TableCell>
                            
                        })}
                    </TableRow>
                ))}
            </TableHead>
            <TableBody>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <TableRow {...row.getRowProps()} className={`clickable-row ${selected === row.original.Uid ? 'selected' : ''}`} style={{border: '1px solid #d3d3d3'}} onClick={() => onRowClick(row.original)} onDoubleClick={onDoubleClick === undefined ? () => {} : () => onDoubleClick(row.original)}>
                            {row.cells.map(cell => {
                                return (
                                    <TableCell {...cell.getCellProps()} className={`react-table-cells ${selected === row.original.Uid ? 'selected' : ''}`}>
                                        {cell.render('Cell')}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    )
                })}
            </TableBody>
        </MaUTable>
    )
}