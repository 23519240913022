import {useQuery} from 'react-query';
import {Settings} from '../Settings';
import {client} from "./ApiClient";

const getServiceUrls = (key, usageId) => client(Settings.DomainServerUrl, `api/v4/serviceurls?UsageID=${usageId}`);

export default function useServiceUrlsFromAccessKey(usageId) {
    const { status, data, error, isFetching } = useQuery(['ServiceUrls', usageId], (key) => getServiceUrls(key, usageId), {
        enabled: !!usageId,
        staleTime: 1000*60*30
    });
    const serviceUrlsStatus = status;
    const serviceUrls = data ? data.data : undefined;
    const serviceUrlsError = error;
    const isServiceUrlsFetching = isFetching;
    return {serviceUrlsStatus, serviceUrls, serviceUrlsError, isServiceUrlsFetching}
}