
async function client(host, endpoint, props = {}) {
    const body = props.body;
    const customConfig = props.customConfig;
    const headers = {'content-type': 'application/json'}
    const config = {
        method: body ? 'POST' : 'GET',
        ...customConfig,
        headers: {
            ...headers,
        },
    }
    if (body) {
        config.body = JSON.stringify(body)
    }

    const url = `${host}/${endpoint}`

    return window
        .fetch(url, config)
        .then(async r => {
            if (r.status === 401) {
                //Expired Key??
                // logout()
                // refresh the page for them
                // @ts-ignore
                console.log('bad status')
                window.location.assign(window.location)
                return
            }
            const data = await r.json()
            if (data.status === 'success') {
                return data
            } else {
                return Promise.reject(data.message)
            }
        })
        .catch((error) => {
            console.error(error)
            return Promise.reject(error)
        })
}

export {client}