import React, {useEffect, useState} from 'react';
import {Provider, useDispatch, useSelector} from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom'

import Layout from './Layout'
import Login from './Login'
import '../App.css';
import configureStore from '../Redux/Store';
import CreateAccountForm from './AccountCreation/CreateAccountForm';
import ResetPassword from './ResetPassword';
import ReturnLanding from './ReturnLanding';
import StoreAndDeviceSetupForm from './StoreAndDeviceSetup/StoreAndDeviceSetupForm';
import LandingPage from './LandingPage';
import PreviewDiscount from './PreviewDiscount/PreviewDiscount';
import PreviewCart from './PreviewCart/PreviewCart';
import queryString from "query-string";
import {getProvisionSession, setProvisionKey, setRedirectUrl} from "../Redux/Actions/Session";
import Wrapper from "./Wrapper";
import {languageCode, updateLanguageCode} from "../Helpers/Globals";

const store = configureStore();

function App() {

    useEffect(() => {
        if (languageCode !== window.navigator.language)
            updateLanguageCode(window.navigator.language)
    }, [window.navigator.language])
  
  return (
    <div className="App">
      <Provider store={store}>
        <Wrapper/>
      </Provider>
    </div>
  );
}

export default App;
