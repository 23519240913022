import {ofType,} from 'redux-observable';
import {of,} from 'rxjs';
import {catchError, mergeMap, switchMap, withLatestFrom,} from 'rxjs/operators'

import {getServiceUrls} from './ServiceUrls';
import {loginRequestFailure, loginRequestSuccess, setLoggedIn, submitLoginRequest} from '../Redux/Actions/Login';
import actionTypes from '../Redux/Actions/ActionTypes';
import {ajax} from 'rxjs/ajax';
import Cookies from 'js-cookie'
import {setDomain} from "../Redux/Actions/Session";


export const loginEpic = (action$, state$) => 
    action$.pipe(
        ofType(actionTypes.login.submitLoginRequest),
        withLatestFrom(state$),
        switchMap(([action, state]) => 
            loginCall(action.domain, action.username, action.password)
        ),
    );
    


const loginCall = (domain, username, password) => {
    const uuidv4 = require('uuid/v4');
    const uuid = uuidv4();
    const body = {
        Uid: uuid,
        LoginDomain: domain,
        UserName: username,
        Password: password
    };

    return getServiceUrls(domain).pipe(
        mergeMap((x) => ajax.post(x.GridApiUrl + '/api/v4/usersession', body)
        .pipe(
            mergeMap(response => processLoginCall(response, domain, username, password)),
            catchError(error => of(loginRequestFailure(error)))
        )));
}

const processLoginCall = (response, domain, username, password) => {
    if (response.status === 200) {
        const blResponse = response.response;
        if (blResponse.status === 'error') {
            if (blResponse.message.startsWith("Client not assigned to this cluster")) {
                Cookies.remove("serviceUrls");
                return of(submitLoginRequest(domain, username, password))
            }
            return of(loginRequestFailure(blResponse.message))
        } else {
            return of(loginRequestSuccess(blResponse.data))
        }
    } else {
        return of(loginRequestFailure(response.message))
    }
}

export const setLoggedInEpic = (action$, state$) => 
    action$.pipe(
        ofType(actionTypes.session.setSessionKey),
        withLatestFrom(state$),
        switchMap(([action, state]) => 
            setLoggedInCall(state)
        ),
    )


const setLoggedInCall = (state) => {
    if (state.createAccount.domain)
        return of(setLoggedIn(), setDomain(state.createAccount.domain));
    else if (state.session.domain)
        return (of(setLoggedIn()))
    else
        return of({type: "empty"})
}
