import React, {useEffect, useMemo, useState} from 'react'
import FormProvisionStore from './StoreSetup/FormProvisionStore';
import FormSetupComplete from './FormSetupComplete';
import Wizard from '../Wizard';
import FormProvisionDevice from './DeviceSetup/FormProvisionDevice';
import ServiceProvidersList from "../ServiceProviders/ServiceProvidersList";
import AutoProvisionServicePage from "../ServiceProviders/AutoProvisionServicePage";
import {useSelector} from "react-redux";
import {Redirect} from "react-router-dom";

export default function StoreAndDeviceSetupForm(props) {
    const domain = useSelector(state => state.session.domain)
    const accessKey = useSelector(state => state.login.key)
    const provisionStore = useSelector(state => state.session.ProvisionSession.ProvisionStore)
    const provisionDevice = useSelector(state => state.session.ProvisionSession.ProvisionDevice)
    const provisionService = useSelector(state => state.session.ProvisionSession.ProvisionService)
    const [logout, setLogout] = useState(false)

    const getMap = () => {
        if (provisionStore && provisionDevice)
            return {
                currentPage: provisionService ? AutoProvisionServicePage : FormProvisionStore,
                // Include BackOffice Connector Setup
                // [FormProvisionStore]: [null, FormBackOfficeConnector],
                // [FormBackOfficeConnector]: [FormProvisionStore, FormProvisionDevice],
                // [FormProvisionDevice]: [FormBackOfficeConnector, FormSetupComplete],
                [AutoProvisionServicePage]: [null, FormProvisionStore],
                [FormProvisionStore]: [null, FormProvisionDevice],
                // [FormProvisionDevice]: [FormProvisionStore, ServiceProvidersList],
                // [ServiceProvidersList]: [FormProvisionDevice, FormSetupComplete]
                [FormProvisionDevice]: [FormProvisionStore, FormSetupComplete],
            }
        else if (provisionStore)
            return {
                currentPage: provisionService ? AutoProvisionServicePage : FormProvisionStore,
                // Include BackOffice Connector Setup
                // [FormProvisionStore]: [null, FormBackOfficeConnector],
                // [FormBackOfficeConnector]: [FormProvisionStore, FormProvisionDevice],
                // [FormProvisionDevice]: [FormBackOfficeConnector, FormSetupComplete],

                [AutoProvisionServicePage]: [null, FormProvisionStore],
                // [FormProvisionStore]: [null, ServiceProvidersList],
                // [ServiceProvidersList]: [FormProvisionStore, FormSetupComplete]
                [FormProvisionStore]: [null, FormSetupComplete],
            }
        else if (provisionDevice)
            return {
                currentPage: provisionService ? AutoProvisionServicePage : FormProvisionDevice,
                // Include BackOffice Connector Setup
                // [FormProvisionStore]: [null, FormBackOfficeConnector],
                // [FormBackOfficeConnector]: [FormProvisionStore, FormProvisionDevice],
                // [FormProvisionDevice]: [FormBackOfficeConnector, FormSetupComplete],

                [AutoProvisionServicePage]: [null, FormProvisionStore],
                // [FormProvisionDevice]: [null, ServiceProvidersList],
                // [ServiceProvidersList]: [FormProvisionDevice, FormSetupComplete]
                [FormProvisionDevice]: [null, FormSetupComplete],
            }
        else
            return {
                currentPage: provisionService ? AutoProvisionServicePage : ServiceProvidersList,

                [AutoProvisionServicePage]: [null, FormSetupComplete],
                // [ServiceProvidersList]: [null, FormSetupComplete]
            }
    }
    
    const getSteps = () => {
        if (provisionStore && provisionDevice)
            return 2
        else if (provisionStore)
            return 2
        else if (provisionDevice)
            return 1
        else
            return 1
    }
    
    const steps = useMemo(() => getSteps(), [])
    
    const map = useMemo(() => getMap(), [])

    useEffect(() => {
        if (!domain || !accessKey)
            setLogout(true)
    })
    
    if (logout)
        return <Redirect push to={'/login' + props.location.search}/>
    
    return (
        <Wizard pageMap={map} steps={steps}/>
    )
}
